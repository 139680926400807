import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { toBaseGeneralQuestion } from "../question";
import {
  DateTypeConstraints,
  NumberTypeConstraints,
} from "src/components/Form/QuestionForm/formik";

export function toFreeText(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.FreeText<AF.WithId> {
  const question = toBaseGeneralQuestion(value);
  return {
    ...question,
    type: AF.FreeTextType,
  };
}

export function toFileUpload(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.FileUpload<AF.WithId> {
  const question = toBaseGeneralQuestion(value);
  return {
    ...question,
    type: AF.FileUploadType,
  };
}

export function toEmail(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.Email<AF.WithId> {
  const question = toBaseGeneralQuestion(value);
  return {
    ...question,
    type: AF.EmailType,
  };
}

export function toPhoneNumber(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.PhoneNumber<AF.WithId> {
  const question = toBaseGeneralQuestion(value);
  return {
    ...question,
    type: AF.PhoneNumberType,
  };
}

export function toAddress(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.Address<AF.WithId> {
  if (!value.form_question) {
    throw new Error("Missing question detail data");
  }

  const question = toBaseGeneralQuestion(value);
  return {
    ...question,
    type: AF.AddressType,
    filters: AFF.parseFilters(value.form_question.filters),
  };
}

export function toDate(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.Date<AF.WithId> {
  const question = toBaseGeneralQuestion(value);
  return {
    ...question,
    type: AF.DateType,
    constraints: question.constraints as DateTypeConstraints,
  };
}

export function toNumber(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.Number<AF.WithId> {
  const question = toBaseGeneralQuestion(value);
  return {
    ...question,
    type: AF.NumberType,
    constraints: question.constraints as NumberTypeConstraints,
  };
}
