import { gql } from "@apollo/client";

export const INSERT_FORM = gql`
  mutation InsertForm($form_template_id: uuid!, $person_id: uuid!) {
    insert_form_one(
      object: {
        form_template_id: $form_template_id
        person_id: $person_id
        status: InProgress
      }
    ) {
      id
    }
  }
`;

export const UPSERT_FORM_ANSWER_FREE_TEXT = gql`
  mutation UpsertFormAnswerFreeText(
    $form_id: uuid!
    $question_id: uuid!
    $free_text_answer: String
  ) {
    insert_form_answer_one(
      object: {
        form_id: $form_id
        question_id: $question_id
        free_text_answer: $free_text_answer
      }
      on_conflict: {
        update_columns: free_text_answer
        constraint: form_answer_form_id_form_question_id_key
      }
    ) {
      id
      updated_at
      free_text_answer
    }
  }
`;

export const UPSERT_FORM_ANSWER_DATE = gql`
  mutation UpsertFormAnswerDate(
    $form_id: uuid!
    $question_id: uuid!
    $date_answer: date
  ) {
    insert_form_answer_one(
      object: {
        form_id: $form_id
        question_id: $question_id
        date_answer: $date_answer
      }
      on_conflict: {
        update_columns: date_answer
        constraint: form_answer_form_id_form_question_id_key
      }
    ) {
      id
      updated_at
      date_answer
    }
  }
`;

export const UPSERT_FORM_ANSWER_NUMBER = gql`
  mutation UpsertFormAnswerNumber(
    $form_id: uuid!
    $question_id: uuid!
    $number_answer: numeric
  ) {
    insert_form_answer_one(
      object: {
        form_id: $form_id
        question_id: $question_id
        number_answer: $number_answer
      }
      on_conflict: {
        update_columns: number_answer
        constraint: form_answer_form_id_form_question_id_key
      }
    ) {
      id
      updated_at
      number_answer
    }
  }
`;

export const UPSERT_GRADES_ANSWER = gql`
  mutation UpsertGradesAnswer(
    $form_id: uuid!
    $question_id: uuid!
    $grade_config_id: uuid
  ) {
    insert_grades_answer_one(
      object: {
        form_id: $form_id
        question_id: $question_id
        grade_config_id: $grade_config_id
      }
      on_conflict: {
        update_columns: grade_config_id
        constraint: grades_answer_form_id_question_id_key
      }
    ) {
      id
      grade_config_id
      updated_at
    }
  }
`;

export const INSERT_FORM_ANSWER_OPTIONS = gql`
  mutation InsertFormAnswerOptions(
    $form_id: uuid!
    $question_id: uuid!
    $options: [form_answer_option_insert_input!]!
  ) {
    delete_form_answer(
      where: {
        _and: { form_id: { _eq: $form_id }, question_id: { _eq: $question_id } }
      }
    ) {
      affected_rows
    }
    insert_form_answer_one(
      object: {
        form_id: $form_id
        question_id: $question_id
        form_answer_options: { data: $options }
      }
    ) {
      id
      form_answer_options {
        id
      }
      updated_at
    }
  }
`;

export const DELETE_FORM_ADDRESS = gql`
  mutation DeleteFormAddress($form_id: uuid!, $question_id: uuid!) {
    delete_form_address(
      where: { form_id: { _eq: $form_id }, question_id: { _eq: $question_id } }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_FORM_ADDRESS = gql`
  mutation UpsertFormAddress($address: form_address_insert_input!) {
    insert_form_address_one(
      object: $address
      on_conflict: {
        constraint: form_address_form_id_question_id_key
        update_columns: [
          street_address
          street_address_line_2
          city
          state
          zip_code
        ]
      }
    ) {
      id
      updated_at
    }
  }
`;

export const UPSERT_CUSTOM_QUESTION_ANSWERS = gql`
  mutation UpsertCustomQuestionAnswers(
    $form_id: uuid!
    $question_id: uuid!
    $single_select_question_ids: [uuid!]!
    $custom_question_answers: [custom_question_answer_insert_input!]!
    $no_answer_bank_relationship: Boolean!
    $answer_bank_id: uuid
  ) {
    # There isn't a great way to upsert answer options so instead we need to explicitly delete and insert.
    # To delete:
    # - custom question answers for custom question fields of SingleSelect type
    # - form answers for all SingleSelect questions
    # - form answer options are automatically taken care of by cascading delete
    delete_custom_question_answer(
      where: {
        form_id: { _eq: $form_id }
        question_id: { _eq: $question_id }
        form_answer: {
          form_id: { _eq: $form_id }
          question_id: { _in: $single_select_question_ids }
        }
      }
    ) {
      affected_rows
    }
    delete_form_answer(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          question_id: { _in: $single_select_question_ids }
        }
      }
    ) {
      affected_rows
    }
    insert_custom_question_answer(
      objects: $custom_question_answers
      on_conflict: {
        constraint: custom_question_answer_form_id_question_id_form_answer_id_key
        update_columns: []
      }
    ) {
      affected_rows
    }
    insert_custom_question_answer_bank_relationship_one(
      object: {
        form_id: $form_id
        custom_question_id: $question_id
        person_answer_bank_id: $answer_bank_id
      }
      on_conflict: {
        update_columns: person_answer_bank_id
        constraint: custom_question_answer_bank_rela_form_id_custom_question_id_key
      }
    ) @skip(if: $no_answer_bank_relationship) {
      id
    }
  }
`;

export const DELETE_CUSTOM_QUESTION_ANSWERS = gql`
  mutation DeleteCustomQuestionAnswers(
    $form_id: uuid!
    $question_id: uuid!
    $nested_question_ids: [uuid!]!
  ) {
    delete_custom_question_answer(
      where: {
        _and: { form_id: { _eq: $form_id }, question_id: { _eq: $question_id } }
      }
    ) {
      affected_rows
    }

    delete_form_answer(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          question_id: { _in: $nested_question_ids }
        }
      }
    ) {
      affected_rows
    }

    delete_custom_question_answer_bank_relationship(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          custom_question_id: { _eq: $question_id }
        }
      }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_FORM_DISCLAIMER = gql`
  mutation UpsertFormDisclaimer(
    $form_id: uuid!
    $disclaimer_section_id: uuid!
    $signature: String!
  ) {
    insert_form_disclaimer_one(
      object: {
        form_id: $form_id
        disclaimer_section_id: $disclaimer_section_id
        signature: $signature
      }
      on_conflict: {
        update_columns: [signature, signed_at]
        constraint: form_disclaimer_form_id_disclaimer_section_id_key
      }
    ) {
      signed_at
    }
  }
`;

export const INSERT_FORM_SCHOOLS_RANK = gql`
  mutation InsertFormSchoolsRank(
    $deleted_school_ranks: form_school_rank_bool_exp!
    $upserted_school_ranks: [form_school_rank_insert_input!]!
    $delete_offers_where: offer_bool_exp!
    $delete_waitlists_where: waitlist_bool_exp!
  ) {
    update_waitlist(
      _set: { deleted_at: "now()" }
      where: $delete_waitlists_where
    ) {
      affected_rows
    }
    update_offer(_set: { deleted_at: "now()" }, where: $delete_offers_where) {
      affected_rows
    }
    delete_form_school_rank(where: $deleted_school_ranks) {
      affected_rows
    }
    insert_form_school_rank(
      objects: $upserted_school_ranks
      on_conflict: {
        constraint: form_school_rank_form_id_schools_ranking_sect_key
        update_columns: rank
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const LATE_EDIT_SCHOOLS_RANK = gql`
  mutation LateEditSchoolRanks(
    $deleted_school_ranks: form_school_rank_bool_exp!
    $upserted_school_ranks: [form_school_rank_insert_input!]!
    $delete_offers_where: offer_bool_exp!
    $delete_waitlists_where: waitlist_bool_exp!
  ) {
    update_assigned_form(
      _set: { previous_offer_id: null }
      where: {
        deleted_at: { _is_null: true }
        previous_offer: $delete_offers_where
      }
    ) {
      affected_rows
    }
    update_waitlist(
      _set: { deleted_at: "now()" }
      where: $delete_waitlists_where
    ) {
      affected_rows
    }
    update_offer(_set: { deleted_at: "now()" }, where: $delete_offers_where) {
      affected_rows
    }
    delete_form_school_rank(where: $deleted_school_ranks) {
      affected_rows
    }
    insert_form_school_rank(
      objects: $upserted_school_ranks
      on_conflict: {
        constraint: form_school_rank_form_id_schools_ranking_sect_key
        update_columns: rank
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CLEAR_IMPORT_SOURCE = gql`
  mutation ClearImportSource($form_id: uuid!) {
    update_form_by_pk(pk_columns: { id: $form_id }, _set: { source: null }) {
      id
    }
  }
`;

export const UPDATE_IN_PROGRESS_FORM = gql`
  mutation UpdateInProgressForm($form_id: uuid!) {
    update_form_by_pk(
      pk_columns: { id: $form_id }
      _set: { status: InProgress, submitted_at: null }
    ) {
      id
    }
  }
`;

//update_action_item will do a blind update from Pending -> Completed for the form
export const SUBMIT_FORM = gql`
  mutation SubmitForm($form_id: uuid!) {
    update_form_by_pk(
      pk_columns: { id: $form_id }
      _set: { status: Submitted, submitted_at: "now()", submitted_before: true }
    ) {
      id
    }
    update_action_item(
      where: { _and: { form_id: { _eq: $form_id }, status: { _eq: Pending } } }
      _set: { status: Completed }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_ANSWERS = gql`
  mutation DeleteAnswers($form_id: uuid!, $question_ids: [uuid!]!) {
    delete_form_answer(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          form_question: {
            _and: {
              question_id: { _in: $question_ids }
              question: { question_type: { value: { _neq: "FileUpload" } } }
            }
          }
        }
      }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_FORM_AND_LINK = gql`
  mutation InsertFormAndLink(
    $form_template_id: uuid!
    $person_id: uuid!
    $previous_form_id: uuid
    $previous_offer_id: uuid
    $previous_waitlist_id: uuid
  ) {
    insert_form(
      objects: {
        status: InProgress
        form_template_id: $form_template_id
        person_id: $person_id
        previous_form_id: $previous_form_id
        previous_offer_id: $previous_offer_id
        previous_waitlist_id: $previous_waitlist_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UNHIDE_AND_LINK_FORM = gql`
  mutation UnhideAndLinkForm(
    $form_id: uuid!
    $previous_form_id: uuid
    $previous_offer_id: uuid
    $previous_waitlist_id: uuid
  ) {
    update_form_by_pk(
      pk_columns: { id: $form_id }
      _set: {
        is_hidden_from_parent: null
        previous_form_id: $previous_form_id
        previous_offer_id: $previous_offer_id
        previous_waitlist_id: $previous_waitlist_id
        source: null
      }
    ) {
      id
    }
  }
`;

export const CLONE_FORM = gql`
  mutation CloneForm(
    $insert_form_answer: [form_answer_insert_input!]!
    $insert_form_address: [form_address_insert_input!]!
    $insert_form_school_rank: [form_school_rank_insert_input!]!
    $insert_grades_answer: [grades_answer_insert_input!]!
    $insert_custom_question_answer: [custom_question_answer_insert_input!]!
    $insert_custom_question_answer_bank_relationship: [custom_question_answer_bank_relationship_insert_input!]!
  ) {
    insert_form_answer(objects: $insert_form_answer) {
      affected_rows
    }
    insert_form_address(objects: $insert_form_address) {
      affected_rows
    }
    insert_form_school_rank(objects: $insert_form_school_rank) {
      affected_rows
    }
    insert_grades_answer(objects: $insert_grades_answer) {
      affected_rows
    }
    insert_custom_question_answer(objects: $insert_custom_question_answer) {
      affected_rows
    }
    insert_custom_question_answer_bank_relationship(
      objects: $insert_custom_question_answer_bank_relationship
    ) {
      affected_rows
    }
  }
`;
