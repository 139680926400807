import { FunctionComponent } from "react";
import { BaseAddress } from "src/schemas/Address";

export type FormattedAddressProps = {
  address: BaseAddress;
};

export const FormattedAddress: FunctionComponent<FormattedAddressProps> = (
  props
) => {
  const {
    address: { street_address, street_address_line_2, city, state, zip_code },
  } = props;

  // minimize enclosing padding to maximize Radio click area
  return (
    <>
      {street_address}
      <br />
      {street_address_line_2 && (
        <>
          {street_address_line_2}
          <br />
        </>
      )}
      {city}, {state} {zip_code}
    </>
  );
};
