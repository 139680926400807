import { Button, Flex, Heading, Icon, Link } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { RiExternalLinkLine } from "react-icons/ri";
import { School } from "src/components/Inputs/MultiSelectSchoolRank";
import { LateMultiSelectSchoolRank } from "src/components/Inputs/MultiSelectSchoolRank/LateMultiSelectSchoolRank";
import { Glossary } from "src/components/Text/Glossary";
import * as AF from "src/types/formTemplate";
import { SchoolRankingSection, Sections, WithId } from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { SchoolWithFakeRank } from "./LateEdit";

type Props = {
  formId: uuid;
  formTemplateId: uuid;
  enrollmentPeriodId: uuid;
  allSections: Sections<WithId>;
  section: SchoolRankingSection<WithId>;
  onLateDeleteSchoolRank: (
    schoolRank: GQL.GetSchoolsRank_form_school_rank
  ) => void;
  newSchools: SchoolWithFakeRank[];
  schoolRanks: GQL.GetSchoolsRank_form_school_rank[];
  onLateSelectSchool: (school: School) => void;
  onLateDeleteNewSchool: (school: School) => void;
};

export const LateEditRankSchools: React.FC<Props> = ({
  formId,
  formTemplateId,
  enrollmentPeriodId,
  allSections,
  section,
  newSchools,
  schoolRanks,
  onLateDeleteSchoolRank,
  onLateSelectSchool,
  onLateDeleteNewSchool,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const onIsDropdownOpenChange = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  };

  const preRankingSection = useMemo(
    () =>
      allSections.find(
        (section): section is AF.PreRankingSection<AF.WithId> =>
          section?.type === "PreRankingSection"
      ) as AF.PreRankingSection<AF.WithId> | undefined,
    [allSections]
  );

  if (!preRankingSection) return null;

  return (
    <Flex direction="column" gap={3}>
      <Heading as="h2" fontSize="md" color="primary.500" fontWeight="600">
        <Glossary>School choices</Glossary>
      </Heading>
      {section.exploreUrl && !isDropdownOpen && (
        <Flex direction="column" gap={3}>
          <Button
            leftIcon={<Icon as={RiExternalLinkLine} />}
            colorScheme="gray"
            bg="gray.200"
            color="gray.900"
            as={Link}
            href={section.exploreUrl}
            isExternal={true}
          >
            <Glossary>Explore schools</Glossary>
          </Button>
        </Flex>
      )}
      <Flex justifyContent="center">
        <LateMultiSelectSchoolRank
          formId={formId}
          formTemplateId={formTemplateId}
          enrollmentPeriodId={enrollmentPeriodId}
          selectedSchoolsRank={schoolRanks}
          preRankingSection={preRankingSection}
          schoolRankingSection={section}
          onIsDropdownOpenChange={onIsDropdownOpenChange}
          newSchools={newSchools}
          onLateDeleteSchoolRank={onLateDeleteSchoolRank}
          onLateSelectedItem={onLateSelectSchool}
          onLateDeleteNewSchool={onLateDeleteNewSchool}
        />
      </Flex>
    </Flex>
  );
};
