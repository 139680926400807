import { Flex, Text } from "@chakra-ui/react";
import { FormikProps, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { VerificationTag } from "src/components/DataDisplay/VerificationTag";
import { AddressAnswer } from "src/components/Form/QuestionForm/formik";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { Answer, Question } from "src/services/formTemplate";
import {
  FormikValues,
  UndoableAnswer,
  isAddressAnswer,
} from "src/services/formTemplate/answer";
import { findInapplicableQuestionsToDelete } from "src/services/formTemplate/question";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import {
  FormQuestion,
  FormQuestionWithAutoSave,
} from "./components/Inputs/FormQuestion";
import { DELETE_ANSWERS } from "./graphql/mutations";
import {
  AddressAnswerChangeProps,
  SingleSelectChangeProps,
} from "./hooks/useFormQuestion";

const verificationBgColorMap = {
  [GQL.verification_status_enum.Pending]: "",
  [GQL.verification_status_enum.Verified]: "green.50",
  [GQL.verification_status_enum.Rejected]: "orange.50",
};

const verificationTextMap = {
  [GQL.verification_status_enum.Pending]: "",
  [GQL.verification_status_enum.Verified]:
    "The following information has been verified by staff and as such can no longer be edited",
  [GQL.verification_status_enum.Rejected]:
    "The following information has been rejected by staff, please review and adjust your responses before submitting again.",
};

export type Props = {
  formId: uuid;
  applicant: AFF.Types.Applicant;
  completeQuestions: readonly AF.Question<AF.WithId>[];
  applicableQuestions: Question.VerificationQuestions[];
  verificationResults: GQL.FormFragment_form_verification_results[];
  formikProps: FormikProps<Answer.FormikValues>;
  confirmEligibilityChange?: (
    dateOfBirth: string | null,
    answers: FormikValues,
    question: AF.Question<AF.WithId>,
    answer: UndoableAnswer<AF.Option<AF.WithId> | undefined>,
    applicableQuestions: readonly AF.Question<AF.WithId>[]
  ) => Promise<boolean>;
  confirmGradesChange?: (
    dateOfBirth: string | null,
    answers: FormikValues,
    question: AF.Question<AF.WithId>,
    newGradeConfigId: UndoableAnswer<uuid | undefined>,
    applicableQuestions: readonly AF.Question<AF.WithId>[]
  ) => Promise<boolean>;
  confirmAddressChange?: (
    dateOfBirth: string | null,
    answers: Answer.FormikValues,
    question: AF.Question<AF.WithId>,
    addressAnswer: UndoableAnswer<AddressAnswer | undefined>,
    applicableQuestions: readonly AF.Question<AF.WithId>[]
  ) => Promise<boolean>;
  onAutosave?: (questionId: uuid, saving: boolean) => void;
  onChangeFormQuestion?: (questionId: string) => void;
};

export const QuestionList: React.FC<Props> = ({
  formId,
  applicant,
  completeQuestions,
  applicableQuestions,
  verificationResults,
  formikProps,
  confirmEligibilityChange,
  confirmGradesChange,
  confirmAddressChange,
  onAutosave,
  onChangeFormQuestion,
}) => {
  const getVerificationResult = useCallback(
    (verificationId?: string) => {
      return verificationResults.find(
        (result) => result.form_verification.id === verificationId
      );
    },
    [verificationResults]
  );

  const applicableQuestionsFlatten = applicableQuestions.flatMap(
    (q) => q.questions
  );
  const formik = useFormikContext();
  const [deleteAnswers] = useRemoteDataMutation<
    GQL.DeleteAnswers,
    GQL.DeleteAnswersVariables
  >(DELETE_ANSWERS);

  React.useEffect(() => {
    const clearInapplicableAnswer = async () => {
      const inapplicableQuestions = findInapplicableQuestionsToDelete(
        completeQuestions,
        applicableQuestionsFlatten,
        formikProps.values
      );
      if (inapplicableQuestions.length > 0) {
        await deleteAnswers({
          variables: {
            form_id: formId,
            question_ids: inapplicableQuestions,
          },
        });

        inapplicableQuestions.forEach((questionId) => {
          formik.setFieldValue(questionId, "");
          formik.setFieldTouched(questionId, false); // To avoid the validation error message being shown immediately.
        });
      }
    };

    clearInapplicableAnswer();

    // This effect need to be triggered whenever user make changes to the answers
    // to ensure no inapplicable answers exists after potentialy branching question edit.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikProps.values]);

  React.useEffect(() => {
    formikProps.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderQuestion = useCallback(
    (
      { verification, questions }: Question.VerificationQuestions,
      index: number
    ) => {
      const verificationResult = getVerificationResult(verification?.id);

      const isPending =
        !verificationResult?.verification_status ||
        verificationResult?.verification_status ===
          GQL.verification_status_enum.Pending;

      const isVerified =
        verificationResult?.verification_status ===
        GQL.verification_status_enum.Verified;

      const isRejected =
        verificationResult?.verification_status ===
        GQL.verification_status_enum.Rejected;

      const verificationBgColor = !verificationResult
        ? ""
        : verificationBgColorMap[verificationResult.verification_status];

      const verificationText = !verificationResult
        ? ""
        : verificationTextMap[verificationResult.verification_status];

      const questionComponents = questions.map((question) => {
        let confirmEligibilityUpdate:
          | ((value: SingleSelectChangeProps) => Promise<boolean>)
          | undefined;

        if (confirmEligibilityChange)
          confirmEligibilityUpdate = ({ before, after }) =>
            confirmEligibilityChange(
              applicant.birth_date,
              formikProps.values,
              question,
              { before, after },
              questions
            );

        let confirmGradesUpdate:
          | ((value: SingleSelectChangeProps) => Promise<boolean>)
          | undefined;

        let confirmAddressUpdate:
          | ((value: AddressAnswerChangeProps) => Promise<boolean>)
          | undefined;

        if (confirmGradesChange) {
          confirmGradesUpdate = ({ before, after }) =>
            confirmGradesChange(
              applicant.birth_date,
              formikProps.values,
              question,
              { before: before?.id, after: after?.id },
              questions
            );
        }

        if (confirmAddressChange) {
          confirmAddressUpdate = ({ before, after }) => {
            if (!isAddressAnswer(after)) {
              return Promise.resolve(true);
            }

            return confirmAddressChange(
              applicant.birth_date,
              formikProps.values,
              question,
              { before, after },
              questions
            );
          };
        }

        return isVerified ? (
          <FormQuestion
            formId={formId}
            applicant={applicant}
            question={question}
            key={question.question}
            readOnly={isVerified}
            confirmAddressChange={confirmAddressUpdate}
            onChangeFormQuestion={onChangeFormQuestion}
          />
        ) : (
          <FormQuestionWithAutoSave
            onAutosave={onAutosave}
            formId={formId}
            applicant={applicant}
            question={question}
            key={question.question}
            readOnly={isVerified}
            confirmGradesChange={confirmGradesUpdate}
            confirmEligibilityChange={confirmEligibilityUpdate}
            confirmAddressChange={confirmAddressUpdate}
            onChangeFormQuestion={onChangeFormQuestion}
          />
        );
      });

      if (verification?.label && !isPending) {
        return (
          <Flex
            key={index}
            padding={2}
            bgColor={verificationBgColor}
            borderRadius="md"
            direction="column"
            gap={4}
          >
            <Flex direction="column" gap={2}>
              <VerificationTag
                label={verification.label}
                status={verificationResult?.verification_status}
                showText="status"
                colorScheme={isRejected ? "orange" : undefined}
              />
              <Text color="blackAlpha.700" fontStyle="italic">
                {verificationText}
              </Text>
            </Flex>
            {questionComponents}
          </Flex>
        );
      }

      return (
        <Flex flexDirection="column" gap={4} key={index}>
          {questionComponents}
        </Flex>
      );
    },
    [
      applicant,
      confirmAddressChange,
      confirmEligibilityChange,
      confirmGradesChange,
      formId,
      formikProps.values,
      getVerificationResult,
      onAutosave,
      onChangeFormQuestion,
    ]
  );

  return (
    <Flex direction="column" gap={4}>
      {applicableQuestions.map(renderQuestion)}
    </Flex>
  );
};
