import {
  Button,
  Flex,
  Icon,
  Link,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { RiExternalLinkLine } from "react-icons/ri";
import {
  MultiSelectSchoolRank,
  Loading,
  School,
} from "src/components/Inputs/MultiSelectSchoolRank";
import {
  FormStepLayout,
  StepProps,
} from "src/components/Layout/FormStepLayout";
import { Glossary } from "src/components/Text/Glossary";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useFormSteps } from "src/hooks/useFormSteps";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useRankedSchools from "src/hooks/useSchoolRank";
import { useWeglotToast, WeglotBlock } from "src/plugins/weglot";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import { SchoolRankingSection, Sections, WithId } from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RemoteData from "src/types/remoteData";
import { FormButtons } from "./components/Layout/FormButtons";
import { INSERT_FORM_SCHOOLS_RANK } from "./graphql/mutations";
import { useAutosaveReducer } from "./hooks/useAutosaveReducer";
import { rankedSchoolsEditVar } from "./store";
import { useSelectedSchoolRanks } from "./hooks/useSelectedSchoolRanks";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { EligibilityServiceError } from "src/components/Feedback/EligibilityServiceError";
import { ApolloError } from "@apollo/client";

type Props = {
  formTemplateId: uuid;
  formId: uuid;
  enrollmentPeriodId: uuid;
  allSections: Sections<WithId>;
  section: SchoolRankingSection<WithId>;
  applicant: AFF.Types.Applicant;
  hasBeenSubmittedBefore: boolean | null;
  refetchSchoolRanks: () => Promise<unknown>;
  schoolRanksRemoteData: RemoteData.RemoteData<ApolloError, GQL.GetSchoolsRank>;
} & StepProps;

const TOAST_SUCCESS_ID = "step-rank-school-success";
const TOAST_ERROR_ID = "step-rank-school-error";

export const StepRankSchools: React.FC<Props> = ({
  formTemplateId,
  formId,
  enrollmentPeriodId,
  allSections,
  section,
  applicant,
  hasBeenSubmittedBefore,
  schoolRanksRemoteData,
  refetchSchoolRanks,
  ...stepProps
}) => {
  const preRankingSection = useMemo(
    () =>
      allSections.find(
        (section): section is AF.PreRankingSection<AF.WithId> =>
          section?.type === "PreRankingSection"
      ) as AF.PreRankingSection<AF.WithId> | undefined,
    [allSections]
  );
  const {
    deletedSchoolsRankAlert,
    clearSchoolRemoveAlert,
    selectedSchoolRanksRemoteData,
  } = useSelectedSchoolRanks({
    dateOfBirth: applicant.birth_date,
    formId,
    formTemplateId,
    schoolRanksRemoteData,
    refetchSchoolRanks,
  });

  const { glossary } = useGlossary();
  const toast = useWeglotToast();

  const { onNext, onPrevious } = useFormSteps(stepProps);

  React.useEffect(() => {
    // clear rankedSchoolEditVar on first render of the component
    rankedSchoolsEditVar(null);
  }, []);

  const {
    getDeletedRanks,
    getUpsertedRanks,
    setRanks,
    getDeletedOffers,
    getDeletedWaitlists,
  } = useRankedSchools([]);

  const listTerm = section.rankingEnabled ? "ranking" : "choices";

  React.useEffect(
    function processSchoolRankEditAlert() {
      if (
        selectedSchoolRanksRemoteData.kind === RemoteData.RemoteDataKind.Success
      ) {
        const rankedSchools =
          selectedSchoolRanksRemoteData.data.form_school_rank.map(
            (rank) => rank.school.id
          );
        setRanks(
          selectedSchoolRanksRemoteData.data.form_school_rank.map((rank) => ({
            form_id: formId,
            schools_ranking_section_id: section.id,
            school_id: rank.school.id,
          }))
        );
        const edit = {
          before: rankedSchoolsEditVar()?.before ?? rankedSchools,
          after: rankedSchools,
        };
        rankedSchoolsEditVar(edit);
      }
    },
    [formId, selectedSchoolRanksRemoteData, section.id, setRanks]
  );

  const { autosaveStatus, onAutosave } = useAutosaveReducer();
  const [insertSchoolRank] = useRemoteDataMutation<
    GQL.InsertFormSchoolsRank,
    GQL.InsertFormSchoolsRankVariables
  >(INSERT_FORM_SCHOOLS_RANK);

  const selectedItemsRemoteData = React.useMemo(() => {
    return selectedSchoolRanksRemoteData.map((getSchoolsRankData) => {
      const selectedItems: School[] = getSchoolsRankData.form_school_rank.map(
        (schoolRank) => schoolRank.school
      );

      return selectedItems;
    });
  }, [selectedSchoolRanksRemoteData]);

  const {
    confirm: confirmFinishRanking,
    confirmationDialog: finishRankingConfirmationDialog,
    setBody: setFinishRankingConfirmationDialogBody,
  } = useConfirmationDialog({
    header: glossary`School ${listTerm}`,
    body: null,
    cancelButton: {
      label: "No, cancel",
    },
    confirmButton: {
      label: "Yes, continue",
    },
    translate: true,
  });

  React.useEffect(() => {
    if (!selectedItemsRemoteData.hasData()) return;
    const schoolRanks = selectedItemsRemoteData.data;

    setFinishRankingConfirmationDialogBody(
      <Flex direction="column">
        <Text>
          <Glossary>Your schools list is currently:</Glossary>
        </Text>
        <WeglotBlock skip={true}>
          <List
            paddingBottom={5}
            listStylePosition="inside"
            margin="0"
            styleType={section.rankingEnabled ? "number" : "disc"}
            ml={section.rankingEnabled ? 0 : 2}
          >
            {schoolRanks.map((school) => (
              <ListItem key={school.id}>{school.name}</ListItem>
            ))}
          </List>
        </WeglotBlock>
        <Text>
          <Glossary>You can edit your schools list later.</Glossary>
        </Text>
      </Flex>
    );
  }, [
    section.rankingEnabled,
    selectedItemsRemoteData,
    setFinishRankingConfirmationDialogBody,
  ]);

  const refetch = React.useCallback(async () => {
    await Promise.all([refetchSchoolRanks()]);
  }, [refetchSchoolRanks]);

  const setSchoolRanks = React.useCallback(
    async (schoolIds: string[]) => {
      // use formId as question id since we don't actualy have quesiton here.
      onAutosave(formId, true);

      const upsertedSchoolRanks = getUpsertedRanks(
        schoolIds.map((schoolId) => ({
          form_id: formId,
          schools_ranking_section_id: section.id,
          school_id: schoolId,
        }))
      );

      const deletedSchoolRanks = getDeletedRanks(upsertedSchoolRanks);
      const deletedOffers = getDeletedOffers(upsertedSchoolRanks);
      const deletedWaitlists = getDeletedWaitlists(upsertedSchoolRanks);

      await insertSchoolRank({
        variables: {
          delete_offers_where: deletedOffers,
          delete_waitlists_where: deletedWaitlists,
          deleted_school_ranks: deletedSchoolRanks,
          upserted_school_ranks: upsertedSchoolRanks,
        },
      });
      await refetch();
      onAutosave(formId, false);
    },
    [
      formId,
      getDeletedWaitlists,
      getDeletedOffers,
      getDeletedRanks,
      getUpsertedRanks,
      insertSchoolRank,
      onAutosave,
      refetch,
      section,
    ]
  );

  const onSortHandler = async (schools: readonly School[]) => {
    try {
      await setSchoolRanks(schools.map((school) => school.id));
    } catch (error) {
      console.error(error);
      if (!toast.isActive(TOAST_ERROR_ID)) {
        toast({
          id: TOAST_ERROR_ID,
          status: "error",
          title: `Error updating school ${listTerm}`,
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
        });
      }
      throw error;
    }

    await refetch();
  };

  const deleteSchoolRank = async (
    schools: readonly School[],
    school: School
  ) => {
    try {
      const updatedSelectedSchoolIds = schools
        .filter((s) => s.id !== school.id)
        .map((s) => s.id);
      await setSchoolRanks(updatedSelectedSchoolIds);
      await refetch();
      if (!toast.isActive(TOAST_SUCCESS_ID)) {
        toast({
          id: TOAST_SUCCESS_ID,
          status: "info",
          title: glossary`School removed from ${listTerm} list`,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      if (!toast.isActive(TOAST_ERROR_ID)) {
        toast({
          id: TOAST_ERROR_ID,
          status: "error",
          title: glossary`Error removing school`,
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
        });
      }
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const onIsDropdownOpenChange = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  };

  if (!preRankingSection) return null;

  return (
    <FormStepLayout
      title={selectedItemsRemoteData.hasError() ? "" : undefined}
      description={section.description}
      buttons={(buttonsProps) => (
        <FormButtons
          saveStatus={autosaveStatus}
          {...buttonsProps}
          overrideNextButton={{
            disabled: selectedItemsRemoteData
              .map(
                (selectedItems) =>
                  selectedItems.length === 0 ||
                  (section.minSchools
                    ? selectedItems.length < section.minSchools
                    : false)
              )
              .withDefault(true),
            action: async () => {
              if (autosaveStatus === "Saving") {
                return;
              }
              if (await confirmFinishRanking()) {
                clearSchoolRemoveAlert();
                onNext();
              }
            },
          }}
          overridePreviousButton={{
            action: () => {
              clearSchoolRemoveAlert();
              onPrevious();
            },
          }}
          hasBeenSubmittedBefore={hasBeenSubmittedBefore}
        />
      )}
      {...stepProps}
      content={
        <RemoteDataView
          loading={<Loading />}
          config={{ showDataWhileReloading: false }}
          error={(error) => <EligibilityServiceError error={error} />}
          remoteData={selectedItemsRemoteData}
        >
          {(selectedItems) => {
            return (
              <Flex direction="column" gap={3}>
                {deletedSchoolsRankAlert}
                {section.exploreUrl && !isDropdownOpen && (
                  <Button
                    leftIcon={<Icon as={RiExternalLinkLine} />}
                    colorScheme="gray"
                    bg="gray.200"
                    color="gray.900"
                    as={Link}
                    href={section.exploreUrl}
                    isExternal={true}
                  >
                    <Glossary>Explore schools</Glossary>
                  </Button>
                )}
                <Flex justifyContent="center">
                  <MultiSelectSchoolRank
                    formTemplateId={formTemplateId}
                    applicant={applicant}
                    enrollmentPeriodId={enrollmentPeriodId}
                    formId={formId}
                    selectedSchools={selectedItems}
                    preRankingSection={preRankingSection}
                    schoolRankingSection={section}
                    onSort={onSortHandler}
                    onDelete={deleteSchoolRank}
                    onSelectedItemsChanged={(schools: readonly School[]) => {
                      setSchoolRanks(schools.map((school) => school.id));
                    }}
                    onIsDropdownOpenChange={onIsDropdownOpenChange}
                  />
                </Flex>
                {finishRankingConfirmationDialog}
              </Flex>
            );
          }}
        </RemoteDataView>
      }
    ></FormStepLayout>
  );
};
