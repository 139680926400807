import { asRequiredField, nullableWithDefault } from "src/services/zodExtra";
import { z } from "zod";

export const AddressSchema = z.object({
  street_address: nullableWithDefault(z.string(), ""),
  street_address_line_2: nullableWithDefault(z.string(), ""),
  city: nullableWithDefault(z.string(), ""),
  state: nullableWithDefault(z.string(), ""),
  zip_code: nullableWithDefault(z.string(), ""),
});

export const AddressValidator = asRequiredField(
  AddressSchema,
  "street_address",
  "city",
  "state",
  "zip_code"
);

export type AddressFormType = z.infer<typeof AddressSchema>;

export const INITIALLY_EMPTY_ADDRESS = {
  street_address: "",
  street_address_line_2: "",
  city: "",
  state: "",
  zip_code: "",
};

export const AddressValidationSchema = z.object({
  street_address: z.string().min(1, "Street address is required"),
  street_address_line_2: z.string(),
  city: z.string().min(1, "City is required"),
  state: z.string().length(2, "A 2 character state is required"),
  zip_code: z.string().length(5, "A 5 digit zip code is required"),
});

export const BaseAddressSchema = z.object({
  street_address: z.string(),
  street_address_line_2: z.string(),
  city: z.string(),
  state: z.string(),
  zip_code: z.string(),
});
export type BaseAddress = z.infer<typeof BaseAddressSchema>;

export const CURRENT_ADDRESS = "currentAddress";
const CurrentAddressSchema = BaseAddressSchema.extend({
  kind: z.literal(CURRENT_ADDRESS),
  user_id: z.string().nullish(),
});
export type CurrentAddress = z.infer<typeof CurrentAddressSchema>;

export const EXISTING_ADDRESS = "existingAddress";
const ExistingAddressSchema = BaseAddressSchema.extend({
  kind: z.literal(EXISTING_ADDRESS),
  user_id: z.string().nullish(),
  user_address_id: z.string().uuid(),
});
export type ExistingAddress = z.infer<typeof ExistingAddressSchema>;

export const NO_ADDRESS = "noAddress";
const EmptyAddressSchema = BaseAddressSchema.extend({
  kind: z.literal(NO_ADDRESS),
  user_id: z.string().nullish(),
});
export type EmptyAddress = z.infer<typeof ExistingAddressSchema>;

export const AddressBookAnswerSchema = z.discriminatedUnion("kind", [
  CurrentAddressSchema,
  ExistingAddressSchema,
  EmptyAddressSchema,
]);
export type AddressBookAnswer = z.infer<typeof AddressBookAnswerSchema>;
