import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Flex, Heading, Spacer, VStack } from "@chakra-ui/layout";
import { DeleteIcon } from "@chakra-ui/icons";
import { Select } from "@chakra-ui/react";
import { Field, Form, Formik, FieldArray } from "formik";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import * as ConfigForm from "./components/ConfigForm";
import { AllOrgConfigs } from "./types";
import { validateWithZod } from "src/services/formValidations";
import { z } from "zod";
import { InputControl } from "formik-chakra-ui";
import {
  AnnouncementForm,
  DESCRIPTION_CONTENT_LIMIT,
} from "./components/AnnouncementForm";

export const FormikSchema = z.object({
  name: z.string().min(1, "This field is required"),
  path: z.string().min(1, "This field is required"),
  timezoneName: z.string().min(1, "This field is required"),
  organizationConfigs: ConfigForm.ForkmikSchema,
  noStudentAnnouncement: z.object({
    id: z.string(),
    title: z.string(),
    description: z
      .string()
      .max(
        DESCRIPTION_CONTENT_LIMIT,
        `Description must be ${DESCRIPTION_CONTENT_LIMIT} characters or less.`
      ),
    active: z.boolean(),
    showType: z.string(),
    linkActive: z.boolean(),
    linkText: z.string().optional(),
    linkUrl: z.string().optional(),
  }),
});

export type OrganizationFormSchema = z.infer<typeof FormikSchema>;

interface OrganizationFormProps {
  organization?: GQL.GetOrganization_organization_by_pk;
  configs?: AllOrgConfigs;
  onSubmit: (values: OrganizationFormSchema) => void;
  submitting: boolean;
}

export const OrganizationForm = ({
  organization,
  configs,
  onSubmit,
  submitting,
}: OrganizationFormProps) => {
  const isNew = !organization;
  const timezoneNames = React.useMemo(() => {
    return (Intl as any).supportedValuesOf("timeZone");
  }, []);

  /* October 2024, 
    we only have NO_STUDENT condition
    that belongs on the PARENT_PORTAL entry_point
    with the INFO type announcement 
    but we can have more in the future
  */
  const noStudentAnnouncement = organization?.announcements.find(
    (announcement) =>
      announcement.condition === GQL.announcement_condition_enum.NO_STUDENT
  );

  const initialValues: OrganizationFormSchema = useMemo(() => {
    const organizationConfigs = ConfigForm.getInitialValues(configs);

    if (organizationConfigs.Contacts.disabled) {
      organizationConfigs.Contacts = {
        path: organization?.path || "",
        contacts: [],
        disabled: false,
      };
    }
    return {
      organizationConfigs,
      name: organization?.name || "",
      path: organization?.path || "",
      timezoneName: organization?.timezone_name || "+00:00",
      noStudentAnnouncement: {
        id: noStudentAnnouncement?.id || "",
        title: noStudentAnnouncement?.title || "",
        description: noStudentAnnouncement?.description || "",
        active: noStudentAnnouncement?.active ?? false,
        showType:
          noStudentAnnouncement?.display_type ??
          GQL.announcement_display_type_enum.BANNER,
        linkActive:
          !!noStudentAnnouncement?.link_text &&
          !!noStudentAnnouncement?.link_url,
        linkText: noStudentAnnouncement?.link_text || "",
        linkUrl: noStudentAnnouncement?.link_url || "",
      },
    };
  }, [
    configs,
    organization?.name,
    organization?.path,
    organization?.timezone_name,
    noStudentAnnouncement,
  ]);

  function editPolicyUrl(
    organization: GQL.GetOrganization_organization_by_pk
  ): string {
    return Url.Admin.Organizations.editPolicy(organization.id);
  }

  // Helper functions to get filtered contacts
  const getPhones = (contacts: any[]) =>
    contacts.filter((c) => c.type === "phone");
  const getEmails = (contacts: any[]) =>
    contacts.filter((c) => c.type === "email");
  const getLinks = (contacts: any[]) =>
    contacts.filter((c) => c.type === "link");

  return (
    <Formik<OrganizationFormSchema>
      initialValues={initialValues}
      validate={validateWithZod(FormikSchema)}
      onSubmit={onSubmit}
    >
      {(form) => {
        const contactsConfig = form.values.organizationConfigs.Contacts;
        const contacts =
          "contacts" in contactsConfig ? contactsConfig.contacts : [];

        return (
          <Form>
            <VStack align="left" spacing={8}>
              <Heading as="h1" size="xl">
                {isNew
                  ? "Create Organization"
                  : `Edit ${organization?.name || "Organization"}`}
              </Heading>
              <Heading as="h2" size="lg">
                Basic information
              </Heading>
              <Flex direction="column">
                <InputControl name="name" label="Organization name">
                  <Input size="sm" placeholder="Organization name" />
                </InputControl>
              </Flex>
              <Flex direction="column">
                <InputControl name="path" label="Path">
                  <Input size="sm" placeholder="Path" />
                </InputControl>
              </Flex>
              <Flex direction="column">
                <FormControl>
                  <FormLabel htmlFor="timezoneName">
                    Timezone offset from UTC
                  </FormLabel>
                  <Field as={Select} id="timezoneName" name="timezoneName">
                    {timezoneNames.map((value: string) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    ))}
                  </Field>
                </FormControl>
              </Flex>

              <hr />

              <Heading as="h2" size="lg">
                Contact information
              </Heading>

              <FieldArray
                name="organizationConfigs.Contacts.contacts"
                render={(arrayHelpers) => (
                  <VStack align="stretch" spacing={4}>
                    {getPhones(contacts).map((contact, index) => {
                      const contactIndex = contacts.indexOf(contact);
                      return (
                        <Flex key={contact.id} gap={4} align="flex-end">
                          <InputControl
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.label`}
                            label="Phone label"
                            flex="1 1 50%"
                            isRequired
                          >
                            <Input size="sm" placeholder="Phone label" />
                          </InputControl>
                          <InputControl
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.value`}
                            label="Phone"
                            flex="1 1 50%"
                            isRequired
                          >
                            <Input size="sm" placeholder="+12125551212" />
                          </InputControl>
                          <Button
                            size="sm"
                            colorScheme="blue"
                            variant="outline"
                            onClick={() => arrayHelpers.remove(contactIndex)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Flex>
                      );
                    })}

                    <Flex align="center">
                      <Button
                        onClick={() =>
                          arrayHelpers.push({
                            id: crypto.randomUUID(),
                            type: "phone",
                            label: "",
                            value: "",
                          })
                        }
                        colorScheme="gray"
                        variant="outline"
                        size="sm"
                        alignSelf="flex-start"
                      >
                        Add phone
                      </Button>
                      <Spacer />
                    </Flex>
                  </VStack>
                )}
              />

              <FieldArray
                name="organizationConfigs.Contacts.contacts"
                render={(arrayHelpers) => (
                  <VStack align="stretch" spacing={4}>
                    {getEmails(contacts).map((contact, index) => {
                      const contactIndex = contacts.indexOf(contact);
                      return (
                        <Flex key={contact.id} gap={4} align="flex-end">
                          <InputControl
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.label`}
                            label="Email label"
                            flex="1 1 50%"
                            isRequired
                          >
                            <Input size="sm" placeholder="Email label" />
                          </InputControl>
                          <InputControl
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.value`}
                            label="Email"
                            flex="1 1 50%"
                            isRequired
                          >
                            <Input size="sm" placeholder="support@avela.org" />
                          </InputControl>
                          <Button
                            size="sm"
                            colorScheme="blue"
                            variant="outline"
                            onClick={() => arrayHelpers.remove(contactIndex)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Flex>
                      );
                    })}

                    <Flex align="center">
                      <Button
                        onClick={() =>
                          arrayHelpers.push({
                            id: crypto.randomUUID(),
                            type: "email",
                            label: "",
                            value: "",
                          })
                        }
                        colorScheme="gray"
                        variant="outline"
                        size="sm"
                        alignSelf="flex-start"
                      >
                        Add email
                      </Button>
                      <Spacer />
                    </Flex>
                  </VStack>
                )}
              />

              <hr />

              <Heading as="h2" size="lg">
                Additional resources
              </Heading>

              <FieldArray
                name="organizationConfigs.Contacts.contacts"
                render={(arrayHelpers) => (
                  <VStack align="stretch" spacing={4}>
                    {getLinks(contacts).map((contact, index) => {
                      const contactIndex = contacts.indexOf(contact);
                      return (
                        <Flex key={contact.id} gap={4} align="flex-end">
                          <InputControl
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.label`}
                            label="Link label"
                            flex="1 1 50%"
                            isRequired
                          >
                            <Input size="sm" placeholder="Link label" />
                          </InputControl>
                          <InputControl
                            name={`organizationConfigs.Contacts.contacts.${contactIndex}.value`}
                            label="Link URL"
                            flex="1 1 50%"
                            isRequired
                          >
                            <Input size="sm" placeholder="https://..." />
                          </InputControl>
                          <Button
                            size="sm"
                            colorScheme="blue"
                            variant="outline"
                            onClick={() => arrayHelpers.remove(contactIndex)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Flex>
                      );
                    })}

                    <Flex align="center">
                      <Button
                        onClick={() =>
                          arrayHelpers.push({
                            id: crypto.randomUUID(),
                            type: "link",
                            label: "",
                            value: "",
                          })
                        }
                        colorScheme="gray"
                        variant="outline"
                        size="sm"
                        alignSelf="flex-start"
                      >
                        Add additional resource
                      </Button>
                      <Spacer />
                    </Flex>
                  </VStack>
                )}
              />

              <hr />

              {!!organization && (
                <>
                  <Heading as="h2" size="lg">
                    Access & policies
                  </Heading>
                  <p>Configure access policies for the organization.</p>
                  <Flex align="center">
                    <Button
                      as={NavLink}
                      colorScheme="gray"
                      to={editPolicyUrl(organization)}
                      aria-label={`Edit access policies`}
                      variant="outline"
                    >
                      Edit access policies
                    </Button>
                    <Spacer />
                  </Flex>
                  <hr />
                </>
              )}

              <Heading as="h2" size="lg">
                General settings
              </Heading>

              <ConfigForm.ConfigForm />

              <AnnouncementForm />

              <Flex align="center">
                <Spacer />
                <Button
                  as={NavLink}
                  to={Url.Admin.Organizations.index()}
                  variant="link"
                >
                  Cancel
                </Button>
                <Button type="submit" marginLeft={4} isLoading={submitting}>
                  {isNew ? "Create" : "Update"}
                </Button>
              </Flex>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
