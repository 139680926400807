import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { useMemo } from "react";
import { z } from "zod";
import * as amplitude from "@amplitude/analytics-browser";
import {
  loading,
  notVerified,
  ok,
  Status,
  unauthenticated,
  UserData,
} from "../types/authData";
import { HasuraRole } from "../types/hasuraRole";
import { Permission, PermissionSchema } from "../types/permissions";
import useAccessToken from "./useAccessToken";

const EmailVerificationOverrideSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("primary-identity"),
    isVerified: z.boolean(),
  }),
  z.object({
    type: z.literal("secondary-identity"),
    isVerified: z.boolean(),
    email: z.string(),
    userId: z.string().optional(),
    provider: z.string().optional(),
  }),
  z.object({
    type: z.literal("no-verification"),
    isVerified: z.boolean(),
  }),
]);
export type EmailVerificationOverride = z.infer<
  typeof EmailVerificationOverrideSchema
>;

export type User = {
  id: string;
  role: HasuraRole;
  userGroup: string;
  loginType: Email | Sms;
  organizations: uuid[];
  permissions: Permission[];
  currentSchoolIds: uuid[];
  applyingSchoolIds: uuid[];
  tokenIssuedAt: number;
};

type Email = {
  type: "email";
  email: string;
  isVerified: boolean;
};

type Sms = { type: "sms" };

function pickRole(assignedRoles: HasuraRole[]): HasuraRole {
  return assignedRoles.reduce((role, nextRole) => {
    if (nextRole === HasuraRole.ADMIN) return HasuraRole.ADMIN;
    if (nextRole === HasuraRole.ORG_ADMIN) return HasuraRole.ORG_ADMIN;
    if (nextRole === HasuraRole.DISTRICT_ADMIN)
      return HasuraRole.DISTRICT_ADMIN;
    if (nextRole === HasuraRole.SCHOOL_ADMIN) return HasuraRole.SCHOOL_ADMIN;
    return role;
  }, HasuraRole.USER);
}
const jwtSchema = z.object({
  "https://hasura.io/jwt/claims": z.object({
    "x-hasura-user-group": z.string(),
    "x-hasura-avela-organization-ids": z
      .string()
      .transform((value) => value.replace("{", "").replace("}", "").split(",")),
    "x-hasura-avela-school-ids": z.string().transform((value) =>
      value
        .replace("{", "")
        .replace("}", "")
        .split(",")
        .filter((x) => x)
    ),
    "x-hasura-avela-attending-school-ids": z.string().transform((value) =>
      value
        .replace("{", "")
        .replace("}", "")
        .split(",")
        .filter((x) => x)
    ),
  }),

  "https://hasura.io/jwt/claims/assignedRoles": z.string().array(),
  "https://hasura.io/jwt/claims/permissions": z.array(PermissionSchema),
  iat: z.number(),
});

export default function useUser(): UserData<User> {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const token = useAccessToken();

  return useMemo(() => {
    if (!isAuthenticated) return unauthenticated;
    if (token.status === Status.UNAUTHENTICATED) return unauthenticated;
    if (!user?.sub) return unauthenticated;
    if (isLoading) return loading;
    if (token.status === Status.LOADING) return loading;

    const jwt = jwtSchema.safeParse(jwt_decode(token.data));
    const organizations = jwt.success
      ? jwt.data["https://hasura.io/jwt/claims"][
          "x-hasura-avela-organization-ids"
        ]
      : [];
    let assignedRoles: HasuraRole[] =
      user["https://hasura.io/jwt/claims/assignedRoles"];
    if (!Array.isArray(assignedRoles)) {
      assignedRoles = [];
    }

    const userGroup = jwt.success
      ? jwt.data["https://hasura.io/jwt/claims"]["x-hasura-user-group"]
      : "";

    const permissions: Permission[] = jwt.success
      ? jwt.data["https://hasura.io/jwt/claims/permissions"]
      : [];

    const isVerified = (user.email_verified ?? false) || isSSOUser(user.sub);

    const loginType: Email | Sms =
      user.email === undefined
        ? { type: "sms" }
        : {
            type: "email",
            email: user.email,
            isVerified,
          };
    const currentSchoolIds = jwt.success
      ? jwt.data["https://hasura.io/jwt/claims"][
          "x-hasura-avela-attending-school-ids"
        ]
      : [];
    const applyingSchoolIds = jwt.success
      ? jwt.data["https://hasura.io/jwt/claims"]["x-hasura-avela-school-ids"]
      : [];

    const data: User = {
      id: user.sub,
      role: pickRole(assignedRoles),
      userGroup,
      loginType,
      organizations,
      permissions,
      currentSchoolIds,
      applyingSchoolIds,
      tokenIssuedAt: jwt.success ? jwt.data.iat : 0,
    };

    const identify = new amplitude.Identify();

    amplitude.identify(identify, {
      user_id: user.sub,
    });

    const emailVerificationOverride = EmailVerificationOverrideSchema.safeParse(
      user.emailVerificationOverride
    );
    if (
      emailVerificationOverride.success &&
      !emailVerificationOverride.data.isVerified
    ) {
      return notVerified<User>(data, emailVerificationOverride.data);
    }

    return ok(data);
  }, [isAuthenticated, isLoading, token, user]);
}

function isSSOUser(userId: string): boolean {
  const ssoPrefix = ["waad|", "samlp|"];
  return (
    ssoPrefix.find((prefix) => {
      return userId.startsWith(prefix);
    }) !== undefined
  );
}
