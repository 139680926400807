import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { Children, ReactNode, useCallback } from "react";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { BannerSelection } from "src/components/Table/BannerSelection";
import { Selection } from "src/hooks/useMultiselectState";
import { hasOrganizations, useOrganization } from "src/hooks/useOrganization";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { isNotNull } from "src/services/predicates";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { BulkTagsPopover } from "../../tags/BulkTagsPopover";
import {
  FormSchool,
  GqlForm,
  GqlSchoolForm,
  SchoolFormId,
  SchoolFormKeyRecord,
  SchoolFormKeyRecordFactory,
} from "../types";
import { BulkExportButton } from "./BulkExportButton";
import { MoreMenu } from "./BulkMoreMenu";
import { BulkVerifyButton } from "./BulkVerifyButton";
import { FormStatusMenuBulk } from "./FormStatusMenuBulk";
import { MenuItem } from "./menuItem";
import { NextStepMenuBulk } from "./NextStepMenuBulk";
import { SendMessageButton } from "./SendMessageButton";
import { SetVisibilityMenuBulk } from "./SetVisibilityMenuBulk";

interface TableBannerProps {
  formTemplate: GQL.FormTemplateFragment;
  enrollmentPeriodId: uuid;
  includeUpdateStatus: boolean;
  onFetchAll: () => Promise<GqlSchoolForm[]>;
  onFetchAllIds: () => Promise<SchoolFormId[]>;
  onFetchByIds: (ids: SchoolFormId[]) => Promise<GQL.ExportForms>;
  onRefetch: () => Promise<unknown>;
  onSelectionChange: (
    selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>
  ) => void;
  selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>;
  hiddenMenuItems: MenuItem[];
}

export const TableBanner: React.FC<TableBannerProps> = ({
  formTemplate,
  enrollmentPeriodId,
  includeUpdateStatus,
  onFetchAll,
  onFetchAllIds,
  onFetchByIds,
  onRefetch,
  onSelectionChange,
  selection,
  hiddenMenuItems,
}) => {
  const organization = useOrganization();

  const fetchSelectedForms = useCallback(async (): Promise<GqlForm[]> => {
    const records = await selection.materialize(onFetchAll);

    return _.uniqBy(
      records.map((schoolRank) => schoolRank.form).filter(isNotNull),
      "id"
    );
  }, [onFetchAll, selection]);

  const fetchSelectedFormKeys = useCallback(async (): Promise<
    SchoolFormId[]
  > => {
    return selection.materializeKeys(async () =>
      (await onFetchAllIds()).map(SchoolFormKeyRecordFactory)
    );
  }, [onFetchAllIds, selection]);

  // This callback is now redundant with fetchSelectedFormKeys(), but it's used
  // in a lot of places and would require a large refactor to replace, so I
  // don't want to remove it just yet.
  // TODO: Remove this handler.
  const fetchSelectedFormSchools = useCallback(async (): Promise<
    FormSchool[]
  > => {
    const selected = await fetchSelectedFormKeys();
    return selected.map((item) => {
      const form = item.formId ? { id: item.formId } : null;
      const school = item.schoolId ? { id: item.schoolId } : null;
      return { form, school };
    });
  }, [fetchSelectedFormKeys]);

  const handleAfterMakeOffers = useCallback(() => {
    onSelectionChange(selection.clear());
  }, [selection, onSelectionChange]);

  const hasEditStatusPermissionAdmins = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.DISTRICT_ADMIN,
  ]);

  // hard-coding to allow school admin edit permissions for some organization(s)
  // TODO: remove when custom user roles allow for school admin to configure more granular form edit permissions
  const hasEditStatusPermissionSchoolAdmin =
    useRequiredHasuraRoles([HasuraRole.SCHOOL_ADMIN]) &&
    hasOrganizations(
      organization,
      "saisd",
      "metco",
      "tulsa",
      "garlandisd",
      "nolaps",
      "newarkcommonapp",
      "phillyprek",
      "philasd",
      "bezosacademy",
      "enrollwcc",
      "oaklandenrolls",
      "aimsk12",
      "efcps",
      "wcsdschoolofchoice",
      "enrollbuffalocharters",
      "leadps",
      "schoolappstl",
      "schoolappkc",
      "pisota",
      "rvla",
      "evcs",
      "tapestry",
      "buffsci",
      "kccs",
      "enterprise",
      "primaryhall"
    );

  const hasEditStatusPermission =
    hasEditStatusPermissionAdmins || hasEditStatusPermissionSchoolAdmin;

  return (
    <BannerSelection
      selection={selection}
      overflowMenu={(children: ReactNode) => {
        if (
          !hiddenMenuItems.includes(MenuItem.Offers) ||
          Children.count(children) > 0
        ) {
          return (
            <MoreMenu
              hiddenMenuItems={hiddenMenuItems}
              fetchSelectedFormSchools={fetchSelectedFormSchools}
              onMakeOfferSuccess={handleAfterMakeOffers}
              onRefetch={onRefetch}
            >
              {children}
            </MoreMenu>
          );
        }

        return <></>;
      }}
    >
      <WithUserPermissions permissions={["message:create"]}>
        <SendMessageButton fetchSelectedFormKeys={fetchSelectedFormKeys} />
      </WithUserPermissions>

      {!hiddenMenuItems.includes(MenuItem.Export) && (
        <WithUserPermissions permissions={["form:read"]}>
          <BulkExportButton
            formTemplate={formTemplate}
            onFetchAllIds={onFetchAllIds}
            onFetchByIds={onFetchByIds}
            selection={selection}
          />
        </WithUserPermissions>
      )}

      {!hiddenMenuItems.includes(MenuItem.BulkVerify) && (
        <WithUserPermissions permissions={["form:update"]}>
          <BulkVerifyButton
            formTemplate={formTemplate}
            onFetchAllIds={onFetchAllIds}
            selection={selection}
          />
        </WithUserPermissions>
      )}

      {!hiddenMenuItems.includes(MenuItem.BulkStatusUpdate) && (
        <WithUserPermissions permissions={["form:update"]}>
          {includeUpdateStatus && hasEditStatusPermission && (
            <Box>
              <FormStatusMenuBulk
                fetchSelectedFormKeys={fetchSelectedFormKeys}
                refetchForms={onRefetch}
              />
            </Box>
          )}
        </WithUserPermissions>
      )}

      {!hiddenMenuItems.includes(MenuItem.BulkStatusDescriptionUpdate) && (
        <WithUserPermissions permissions={["form:update"]}>
          {hasEditStatusPermission && (
            <Box>
              <NextStepMenuBulk
                formTemplateId={formTemplate.id}
                handleNextStepBulk={fetchSelectedForms}
              />
            </Box>
          )}
        </WithUserPermissions>
      )}

      {!hiddenMenuItems.includes(MenuItem.Tag) && (
        <WithUserPermissions permissions={["form:update"]}>
          <BulkTagsPopover
            enrollmentPeriodId={enrollmentPeriodId}
            formTemplateId={formTemplate.id}
            selection={selection}
            refetchForms={onRefetch}
          />
        </WithUserPermissions>
      )}

      {!hiddenMenuItems.includes(MenuItem.BulkSetVisibility) && (
        <WithUserPermissions permissions={["form:update"]}>
          <Box>
            <SetVisibilityMenuBulk
              fetchSelectedFormKeys={fetchSelectedFormKeys}
              refetchForms={onRefetch}
            />
          </Box>
        </WithUserPermissions>
      )}
    </BannerSelection>
  );
};
