import { groupBy } from "lodash";
import { ilikeNormalizedSearchFilter } from "src/components/graphql/utils";
import {
  AttendanceTypes,
  FILTER_TYPES,
  FormTabsTypes,
  SearchAndFilterTypes,
  VISIBILITY_FILTER_OPTIONS,
  VisibilityOptions,
} from "src/constants";
import { SortField } from "src/hooks/useCommonSearchParams";
import { User } from "src/hooks/useUser";
import { includeAllAdditionalQuestions } from "src/services/formTemplate/question";
import { isNotNull, isValueOf } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { FormListImportRow } from "../components/ImportMenuInput";
import { ColumnId } from "../formTables/constants";

export const buildSearchQuery = (
  search: string
): GQL.search_form_by_school_bool_exp => {
  return {
    form_searchable_text: {
      searchable_text: ilikeNormalizedSearchFilter(search),
    },
  };
};

const buildStatusFilterQuery = (statuses: string[]) => ({
  form_status: { _in: statuses as GQL.form_status_enum[] },
});

const buildNoSchoolRankQuery = (
  searchParams: URLSearchParams
): GQL.search_form_by_school_bool_exp | undefined => {
  if (
    searchParams.get(SearchAndFilterTypes.School)?.match(/noschool/) ||
    searchParams.get(SearchAndFilterTypes.Rank)?.match(/norank/)
  ) {
    return {
      form_school_rank_id: { _is_null: true },
      previous_offer_id: { _is_null: true },
      previous_waitlist_id: { _is_null: true },
    };
  }
  return undefined;
};

export const buildFormSchoolRankFilterQuery = (
  searchParams: URLSearchParams,
  claims: User | null
): GQL.search_form_by_school_bool_exp | undefined => {
  const schoolQuery = buildSchoolsFilterQuery(searchParams, claims);
  const rankQuery = buildRanksFilterQuery(
    searchParams.get(SearchAndFilterTypes.Rank)
  );
  const noSchoolRankQuery = buildNoSchoolRankQuery(searchParams);
  const formSchoolTagQuery = buildFormSchoolTagsFilterQuery(
    searchParams.get(SearchAndFilterTypes.Tag)
  );
  const formTagQuery = buildFormTagsFilterQuery(
    searchParams.get(SearchAndFilterTypes.Tag)
  );
  const capacityQuery = buildCapacityFilterQuery(
    searchParams.get(SearchAndFilterTypes.WithinCapacity)
  );

  const queryTerms: GQL.search_form_by_school_bool_exp[] = [];

  if (schoolQuery) {
    queryTerms.push({
      _or: [schoolQuery, { previous_offer: schoolQuery }],
    });
  }
  if (rankQuery || noSchoolRankQuery) {
    const rankOr: GQL.search_form_by_school_bool_exp[] = [];
    if (rankQuery) rankOr.push(rankQuery);
    if (noSchoolRankQuery) rankOr.push(noSchoolRankQuery);
    queryTerms.push({ _or: rankOr });
  }
  if (capacityQuery) {
    queryTerms.push({ form_school_rank: capacityQuery });
  }
  if (formSchoolTagQuery || formTagQuery) {
    const tagOr: GQL.search_form_by_school_bool_exp[] = [];
    if (formSchoolTagQuery)
      tagOr.push({ form_school_rank: formSchoolTagQuery });
    if (formTagQuery) tagOr.push({ form: formTagQuery });
    queryTerms.push({ _or: tagOr });
  }

  return queryTerms.length ? { _and: queryTerms } : undefined;
};

const buildSchoolsFilterQuery = (
  searchParams: URLSearchParams,
  claims: User | null
) => {
  const attendance = searchParams.get(SearchAndFilterTypes.Attendance);
  let schoolIds = searchParams
    .get(SearchAndFilterTypes.School)
    ?.split("_")
    .filter((school) => school !== "noschool");

  // This check assumes that there will be an attendance setting if and only if
  // the user has a school assignment, which is enforced in
  // src/scenes/orgAdmin/forms/index.tsx, but there may be brief interim periods
  // while the page is settling in which this is not true.
  // TODO: Make this enforcement more robust.
  if (attendance === AttendanceTypes.Applying) {
    if (!schoolIds?.length) {
      schoolIds = claims?.applyingSchoolIds ?? [];
    } else {
      schoolIds = schoolIds.filter((id) =>
        claims?.applyingSchoolIds.includes(id)
      );
    }
  } else if (!schoolIds?.length) {
    return undefined;
  }
  return {
    school_id: {
      _in: schoolIds,
    },
  };
};

export const buildDropoffFormSchoolsFilterQuery = (
  searchParams: URLSearchParams,
  claims: User | null
): GQL.assigned_form_bool_exp => {
  const schoolsParam = searchParams.get(SearchAndFilterTypes.School);
  const search = buildSchoolsFilterQuery(searchParams, claims);
  if (!search && schoolsParam?.match(/noschool/)) {
    return {
      previous_offer_id: { _is_null: true },
      previous_waitlist_id: { _is_null: true },
    };
  }

  if (!search) {
    return {};
  }

  return {
    _or: [
      {
        previous_offer: search,
      },
      {
        previous_waitlist: search,
      },
    ],
  };
};

const buildRanksFilterQuery = (
  ranks: string | null
): GQL.search_form_by_school_bool_exp | undefined => {
  if (!ranks?.length) return undefined;

  return {
    rank: {
      _in: ranks
        ?.split("_")
        .filter((rank) => rank !== "norank")
        .map(Number),
    },
  };
};

export const buildDropoffFormTagsFilterQuery = (
  searchParams: URLSearchParams
): GQL.assigned_form_bool_exp => {
  const tagQuery = buildFormSchoolTagsFilterQuery(
    searchParams.get(SearchAndFilterTypes.Tag)
  );
  if (!tagQuery) {
    return {};
  }

  return {
    previous_form: {
      form_school_ranks: {
        ...tagQuery,
      },
    },
  };
};

const buildFormSchoolTagsFilterQuery = (
  filterTags: string | null
): GQL.form_school_rank_bool_exp | undefined => {
  if (!filterTags?.length) return undefined;

  return {
    _and: filterTags.split("_").map((filterTag) => ({
      tags: {
        tag_id: {
          _eq: filterTag,
        },
      },
    })),
  };
};

const buildFormTagsFilterQuery = (
  filterTags: string | null
): GQL.form_bool_exp | undefined => {
  if (!filterTags?.length) return undefined;

  return {
    _and: filterTags.split("_").map((filterTag) => ({
      tags: {
        tag_id: {
          _eq: filterTag,
        },
      },
    })),
  };
};

const buildCapacityFilterQuery = (
  programId: string | null
): GQL.form_school_rank_bool_exp | undefined => {
  if (!programId) return undefined;
  // case of no programs
  if (programId === "1") {
    return {
      waitlists: {
        deleted_at: { _is_null: true },
        status: {
          _eq: GQL.waitlist_status_enum.Waitlisted,
        },
        grade: {
          seats_available: { _gt: 0 },
        },
      },
    };
  }
  // case with programs
  return {
    waitlists: {
      deleted_at: { _is_null: true },
      status: {
        _eq: GQL.waitlist_status_enum.Waitlisted,
      },
      grade: {
        program_id: { _eq: programId },
        seats_available: { _gt: 0 },
      },
    },
  };
};

export const buildVerificationsFilterQuery = (
  status: string | null,
  verificationIds: string | null
): GQL.form_bool_exp | undefined => {
  if (!status || status === "All") return;
  const vIds = verificationIds?.split("_").filter((id) => id.trim().length);
  if (vIds?.length) {
    return {
      _and: vIds.map<GQL.form_bool_exp>((vId) => ({
        form_verification_result_statuses: {
          form_verification_id: { _eq: vId },
          verification_status: {
            _eq: status as GQL.verification_status_enum,
          },
        },
      })),
    };
  } else {
    // Match any verification with the target status.
    return {
      form_verification_result_statuses: {
        verification_status: {
          _eq: status as GQL.verification_status_enum,
        },
      },
    };
  }
};

const buildDynamicQuestionsFilterQuery = (dynamicQuestions: string[]) => {
  const formattedQuestions: { question_id?: string; option_id?: string }[] =
    dynamicQuestions.map((question) => ({
      option_id: question.split("~")[0],
      question_id: question.split("~")[1],
    }));

  const groupedQuestions = groupBy(formattedQuestions, "question_id");

  return {
    _and: Object.entries(groupedQuestions).map<GQL.form_bool_exp>(
      ([questionId, options]) => ({
        form_answers: {
          question_id: { _eq: questionId },
          form_answer_options: {
            form_question_option_id: {
              _in: options.map((o) => o.option_id).filter(isNotNull),
            },
          },
        },
      })
    ),
  };
};

const buildGradesFilterQuery = (grades: string[]) => ({
  form_related_grades: { grade_config_id: { _in: grades } },
});

const buildPreferredLanguageQuery = (
  preferredLanguages: (string | null)[]
): GQL.form_bool_exp => {
  const preferredLanguagesNotNull = preferredLanguages.filter(isNotNull);
  const conditions: GQL.form_bool_exp[] = [
    {
      applicant_guardians: {
        guardian: { preferred_language: { _in: preferredLanguagesNotNull } },
      },
    },
  ];
  if (preferredLanguages.includes(null)) {
    conditions.push({
      applicant_guardians: {
        guardian: { preferred_language: { _is_null: true } },
      },
    });
  }
  return {
    _or: conditions,
  };
};

const buildVisibilityQuery = (
  visibility: string[]
): GQL.search_form_by_school_bool_exp => {
  return visibility.includes(VisibilityOptions.Visible)
    ? {
        _or: [
          { is_hidden_from_parent: { _eq: false } },
          { is_hidden_from_parent: { _is_null: true } },
        ],
      }
    : { is_hidden_from_parent: { _eq: true } };
};

export const buildSearchAndFilterQuery = (
  searchParams: URLSearchParams,
  claims: User | null
): GQL.search_form_by_school_bool_exp => {
  const queryTerms: GQL.search_form_by_school_bool_exp[] = [];
  if (searchParams.get(SearchAndFilterTypes.Search)) {
    queryTerms.push(
      buildSearchQuery(searchParams.get(SearchAndFilterTypes.Search) ?? "")
    );
  }

  if (searchParams.get(SearchAndFilterTypes.Status)) {
    const statusArray = searchParams
      .get(SearchAndFilterTypes.Status)
      ?.split("_");

    if (statusArray?.length)
      queryTerms.push(buildStatusFilterQuery(statusArray));
  }

  if (searchParams.get(SearchAndFilterTypes.DynamicQuestions)) {
    const dynamicQuestionsArray = searchParams
      .get(SearchAndFilterTypes.DynamicQuestions)
      ?.split("_");

    if (dynamicQuestionsArray?.length)
      queryTerms.push({
        form: buildDynamicQuestionsFilterQuery(dynamicQuestionsArray),
      });
  }

  if (searchParams.get(SearchAndFilterTypes.Grades)) {
    const gradesArray = searchParams
      .get(SearchAndFilterTypes.Grades)
      ?.split("_");

    if (gradesArray?.length)
      queryTerms.push(buildGradesFilterQuery(gradesArray));
  }

  if (searchParams.get(SearchAndFilterTypes.VerificationStatus)) {
    const verificationsFilter = buildVerificationsFilterQuery(
      searchParams.get(SearchAndFilterTypes.VerificationStatus),
      searchParams.get(SearchAndFilterTypes.VerificationIds)
    );
    if (verificationsFilter) queryTerms.push({ form: verificationsFilter });
  }

  if (searchParams.get(SearchAndFilterTypes.PreferredLanguage)) {
    const preferredLanguages = searchParams
      .get(SearchAndFilterTypes.PreferredLanguage)
      ?.split("_")
      .map((language) => (language === "null" ? null : language));

    if (preferredLanguages?.length)
      queryTerms.push({
        form: buildPreferredLanguageQuery(preferredLanguages),
      });
  }

  if (searchParams.get(SearchAndFilterTypes.Visibility)) {
    const visibilityStatus = searchParams
      .get(SearchAndFilterTypes.Visibility)
      ?.split("_");

    if (
      visibilityStatus?.length &&
      visibilityStatus?.length !== VISIBILITY_FILTER_OPTIONS.length
    ) {
      queryTerms.push(buildVisibilityQuery(visibilityStatus));
    }
  }

  // TODO: Factor these into their own function.
  if (searchParams.get(SearchAndFilterTypes.AttendingSchool)) {
    const attendingSchoolIds = searchParams
      .get(SearchAndFilterTypes.AttendingSchool)
      ?.split("_")
      .filter((school) => school !== "noschool");

    const noSchool = searchParams
      .get(SearchAndFilterTypes.AttendingSchool)
      ?.match(/noschool/);

    queryTerms.push({
      _or: [
        ...(attendingSchoolIds?.length
          ? [
              {
                applicant_attending_school: {
                  school_id: { _in: attendingSchoolIds },
                },
              },
            ]
          : []),
        ...(noSchool
          ? [
              {
                _not: {
                  applicant_attending_school: {
                    school_id: { _is_null: false },
                  },
                },
              },
            ]
          : []),
      ],
    });
  }
  // This check assumes that there will be an attendance setting if and only if
  // the user has a school assignment, which is enforced in
  // src/scenes/orgAdmin/forms/index.tsx, but there may be brief interim periods
  // while the page is settling in which this is not true.
  // TODO: Make this enforcement more robust.
  if (
    searchParams.get(SearchAndFilterTypes.Attendance) ===
    AttendanceTypes.Attending
  ) {
    const attendingSchoolIds = claims?.currentSchoolIds ?? [];
    queryTerms.push({
      applicant_attending_school: {
        school_id: { _in: attendingSchoolIds },
      },
    });
  }

  return { _and: queryTerms };
};

export const buildOrderClause = (
  sortField: SortField | null,
  role: HasuraRole
): GQL.search_form_by_school_order_by => {
  switch (sortField?.sortKey) {
    case ColumnId.School:
      return { school_name: sortField.sortType };

    case ColumnId.SchoolRank:
      if (role === HasuraRole.SCHOOL_ADMIN) return {};
      return { rank: sortField.sortType };

    case ColumnId.Student:
      return { person_full_name: sortField.sortType };

    case ColumnId.Grade:
      return { grade_order: sortField.sortType };

    case ColumnId.Status:
      return { submitted_at: sortField.sortType };

    case ColumnId.SubStatus:
      return { form_school_rank_sub_status: sortField.sortType };

    case ColumnId.WaitlistPosition:
      return { waitlist_position: sortField.sortType };

    case ColumnId.Tiebreaker:
      return { lottery_order: sortField.sortType };

    default:
      return {};
  }
};

export interface DynamicQuestionFilter {
  questionId: string;
  questionLabel: string;
  options?: { id: uuid; label: string }[];
  questionType: string;
}

export interface GradesQuestionFilter {
  questionLabel: string;
  options?: GQL.GetGradesConfigByOrganization_grade_config[];
}

export interface SchoolOptions {
  label: string;
  value: uuid;
  id: uuid;
}

export interface RankOptions {
  label: string;
  value?: GQL.GetRanksForFilters_form_school_rank["rank"];
  id: string;
}

export interface TagOption {
  label: GQL.GetTagGroupsForFilters_tag_group_tags["name"];
  value: GQL.GetTagGroupsForFilters_tag_group_tags["id"];
  id: GQL.GetTagGroupsForFilters_tag_group_tags["id"];
}

export interface CapacityOptions {
  label: GQL.GetProgramsForCapacitiesFilter_program_group_programs["label"];
  value: GQL.GetProgramsForCapacitiesFilter_program_group_programs["id"];
  id: GQL.GetProgramsForCapacitiesFilter_program_group_programs["id"];
}

export interface PreferredLanguageOptions {
  label: string;
  value: string;
  id: string;
}

export interface VerificationOptions {
  label: GQL.GetVerificationsForFilters_form_verification["label"];
  value: GQL.GetVerificationsForFilters_form_verification["id"];
  id: GQL.GetVerificationsForFilters_form_verification["id"];
}

export const formatDynamicQuestionFilters = (
  formTemplate: GQL.FormTemplateFragment
) => {
  return formTemplate.sections
    .flatMap((sec) => includeAllAdditionalQuestions(sec.questions))
    .filter((q) => [AF.SingleSelectType, AF.MultiSelectType].includes(q.type))
    .map<DynamicQuestionFilter>((question) => ({
      questionId: question.id,
      questionLabel: question.question,
      options: question.form_question?.form_question_options.flatMap(
        (questionOption) => questionOption
      ),
      questionType: question.type,
    }));
};

export const formatGradesQuestionFilters = (
  data: GQL.GetGradesConfigByOrganization
) => {
  return {
    questionLabel: "Grade applying to",
    options: data.grade_config,
  };
};

export const getSchoolFiltersInitialValues = (schools: string) => {
  const schoolFilters = schools.split("_").map((school) => ({
    id: school,
  }));
  return schoolFilters;
};

export const verifyIfFiltersAreApplied = (searchParams: URLSearchParams) =>
  FILTER_TYPES.some((filterType: SearchAndFilterTypes) =>
    searchParams.get(filterType)
  );

export const buildSelectedTabFilter = (
  searchParams: URLSearchParams
): GQL.search_form_by_school_bool_exp => {
  const selectedTab = searchParams.get(SearchAndFilterTypes.Tab);
  const subStatusArray = searchParams
    .get(SearchAndFilterTypes.SubStatus)
    ?.split("_");

  switch (selectedTab) {
    case FormTabsTypes.Submissions:
      return submissionsTabFetchQuery;

    case FormTabsTypes.Waitlists:
      return buildFormSubStatusQuery(
        subStatusArray?.length
          ? subStatusArray
          : Object.values(GQL.waitlist_status_enum)
      );

    case FormTabsTypes.Offers:
      return buildFormSubStatusQuery(
        subStatusArray?.length
          ? subStatusArray
          : Object.values(GQL.offer_status_enum)
      );

    case FormTabsTypes.All:
      return subStatusArray?.length
        ? buildFormSubStatusQuery(subStatusArray)
        : {};

    default:
      return {};
  }
};

export const buildDropoffFormSubStatusQuery = (
  searchParams: URLSearchParams
): GQL.assigned_form_bool_exp => {
  const subStatusArray = searchParams
    .get(SearchAndFilterTypes.SubStatus)
    ?.split("_");

  if (!subStatusArray || subStatusArray.length === 0) {
    return {};
  }

  return {
    previous_offer: {
      status: { _in: filterOfferStatus(subStatusArray) },
    },
  };
};

export const buildFormSubStatusQuery = (
  subStatusArray: string[] | undefined
): GQL.search_form_by_school_bool_exp => ({
  _or: [
    {
      waitlists: {
        deleted_at: { _is_null: true },
        status: { _in: filterWaitlistStatus(subStatusArray) },
      },
    },
    { offer_status: { _in: filterOfferStatus(subStatusArray) } },
    {
      form_school_rank_status: { _in: filterSchoolRankStatus(subStatusArray) },
    },
  ],
});

export const submissionsTabFetchQuery: GQL.search_form_by_school_bool_exp = {
  form_school_rank_status: { _is_null: true },
  has_offer: { _eq: false },
  has_waitlist: { _eq: false },
};

const filterWaitlistStatus = (
  status: string[] | undefined
): GQL.waitlist_status_enum[] => {
  return status?.length
    ? status.filter(isValueOf(GQL.waitlist_status_enum))
    : Object.values(GQL.waitlist_status_enum);
};

const filterOfferStatus = (
  status: string[] | undefined
): GQL.offer_status_enum[] => {
  return status?.length
    ? status.filter(isValueOf(GQL.offer_status_enum))
    : Object.values(GQL.offer_status_enum);
};

const filterSchoolRankStatus = (
  status: string[] | undefined
): GQL.form_school_rank_status_enum[] => {
  return status?.length
    ? status.filter(isValueOf(GQL.form_school_rank_status_enum))
    : Object.values(GQL.form_school_rank_status_enum);
};

export const buildFormSchoolFilter = (
  data: FormListImportRow[]
): GQL.search_form_by_school_bool_exp => {
  if (data.length <= 0) {
    return {};
  }

  return {
    _or: data.map((row) => {
      const schoolId = row["School ID"];

      // filter by form id and school id
      if (schoolId && schoolId.length > 0) {
        return {
          form_id: { _eq: row["App ID"] },
          form_school_rank: { school_id: { _eq: schoolId } },
        };
      }

      // only filter by form id
      return {
        form_id: { _eq: row["App ID"] },
      };
    }),
  };
};
