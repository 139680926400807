import { Radio } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { Card } from "src/components/Layout/Card";
import { AddressBookAnswer } from "src/schemas/Address";
import { FormattedAddress } from "./FormattedAddress";

export type AddressRadioOptionProps = AddressBookAnswer & {
  isInvalid: boolean | undefined;
};

export const AddressRadioOption: FunctionComponent<AddressRadioOptionProps> = (
  props
) => {
  const { kind, ...address } = props;

  const value: string =
    kind === "existingAddress" ? props.user_address_id : props.kind;

  // minimize enclosing padding to maximize Radio click area
  return (
    <Card showBorder padding={0}>
      <Radio
        value={value}
        padding={4}
        isInvalid={false} // disable red ring
      >
        <FormattedAddress address={address} />
      </Radio>
    </Card>
  );
};
