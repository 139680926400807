import { gql } from "@apollo/client";
import {
  SCHOOL_GRADES_FRAGMENT,
  SIMPLE_SCHOOL_FRAGMENT,
} from "src/operations/fragments/school";

export const GET_SCHOOLS_FOR_FORM = gql`
  ${SIMPLE_SCHOOL_FRAGMENT}
  ${SCHOOL_GRADES_FRAGMENT}

  query GetSchoolsForFormTemplate($form_id: uuid!) {
    school_by_form(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          school: { status: { _eq: "Active" } }
        }
      }
      order_by: { school: { name: asc } }
    ) {
      school {
        ...SimpleSchoolFragment
        ...SchoolGradesFragment
      }
    }
  }
`;

export const GET_SCHOOLS_FOR_ORGANIZATION = gql`
  ${SIMPLE_SCHOOL_FRAGMENT}

  query GetSchoolsForOrganization(
    $organization_id: uuid!
    $enrollment_period_id: uuid!
  ) {
    school(
      where: {
        _and: {
          organization_id: { _eq: $organization_id }
          status: { _eq: "Active" }
          grades: { enrollment_period_id: { _eq: $enrollment_period_id } }
        }
      }
      order_by: { name: asc }
    ) {
      ...SimpleSchoolFragment
    }
  }
`;
