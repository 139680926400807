import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { validateRequiredQuestions } from "../formTemplateValidations";
import { getFormikInitialValues } from "./answer";
import { SelectedSchoolIds } from "./generalSection/question";
import { getCompleteApplicableQuestions } from "./question";
import _ from "lodash";

export function toQuestions(
  section: AF.Section<AF.WithId> | undefined
): AF.Question<AF.WithId>[] {
  if (!section) return [];
  switch (section.type) {
    case "GeneralSection":
    case "PreRankingSection":
      return section.questions;
    case "DisclaimerSection":
    case "SchoolRankingSection":
      return [];

    default:
      const _exhaustiveCheck: never = section;
      return _exhaustiveCheck;
  }
}

/**
 * Checks if a section is editable based on the form status and family editable statuses.
 */
function isSectionEditableForFamily(
  section: AF.Section<AF.WithId>,
  formStatus: GQL.form_status_enum | undefined,
  isFamilyEditableStatusesEnabled: boolean
): boolean {
  if (!isFamilyEditableStatusesEnabled) {
    return true;
  }

  return (
    Array.isArray(section.familyEditableStatuses) &&
    formStatus !== undefined &&
    section.familyEditableStatuses.includes(formStatus)
  );
}

/**
 * Determines if a form section is applicable for viewing to a user based on their answers,
 * their school rankings, the form status.
 */
export function isSectionApplicableForView(
  formStatus: GQL.form_status_enum | undefined,
  section: AF.Section<AF.WithId> | undefined,
  applicationAnswers: GQL.FormAnswerFragment[],
  gradesAnswers: GQL.GradesAnswersFragment[],
  addressAnswers: GQL.AddressAnswersFragment[],
  customQuestionAnswers: GQL.CustomQuestionAnswersFragment[],
  selectedSchoolIds: SelectedSchoolIds
): boolean {
  if (!section) {
    return false;
  }

  if (!("questions" in section)) {
    /*
     * Sections without questions only verify section-specific criteria. These include:
     *
     * - School Ranking Section
     * - Disclaimer Section
     */
    return true;
  }

  const initialValues = getFormikInitialValues(
    section.questions,
    applicationAnswers,
    gradesAnswers,
    addressAnswers,
    customQuestionAnswers
  );

  const applicableQuestions = getCompleteApplicableQuestions(
    section.questions,
    initialValues,
    selectedSchoolIds
  );

  return applicableQuestions.length > 0;
}

/**
 * Determines if a form section is applicable for editing to a user based on their answers,
 * their school rankings, the form status.
 */
export function isSectionApplicableForEdit(
  formStatus: GQL.form_status_enum | undefined,
  section: AF.Section<AF.WithId> | undefined,
  applicationAnswers: GQL.FormAnswerFragment[],
  gradesAnswers: GQL.GradesAnswersFragment[],
  addressAnswers: GQL.AddressAnswersFragment[],
  customQuestionAnswers: GQL.CustomQuestionAnswersFragment[],
  selectedSchoolIds: SelectedSchoolIds,
  // TODO: Feature flag should be retired and removed when the feature is stable
  isFamilyEditableStatusesEnabled: boolean
): boolean {
  if (!section) {
    return false;
  }

  if (
    !isSectionEditableForFamily(
      section,
      formStatus,
      isFamilyEditableStatusesEnabled
    )
  ) {
    return false;
  }

  if (!("questions" in section)) {
    /*
     * Sections without questions only verify section-specific criteria. These include:
     *
     * - School Ranking Section
     * - Disclaimer Section
     */
    return true;
  }

  const initialValues = getFormikInitialValues(
    section.questions,
    applicationAnswers,
    gradesAnswers,
    addressAnswers,
    customQuestionAnswers
  );

  const applicableQuestions = getCompleteApplicableQuestions(
    section.questions,
    initialValues,
    selectedSchoolIds
  );

  return applicableQuestions.length > 0;
}

export function getMissingRequiredQuestions(
  section: AF.Section<AF.WithId>,
  applicationAnswers: GQL.FormAnswerFragment[],
  gradesAnswers: GQL.GradesAnswersFragment[],
  addressAnswers: GQL.AddressAnswersFragment[],
  customQuestionAnswers: GQL.CustomQuestionAnswersFragment[],
  disclaimerAnswers: GQL.GetFormAnswersById_form_disclaimer[],
  selectedSchoolIds: SelectedSchoolIds
) {
  // Check if disclaimer section has signature
  if (section.type === GQL.form_template_section_type_enum.DisclaimerSection) {
    return disclaimerAnswers &&
      disclaimerAnswers.length > 0 &&
      !_.isEmpty(disclaimerAnswers[0]?.signature)
      ? []
      : [GQL.form_template_section_type_enum.DisclaimerSection];
  }

  // Check if school rank section has school ranks
  if (
    section.type === GQL.form_template_section_type_enum.SchoolRankingSection
  ) {
    const schoolCount = selectedSchoolIds.rankedSchoolIds.length;
    return (section.minSchools === undefined ||
      schoolCount >= section.minSchools) &&
      (section.maxSchools === undefined || schoolCount <= section.maxSchools)
      ? []
      : [GQL.form_template_section_type_enum.SchoolRankingSection];
  }

  const formValues = getFormikInitialValues(
    section.questions,
    applicationAnswers,
    gradesAnswers,
    addressAnswers,
    customQuestionAnswers
  );

  const missingAnswerRequiredQuestions = validateRequiredQuestions(
    section.questions,
    selectedSchoolIds,
    formValues
  );

  return missingAnswerRequiredQuestions;
}

export const sectionIsType =
  <T extends AF.SectionType>(sectionType: T) =>
  <ID extends AF.BaseId>(
    section: AF.Section<ID> | null | undefined
  ): section is Extract<AF.Section<ID>, { type: T }> =>
    section?.type === sectionType;

export function findSection<T extends AF.SectionType, ID extends AF.BaseId>(
  sections: AF.GenericSections<ID>,
  sectionType: T
) {
  return sections.find(sectionIsType(sectionType));
}

export function findSections<T extends AF.SectionType, ID extends AF.BaseId>(
  sections: AF.GenericSections<ID>,
  sectionType: T
) {
  return sections.filter(sectionIsType(sectionType));
}

export function findPreRankingSection(
  sections: AF.GenericSections<AF.WithId>
): AF.PreRankingSection<AF.WithId> | undefined {
  return findSection(sections, AF.PreRankingSectionType);
}

export function findSchoolRankingSection(
  sections: AF.GenericSections<AF.WithId>
): AF.SchoolRankingSection<AF.WithId> | undefined {
  return findSection(sections, AF.SchoolRankingSectionType);
}

export function findGeneralSections(sections: AF.GenericSections<AF.WithId>) {
  return findSections(sections, AF.GeneralSectionType);
}
