import { Card, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { FunctionComponent, useCallback } from "react";
import { useLoadGooglePlacesScript } from "src/hooks/useLoadGoogleMaps";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  AddressBookAnswer,
  AddressValidationSchema,
  BaseAddress,
  EXISTING_ADDRESS,
  ExistingAddress,
  INITIALLY_EMPTY_ADDRESS,
} from "src/schemas/Address";
import { address as formatAddress } from "src/services/format";
import { validateWithZod } from "src/services/formValidations";
import {
  UpsertUserAddress,
  UpsertUserAddressVariables,
} from "src/types/graphql";
import { UPSERT_USER_ADDRESS } from "../mutations";
import { GET_USER_ADDRESSES } from "../queries";
import { ValidateNewAddressOrchestrator } from "./ValidateNewAddressOrchestrator";

type ValidateNewAddressFormProps = {
  userId: string;
  onAfterSubmit: (upsertedAddress: ExistingAddress) => Promise<void>;
  addressIsRequired?: boolean;
};

/**
 * Nested form that appears when "Add new address" button is clicked.
 */
export const ValidateNewAddressForm: FunctionComponent<
  ValidateNewAddressFormProps
> = (props) => {
  useLoadGooglePlacesScript();

  const { userId, onAfterSubmit, addressIsRequired } = props;

  const [upsertAddressBookEntry] = useRemoteDataMutation<
    UpsertUserAddress,
    UpsertUserAddressVariables
  >(UPSERT_USER_ADDRESS, {
    refetchQueries: [GET_USER_ADDRESSES],
  });

  const onSubmitNewValidatedAddress = useCallback(
    async (address: BaseAddress) => {
      const { data } = await upsertAddressBookEntry({
        variables: {
          user_address: {
            ...address,
            user_id: userId,
          },
        },
      });
      if (!data?.insert_user_address_one) {
        throw new Error(`Failed to upsert address: ${formatAddress(address)}`);
      }

      const upsertedAddress: AddressBookAnswer = {
        ...address,
        kind: EXISTING_ADDRESS,
        user_address_id: data.insert_user_address_one.id,
        user_id: userId,
      };

      onAfterSubmit(upsertedAddress);
    },
    [upsertAddressBookEntry, userId, onAfterSubmit]
  );

  return (
    <Formik<BaseAddress>
      initialValues={INITIALLY_EMPTY_ADDRESS}
      onSubmit={onSubmitNewValidatedAddress}
      validate={validateWithZod(AddressValidationSchema)}
    >
      {({ errors, touched }) => {
        const showValidationErrors = !isEmpty(errors) && !isEmpty(touched);
        return (
          <FormControl isInvalid={showValidationErrors}>
            <Card
              border={
                showValidationErrors ? "2px solid red" : "2px solid white"
              }
            >
              <ValidateNewAddressOrchestrator
                addressIsRequired={addressIsRequired}
              />
            </Card>
            {showValidationErrors && (
              <FormErrorMessage>An address is required.</FormErrorMessage>
            )}
          </FormControl>
        );
      }}
    </Formik>
  );
};
