import * as DateFns from "date-fns";
import { z } from "zod";
import { hasValue } from "./formValidations";
import { REQUIRED_SHORT_VALIDATION_ERROR } from "./url/constants";

export const nullableWithDefault = (
  zodString: z.ZodString,
  defaultValue: string
) =>
  zodString
    .nullable()
    .transform((value) => (value === null ? defaultValue : value))
    .default(defaultValue);

export const emptyStringAsNull = (zodString: z.ZodString) =>
  zodString
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .default(null);

export const dateStringRequired = (validationMessage: string) =>
  nullableWithDefault(z.string(), "").refine(
    (value) => DateFns.isMatch(value, "yyyy-MM-dd"),
    validationMessage
  );

export function asValidator<T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
  field: keyof T,
  validate: (value: string) => boolean,
  message: string
): z.ZodObject<T> {
  const value = schema.shape[field];
  if (!value) {
    return schema;
  }

  return schema.extend({
    [field]: value.refine(validate, message),
  }) as unknown as z.ZodObject<T>;
}

export function asRequiredField<T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
  ...fields: Array<keyof T>
): z.ZodObject<T> {
  const requiredFields = fields.reduce((accumulateFields, field) => {
    const fieldSchema = schema.shape[field];
    if (!fieldSchema) {
      return accumulateFields;
    }

    return {
      ...accumulateFields,
      [field]: fieldSchema.refine(hasValue, REQUIRED_SHORT_VALIDATION_ERROR),
    };
  }, {} as Record<string, z.ZodTypeAny>);

  return schema.extend(requiredFields) as z.ZodObject<T>;
}
