import * as OrgConfig from "@avela/organization-config-sdk";
import { z } from "zod";

export const DisabledConfigSchema = z.object({ disabled: z.literal(true) });
export const DisabledConfig = {
  disabled: true,
} as const;

export const AllOrgConfigsSchema = z.object({
  Login: z
    .union([OrgConfig.Login.ConfigSchema, DisabledConfigSchema])
    .default(DisabledConfig),
  TranslationOptions: z
    .union([OrgConfig.TranslationOptions.ConfigSchema, DisabledConfigSchema])
    .default(DisabledConfig),
  Match: z
    .union([OrgConfig.Match.ConfigSchema, DisabledConfigSchema])
    .default(DisabledConfig),
  ScheduleExport: z
    .union([OrgConfig.ScheduleExport.ConfigSchema, DisabledConfigSchema])
    .default(DisabledConfig),
  AccountLookup: z
    .union([OrgConfig.AccountLookup.ConfigSchema, DisabledConfigSchema])
    .default(DisabledConfig),
  Contacts: z
    .union([OrgConfig.Contacts.ConfigSchema, DisabledConfigSchema])
    .default(DisabledConfig),
  Auth0: z
    .union([OrgConfig.Auth0.ConfigSchema, DisabledConfigSchema])
    .default(DisabledConfig),
});

export type AllOrgConfigs = z.infer<typeof AllOrgConfigsSchema>;
