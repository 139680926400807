import { RankedSchoolEdit } from "src/scenes/parent/forms/store";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { Strict } from "../Strict";
import { formatQueryResponseAsCustomQuestionProps } from "../customQuestion";
import { toFormVerification } from "../question";
import {
  toAddress,
  toDate,
  toEmail,
  toFileUpload,
  toFreeText,
  toMultiSelectWithBranching,
  toNumber,
  toPhoneNumber,
  toSingleSelectWithBranching,
} from "./questionTypes";

export function toQuestionWithBranching(
  value: GQL.FormTemplateSectionFragment_questions | null | undefined,
  strict: Strict
): AF.Question<AF.WithId> | null {
  if (!value) {
    return null;
  }

  if (
    value?.form_question?.category !==
      GQL.form_question_category_enum.GeneralQuestion &&
    value.type !== GQL.question_type_enum.CustomQuestion
  ) {
    const error = `Invalid question for GeneralSection. Expecting "GeneralCategory" category or a "CustomQuestion" type. Got a ${value.form_question?.category}`;
    if (strict === "strict") {
      throw new Error(error);
    } else {
      console.warn(error);
    }
  }

  switch (value.type) {
    case GQL.question_type_enum.Date:
      return toDate(value);
    case GQL.question_type_enum.Number:
      return toNumber(value);
    case GQL.question_type_enum.FileUpload:
      return toFileUpload(value);
    case GQL.question_type_enum.FreeText:
      return toFreeText(value);
    case GQL.question_type_enum.MultiSelect:
      return toMultiSelectWithBranching(value, strict);
    case GQL.question_type_enum.SingleSelect:
      return toSingleSelectWithBranching(value, strict);
    case GQL.question_type_enum.Email:
      return toEmail(value);
    case GQL.question_type_enum.PhoneNumber:
      return toPhoneNumber(value);
    case GQL.question_type_enum.Address:
      return toAddress(value);
    case GQL.question_type_enum.Grades:
      throw new Error("Grades question is not supported");
    case GQL.question_type_enum.CustomQuestion:
      return formatQueryResponseAsCustomQuestionProps(value);
    default:
      const _exhaustiveCheck: never = value.type;
      return _exhaustiveCheck;
  }
}

export function toBaseFormQuestion(
  value: GQL.FormQuestionWithoutBranchingFragment
): Omit<AF.FormQuestion<AF.WithId>, "type"> {
  const specificToSchools: string[] | undefined =
    value.form_question_schools.length > 0
      ? value.form_question_schools.map((school) => school.school_id)
      : undefined;
  return {
    id: value.id,
    question: value.question,
    category: "General",
    key: value.key ?? undefined,
    requirement: value.is_required ? "Required" : undefined,
    specificToSchools,
    link_text: value.link_text ?? undefined,
    link_url: value.link_url ?? undefined,
    permissionLevel: value.permission_level ?? undefined,
    formVerification: toFormVerification(value),
    constraints: value.constraints,
  };
}

/**
 * Calculate number of new questions added due to the change in ranked schools list.
 * @param questions list of all general category questions
 * @param rankedSchoolsEdit changes in ranked schools list
 * @returns number of new questions
 */
export function calculateNewQuestionsCount(
  questions: AF.Question<AF.WithId>[],
  rankedSchoolsEdit: RankedSchoolEdit
): number {
  const before = calculateApplicableQuestions(questions, {
    rankedSchoolIds: rankedSchoolsEdit.before,
    previousFormSchoolIds: [],
  }).map((question) => question.id);
  const after = calculateApplicableQuestions(questions, {
    rankedSchoolIds: rankedSchoolsEdit.after,
    previousFormSchoolIds: [],
  }).map((question) => question.id);
  const newQuestionCount = after.filter(
    (questionId) => !before.includes(questionId)
  ).length;
  return newQuestionCount;
}

/**
 * Calculate all applicable questions based on the current ranked schools list and school specific property of a question.
 * @param questions list of all general category questions
 * @param rankedSchoolIds current ranked school ids list
 * @returns applicable questions
 */
export function calculateApplicableQuestions(
  questions: AF.Question<AF.WithId>[],
  selectedSchoolIds: SelectedSchoolIds
): AF.Question<AF.WithId>[] {
  return questions.filter((question) => {
    const isApplicable = isQuestionApplicable(question, selectedSchoolIds);
    return isApplicable;
  });
}

export type SelectedSchoolIds = {
  rankedSchoolIds: uuid[];
  previousFormSchoolIds: uuid[];
};
export function isQuestionApplicable(
  question: AF.Question<AF.WithId>,
  { rankedSchoolIds, previousFormSchoolIds }: SelectedSchoolIds
) {
  if (
    question.type === "Grades" ||
    !question.specificToSchools ||
    question.specificToSchools.length === 0
  )
    return true;

  for (const schoolId of previousFormSchoolIds) {
    if (question.specificToSchools.includes(schoolId)) return true;
  }

  for (const schoolId of rankedSchoolIds) {
    if (question.specificToSchools.includes(schoolId)) return true;
  }

  return false;
}
