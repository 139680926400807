import { useMemo } from "react";
import { z } from "zod";

const Auth0ClientIdsSchema = z.object({
  demo: z.string(),
  newarkcommonapp: z.string(),
  nolaps: z.string(),
  bezosacademy: z.string(),
  metco: z.string(),
  tulsa: z.string(),
  saisddemo: z.string(),
  saisd: z.string(),
  iemschools: z.string(),
  boston: z.string(),
  garlandisd: z.string(),
  philasd: z.string(),
  phillyprek: z.string(),
  platoacademy: z.string(),
  saeschool: z.string(),
  platosportsleague: z.string(),
  oaklandenrolls: z.string(),
  enrollwcc: z.string(),
  aimsk12: z.string(),
  efcps: z.string(),
  ebia: z.string(),
  wcsdschoolofchoice: z.string(),
  enrollbuffalocharters: z.string(),
  leadps: z.string(),
  schoolappstl: z.string(),
  schoolappkc: z.string(),
  pisota: z.string(),
  rvla: z.string(),
  thrivesuccess: z.string(),
  evcs: z.string(),
  tapestry: z.string(),
  buffsci: z.string(),
  kccs: z.string(),
  enterprise: z.string(),
  primaryhall: z.string(),
});

const EnvSchema = z.object({
  NODE_ENV: z.string(),
  REACT_APP_HASURA_URL: z.string(),
  REACT_APP_AUTH0_DOMAIN: z.string(),
  REACT_APP_AUTH0_CLIENT_ID: z.string(),
  REACT_APP_ORGANIZATION_AUTH0_CLIENT_IDS: z
    .string()
    .transform((value) => Auth0ClientIdsSchema.parse(JSON.parse(value))),
  REACT_APP_WEGLOT_APIKEY: z.string(),
  REACT_APP_AVELA_OFFLINE_MODE: z.string().optional().default("false"),
  REACT_APP_AVELA_OFFLINE_AUTH_TOKEN: z.string().optional(),
  REACT_APP_GOOGLE_API_KEY: z.string().optional(),
  REACT_APP_MATCH_API_URL: z.string().optional(),
  REACT_APP_FLAGSMITH_URL: z.string(),
  REACT_APP_FLAGSMITH_ENVIRONMENT_KEY: z.string(),
  REACT_APP_ASSETS_BASE_URL: z.string(),
  REACT_APP_CONFIG_URL: z.string(),
  REACT_APP_ORGANIZATION_SERVICE_URL: z.string(),
  REACT_APP_ELIGIBILITY_SERVICE_URL: z.string(),
});

export type Env = z.infer<typeof EnvSchema>;

export function read(): Env {
  return EnvSchema.parse(process.env);
}

export function useEnv() {
  return useMemo(() => read(), []);
}
