import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  IconButton,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import _ from "lodash";
import { UIEvent, useCallback, useEffect, useMemo, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { ToggleButton } from "src/components/Buttons/ToggleButton";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { Glossary } from "src/components/Text/Glossary";
import {
  AttendanceTypes,
  FORM_SCHOOL_RANK_SUB_STATUS,
  FORM_STATUS,
  FilterTypes,
  FormTabsTypes,
  OFFER_STATUS,
  SearchAndFilterTypes,
  VISIBILITY_FILTER_OPTIONS,
  WAITLIST_STATUS,
} from "src/constants";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { useWeglot } from "src/plugins/weglot";
import { handleFilterChange } from "src/services/filter";
import { getLanguageName } from "src/services/languages";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { RemoteData } from "src/types/remoteData";
import {
  GET_PROGRAMS_FOR_CAPACITIES_FILTERS,
  GET_RANKS_FOR_FILTERS,
  GET_SCHOOLS_FOR_FILTERS,
  GET_VERIFICATIONS_FOR_FILTERS,
} from "../graphql/queries";
import {
  CapacityOptions,
  PreferredLanguageOptions,
  RankOptions,
  SchoolOptions,
  VerificationOptions,
  verifyIfFiltersAreApplied,
} from "../graphql/utils";
import { School } from "../types";
import { FormFiltersAdvancedTools } from "./FormFiltersAdvancedTools";
import { FormFiltersTags } from "./FormFiltersTags";
import {
  FormFiltersVerifications,
  VerificationFilters,
  VerificationStatus,
  initialVerificationFilters,
} from "./FormFiltersVerifications";
import { verifyFilterInclusion } from "./utils";

interface FormFiltersProps {
  formTemplateRemoteData: RemoteData<Error, GQL.FormTemplateFragment>;
  onToggleFilter: () => void;
  onClearFilters?: () => void;
  organizationId: string;
  enrollmentPeriodId: uuid;
}

export const FormFilters: React.FC<FormFiltersProps> = ({
  formTemplateRemoteData,
  onToggleFilter,
  onClearFilters,
  organizationId,
  enrollmentPeriodId,
}) => {
  const flags = useFlags(["current-applying-schools"]);
  const { glossary } = useGlossary();
  const hasRankViewPermission = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.DISTRICT_ADMIN,
  ]);

  const { applyingSchoolIds, attendingSchoolIds } = useSchoolAdmin();

  const [searchParams, setSearchParams] = useSearchParams();

  const [schoolInitialValues, setSchoolInitialValues] = useState<
    SchoolOptions[]
  >([]);

  const [attendingSchoolInitialValues, setAttendingSchoolInitialValues] =
    useState<SchoolOptions[]>([]);

  const [schoolsOptions, setSchoolsOptions] = useState<SchoolOptions[]>();

  const [attendingSchoolsOptions, setAttendingSchoolsOptions] =
    useState<SchoolOptions[]>();

  const [rankFilters, setRankFilters] = useState<RankOptions[]>([]);
  const [rankOptions, setRankOptions] = useState<RankOptions[]>([]);

  const [capacityOptions, setCapacityOptions] = useState<CapacityOptions[]>([]);

  const [verificationFilters, setVerificationFilters] =
    useState<VerificationFilters>(initialVerificationFilters);

  const [verificationOptions, setVerificationOptions] = useState<
    VerificationOptions[]
  >([]);

  const [isLoadingMoreFilters, setIsLoadingMoreFilters] =
    useState<boolean>(false);

  const formTemplateId = useMemo(() => {
    return formTemplateRemoteData.map((data) => data.id).withDefault("");
  }, [formTemplateRemoteData]);

  const rankingEnabled = useMemo(() => {
    return formTemplateRemoteData
      .map((data) => {
        const schoolRankingSection = data.sections.find(
          (section) =>
            section?.type ===
            GQL.form_template_section_type_enum.SchoolRankingSection
        );
        return (
          schoolRankingSection?.schools_ranking_section?.ranking_enabled ??
          false
        );
      })
      .withDefault(false);
  }, [formTemplateRemoteData]);

  // Preferred language filter configs
  const weglot = useWeglot();
  const [preferredLanguageFilters, setPreferredLanguageFilters] = useState<
    PreferredLanguageOptions[]
  >([]);
  const [preferredLanguageOptions, setPreferredLanguageOptions] = useState<
    PreferredLanguageOptions[]
  >([]);

  // Verify if preferred language filter is present on the URLSearchParams and set the initial values
  useEffect(() => {
    const preferredLanguageWeglotOptions = weglot?.options.languages.map(
      (language) => {
        const languageName = getLanguageName(language.language_to);
        return {
          label: languageName,
          id: language.language_to,
          value: language.language_to,
        };
      }
    );

    if (preferredLanguageWeglotOptions?.length) {
      const englishLanguage = {
        label: getLanguageName(weglot?.options.language_from ?? ""),
        id: weglot?.options.language_from ?? "",
        value: weglot?.options.language_from ?? "",
      };
      const noPreferredLanguage = {
        label: "No preferred language",
        id: "null",
        value: "null",
      };
      const completePreferredLanguageOptions = [
        noPreferredLanguage,
        englishLanguage,
        ...preferredLanguageWeglotOptions,
      ];
      setPreferredLanguageOptions(completePreferredLanguageOptions);
    }

    if (searchParams.has(FilterTypes.PreferredLanguage)) {
      const preferredLanguageFilters = searchParams
        .get(FilterTypes.PreferredLanguage)!
        .split("_")
        .map((languageCode) => {
          const languageName = getLanguageName(languageCode);
          return {
            label: languageName ?? "",
            id: languageCode,
            value: languageCode,
          };
        });
      setPreferredLanguageFilters(preferredLanguageFilters);
    }
  }, [searchParams, weglot]);

  // Handle for preferred language filter change
  const handlePreferredLanguageFilterChange = (
    newPreferredLanguage: PreferredLanguageOptions[]
  ) => {
    if (newPreferredLanguage.length) {
      const newPreferredLanguageCodes = newPreferredLanguage.map(
        (language) => language.id
      );
      searchParams.set(
        SearchAndFilterTypes.PreferredLanguage,
        newPreferredLanguageCodes.join("_")
      );
    } else {
      searchParams.delete(SearchAndFilterTypes.PreferredLanguage);
    }

    setSearchParams(searchParams);
  };

  // setting up school and rank filter options
  const { remoteData: getSchoolsRemoteData } = useRemoteDataQuery<
    GQL.GetSchoolsForFilters,
    GQL.GetSchoolsForFiltersVariables
  >(GET_SCHOOLS_FOR_FILTERS, {
    skip: !formTemplateRemoteData.hasData(),
    variables: {
      form_template_id: formTemplateId,
      skip_rank: !hasRankViewPermission,
    },
  });

  useEffect(() => {
    if (!getSchoolsRemoteData.hasData()) {
      return;
    }

    const applyingSchools = formatSchoolOptions({
      schools: getSchoolsRemoteData.data.school,
      noSchoolLabel: glossary`No school`,
      idFilter:
        applyingSchoolIds.length &&
        searchParams.get(SearchAndFilterTypes.Attendance) !==
          AttendanceTypes.Attending
          ? applyingSchoolIds
          : undefined,
    });
    setSchoolsOptions(applyingSchools);
    setSchoolInitialValues(
      applyingSchools.filter((school) =>
        searchParams.get(SearchAndFilterTypes.School)?.includes(school.id)
      )
    );

    const attendingSchools = formatSchoolOptions({
      schools: getSchoolsRemoteData.data.attending_school,
      noSchoolLabel: glossary`No school`,
      idFilter:
        attendingSchoolIds.length &&
        searchParams.get(SearchAndFilterTypes.Attendance) ===
          AttendanceTypes.Attending
          ? attendingSchoolIds
          : undefined,
    });
    setAttendingSchoolsOptions(attendingSchools);
    setAttendingSchoolInitialValues(
      attendingSchools.filter((school) =>
        searchParams
          .get(SearchAndFilterTypes.AttendingSchool)
          ?.includes(school.id)
      )
    );

    const ranks = hasRankViewPermission
      ? _.uniq(
          // a skip is used in the query to avoid fetching ranks when the user does not have permission
          // the generated type doesn't reflect that optionality, so this is important to not allow runtime errors
          getSchoolsRemoteData.data.form_school_rank?.map(
            (school) => school.rank
          ) ?? []
        )
          .sort((a, b) => a - b)
          .map(
            (rank): RankOptions => ({
              label: (rank + 1).toString(),
              value: rank,
              id: rank.toString(),
            })
          )
      : [];

    ranks.unshift({ label: "No rank", id: "norank" });

    if (ranks.length) {
      setRankOptions(ranks);

      if (searchParams.has(SearchAndFilterTypes.Rank)) {
        const rankValues = ranks.filter((rank) =>
          searchParams
            .get(SearchAndFilterTypes.Rank)
            ?.includes(rank.id.toString() ?? "")
        );
        setRankFilters(rankValues);
      } else {
        setRankFilters([]);
      }
    }
  }, [
    getSchoolsRemoteData,
    glossary,
    hasRankViewPermission,
    searchParams,
    applyingSchoolIds,
    attendingSchoolIds,
  ]);

  // setting up rank filter options
  const { remoteData: getRanksRemoteData } = useRemoteDataQuery<
    GQL.GetRanksForFilters,
    GQL.GetRanksForFiltersVariables
  >(GET_RANKS_FOR_FILTERS, {
    skip: !formTemplateRemoteData.hasData(),
    variables: {
      form_template_id: formTemplateId,
      skip_rank: !hasRankViewPermission,
    },
  });

  useEffect(() => {
    if (
      !hasRankViewPermission ||
      !getRanksRemoteData.hasData() ||
      !getRanksRemoteData.data.form_school_rank.length
    )
      return;

    const ranks = hasRankViewPermission
      ? getRanksRemoteData.data.form_school_rank.map(
          (school): RankOptions => ({
            label: (school.rank + 1).toString(),
            value: school.rank,
            id: school.rank.toString(),
          })
        )
      : [];

    ranks.unshift({ label: "No rank", id: "norank" });

    if (ranks.length) {
      setRankOptions(ranks);

      if (searchParams.has(SearchAndFilterTypes.Rank)) {
        const rankValues = ranks.filter((rank) =>
          searchParams
            .get(SearchAndFilterTypes.Rank)
            ?.includes(rank.id.toString() ?? "")
        );
        setRankFilters(rankValues);
      } else {
        setRankFilters([]);
      }
    }
  }, [getRanksRemoteData, hasRankViewPermission, searchParams]);

  // setting up capacities filters
  const { remoteData: getProgramsRemoteData } = useRemoteDataQuery<
    GQL.GetProgramsForCapacitiesFilter,
    GQL.GetProgramsForCapacitiesFilterVariables
  >(GET_PROGRAMS_FOR_CAPACITIES_FILTERS, {
    skip: !formTemplateRemoteData.hasData(),
    variables: {
      organization_id: organizationId,
    },
  });

  useEffect(() => {
    if (
      !getProgramsRemoteData.hasData() ||
      !getProgramsRemoteData.data.program_group[0]?.programs.length
    )
      return;

    // Assume there is only 0 or 1 program group for an organization
    const capacityOptions =
      getProgramsRemoteData.data.program_group[0].programs.map((program) => ({
        label: program.label,
        value: program.id,
        id: program.id,
      }));
    setCapacityOptions(capacityOptions);
  }, [getProgramsRemoteData]);

  // setting up verifications filters
  const { remoteData: getVerificationsRemoteData } = useRemoteDataQuery<
    GQL.GetVerificationsForFilters,
    GQL.GetVerificationsForFiltersVariables
  >(GET_VERIFICATIONS_FOR_FILTERS, {
    skip: !formTemplateRemoteData.hasData(),
    variables: {
      form_template_id: formTemplateId,
    },
  });

  const [hasVerifications, setHasVerifications] = useState(false);

  useEffect(() => {
    setHasVerifications(false);

    if (
      !getVerificationsRemoteData.hasData() ||
      !getVerificationsRemoteData.data.form_verification.length
    )
      return;

    const verifications = getVerificationsRemoteData.data.form_verification.map(
      (verification) => ({
        label: verification.label,
        value: verification.id,
        id: verification.id,
      })
    );

    if (verifications.length) {
      setVerificationOptions(verifications);
      setHasVerifications(true);
    }

    const status =
      (searchParams.get(
        SearchAndFilterTypes.VerificationStatus
      ) as VerificationStatus) ?? "All";

    const verificationIds = searchParams.has(
      SearchAndFilterTypes.VerificationIds
    )
      ? searchParams.get(SearchAndFilterTypes.VerificationIds)!.split("_")
      : [];

    setVerificationFilters({
      status,
      verificationIds,
    });
  }, [getVerificationsRemoteData, searchParams]);

  // form filter handler
  const handleFormFilterChange = (
    filterType: SearchAndFilterTypes,
    newValue: string
  ) => {
    if (searchParams.get(SearchAndFilterTypes.Tab) === FormTabsTypes.All) {
      if (newValue === GQL.form_status_enum.Admissions) {
        searchParams.delete(SearchAndFilterTypes.SubStatus);
      }
    }
    setSearchParams(handleFilterChange(searchParams, filterType, newValue));
  };

  // school filter handler
  const handleSchoolFilterChange = (newSchools: SchoolOptions[]) => {
    if (newSchools.length) {
      const newSchoolIds = newSchools.map((school) => school.id);
      searchParams.set(SearchAndFilterTypes.School, newSchoolIds.join("_"));
    } else {
      searchParams.delete(SearchAndFilterTypes.School);
    }

    setSearchParams(searchParams);
  };

  // attending school filter handler
  const handleAttendingSchoolFilterChange = (schools: SchoolOptions[]) => {
    if (schools.length) {
      const newSchoolIds = schools.map((school) => school.id);
      searchParams.set(
        SearchAndFilterTypes.AttendingSchool,
        newSchoolIds.join("_")
      );
    } else {
      searchParams.delete(SearchAndFilterTypes.AttendingSchool);
    }

    setSearchParams(searchParams);
  };

  // rank filter handler
  const handleRankFilterChange = (newRanks: RankOptions[]) => {
    if (newRanks.length) {
      const newRankIds = newRanks.map((rank) => rank.id);
      searchParams.set(SearchAndFilterTypes.Rank, newRankIds.join("_"));
    } else {
      searchParams.delete(SearchAndFilterTypes.Rank);
    }

    setSearchParams(searchParams);
  };

  // verification filter handler
  const handleVerificationFiltersChange = (
    verificationFilters: VerificationFilters
  ) => {
    if (verificationFilters.status !== "All") {
      searchParams.set(
        SearchAndFilterTypes.VerificationStatus,
        verificationFilters.status
      );
      searchParams.set(
        SearchAndFilterTypes.VerificationIds,
        verificationFilters.verificationIds.join("_")
      );
    } else {
      searchParams.delete(SearchAndFilterTypes.VerificationStatus);
      searchParams.delete(SearchAndFilterTypes.VerificationIds);
    }

    setSearchParams(searchParams);
  };

  const [showCapacityOptions, setShowCapacityOptions] = useState(false);

  const handleCapacityFilterChange = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      if (capacityOptions.length) {
        // org has programs
        setShowCapacityOptions(true);
        return;
      } else {
        // org does not have programs
        searchParams.set(SearchAndFilterTypes.WithinCapacity, "1");
      }
    } else {
      searchParams.delete(SearchAndFilterTypes.WithinCapacity);
      setShowCapacityOptions(false);
    }

    setSearchParams(searchParams);
  };

  const setProgramForCapacityFilter = (programId: string) => {
    searchParams.set(SearchAndFilterTypes.WithinCapacity, programId);
    setSearchParams(searchParams);
  };

  const getSelectedTabFilters = (): any => {
    const selectedTab = searchParams.get(SearchAndFilterTypes.Tab);

    switch (selectedTab) {
      case FormTabsTypes.Waitlists:
        return WAITLIST_STATUS;
      case FormTabsTypes.Offers:
        return OFFER_STATUS;
      default:
        return { ...FORM_SCHOOL_RANK_SUB_STATUS };
    }
  };

  const verifySubStatusFilter = (): boolean => {
    const selectedTab = searchParams.get(SearchAndFilterTypes.Tab);

    if (
      (selectedTab === FormTabsTypes.All ||
        selectedTab === FormTabsTypes.FormListImports) &&
      searchParams.has(SearchAndFilterTypes.Status)
    ) {
      if (
        searchParams
          .get(SearchAndFilterTypes.Status)
          ?.includes(GQL.form_status_enum.Admissions)
      ) {
        return true;
      }
    }

    return false;
  };

  const handleClearAll = useCallback(() => {
    setShowCapacityOptions(false);
    onClearFilters && onClearFilters();
  }, [onClearFilters]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } =
      e.target as HTMLDivElement;

    if (scrollTop / (scrollHeight - clientHeight) > 0.99) {
      setIsLoadingMoreFilters(true);
    } else {
      setIsLoadingMoreFilters(false);
    }
  };

  return (
    <VStack
      width="30%"
      paddingX={4}
      spacing={4}
      align="left"
      height="calc(100vh - 160px)"
      overflowY="auto"
      position="sticky"
      top={0}
      onScroll={handleScroll}
    >
      <HStack>
        <Text variant="filterHeader">Filter</Text>
        <Spacer />
        <IconButton
          aria-label="close-filter"
          variant="ghost"
          icon={<RiCloseLine />}
          onClick={onToggleFilter}
        />
      </HStack>
      {verifyFilterInclusion(searchParams, FilterTypes.School) && (
        <VStack align="inherit">
          <Text variant="filterLabel">
            <Glossary>Applying School</Glossary>
          </Text>
          <Box width="100%">
            <MultiSelectInput
              size="md"
              initialValues={schoolInitialValues ?? []}
              options={schoolsOptions ?? []}
              onChange={handleSchoolFilterChange}
              renderDropdownItem={renderDropdownItem}
              placeholder={glossary`Type a school`}
            />
          </Box>
        </VStack>
      )}

      {flags["current-applying-schools"].enabled &&
        verifyFilterInclusion(searchParams, FilterTypes.AttendingSchool) && (
          <VStack align="inherit">
            <Text variant="filterLabel">
              <Glossary>Current School</Glossary>
            </Text>
            <Box width="100%">
              <MultiSelectInput
                size="md"
                initialValues={attendingSchoolInitialValues ?? []}
                options={attendingSchoolsOptions ?? []}
                onChange={handleAttendingSchoolFilterChange}
                renderDropdownItem={renderDropdownItem}
                placeholder={glossary`Type a school`}
              />
            </Box>
          </VStack>
        )}

      {verifyFilterInclusion(searchParams, FilterTypes.Status) && (
        <VStack align="inherit">
          <Text variant="filterLabel">Status</Text>
          <Flex flexWrap="wrap" gap={2}>
            {Object.keys(FORM_STATUS).map(
              (status) =>
                FORM_STATUS[status as GQL.form_status_enum].isActive && (
                  <ToggleButton
                    key={status}
                    active={searchParams
                      .get(SearchAndFilterTypes.Status)
                      ?.includes(status)}
                    onClick={() =>
                      handleFormFilterChange(
                        SearchAndFilterTypes.Status,
                        status
                      )
                    }
                  >
                    <Glossary>
                      {FORM_STATUS[status as GQL.form_status_enum]?.label}
                    </Glossary>
                  </ToggleButton>
                )
            )}
          </Flex>
        </VStack>
      )}

      {(verifyFilterInclusion(searchParams, FilterTypes.SubStatus) ||
        verifySubStatusFilter()) && (
        <VStack align="inherit">
          <Text variant="filterLabel">Sub-status</Text>
          <Flex flexWrap="wrap" gap={2}>
            {Object.keys(getSelectedTabFilters()).map((subStatus) => (
              <ToggleButton
                key={subStatus}
                active={searchParams
                  .get(SearchAndFilterTypes.SubStatus)
                  ?.includes(subStatus)}
                onClick={() =>
                  handleFormFilterChange(
                    SearchAndFilterTypes.SubStatus,
                    subStatus
                  )
                }
              >
                <Glossary>{getSelectedTabFilters()[subStatus]?.label}</Glossary>
              </ToggleButton>
            ))}
          </Flex>
        </VStack>
      )}

      {hasRankViewPermission &&
        rankingEnabled &&
        verifyFilterInclusion(searchParams, FilterTypes.Rank) && (
          <VStack align="inherit">
            <Text variant="filterLabel">Rank</Text>
            <Box width="100%">
              <MultiSelectInput
                size="md"
                initialValues={rankFilters ?? []}
                options={rankOptions ?? []}
                onChange={handleRankFilterChange}
                renderDropdownItem={renderDropdownItem}
                placeholder="Type a rank"
              />
            </Box>
          </VStack>
        )}

      <FormFiltersTags
        enrollmentPeriodId={enrollmentPeriodId}
        formTemplateId={formTemplateId}
      />

      {verifyFilterInclusion(searchParams, FilterTypes.WithinCapacity) && (
        <VStack align="inherit">
          <Text variant="filterLabel">Capacity</Text>
          <Checkbox
            isChecked={
              searchParams.has(SearchAndFilterTypes.WithinCapacity) ||
              showCapacityOptions
            }
            onChange={handleCapacityFilterChange}
          >
            Show only within capacity
          </Checkbox>
          {(searchParams.has(SearchAndFilterTypes.WithinCapacity) ||
            showCapacityOptions) && (
            <RadioGroup
              display="flex"
              flexDirection="column"
              gap="2"
              value={
                searchParams.get(SearchAndFilterTypes.WithinCapacity) ??
                undefined
              }
              onChange={setProgramForCapacityFilter}
            >
              {capacityOptions.map((option) => (
                <Radio key={option.id} value={option.value} ml={4}>
                  {option.label}
                </Radio>
              ))}
            </RadioGroup>
          )}
        </VStack>
      )}

      {verifyFilterInclusion(searchParams, FilterTypes.PreferredLanguage) && (
        <VStack align="inherit">
          <Text variant="filterLabel">Preferred language</Text>
          <Box width="100%">
            <MultiSelectInput
              size="md"
              initialValues={preferredLanguageFilters ?? []}
              options={preferredLanguageOptions ?? []}
              onChange={handlePreferredLanguageFilterChange}
              renderDropdownItem={renderDropdownItem}
              placeholder="Select a language"
            />
          </Box>
        </VStack>
      )}

      {hasVerifications &&
        verifyFilterInclusion(searchParams, FilterTypes.VerificationStatus) && (
          <FormFiltersVerifications
            onChange={handleVerificationFiltersChange}
            options={verificationOptions}
            isLoading={formTemplateRemoteData.isLoading()}
            initialValues={verificationFilters}
          />
        )}

      {verifyFilterInclusion(searchParams, FilterTypes.Visibility) && (
        <VStack align="inherit">
          <Text variant="filterLabel">Visibility</Text>
          <Flex flexWrap="wrap" gap={2}>
            {VISIBILITY_FILTER_OPTIONS.map((visibility) => (
              <ToggleButton
                key={visibility.key}
                active={searchParams
                  .get(SearchAndFilterTypes.Visibility)
                  ?.includes(visibility.key)}
                onClick={() =>
                  handleFormFilterChange(
                    SearchAndFilterTypes.Visibility,
                    visibility.key
                  )
                }
              >
                {visibility.label}
              </ToggleButton>
            ))}
          </Flex>
        </VStack>
      )}
      <Divider />
      {verifyFilterInclusion(searchParams, FilterTypes.DynamicQuestions) && (
        <FormFiltersAdvancedTools
          formTemplateRemoteData={formTemplateRemoteData}
          isLoadingMoreFilters={isLoadingMoreFilters}
          onRenderingMoreFilters={() => setIsLoadingMoreFilters(false)}
        />
      )}

      {verifyIfFiltersAreApplied(searchParams) && (
        <VStack
          position="sticky"
          background="white"
          align="end"
          bottom="0"
          zIndex="300"
        >
          <Divider />
          <Button
            marginTop={2}
            onClick={handleClearAll}
            variant="outline"
            colorScheme="gray"
            size="sm"
          >
            Clear all
          </Button>
        </VStack>
      )}
    </VStack>
  );
};

const renderDropdownItem = (item: SchoolOptions | RankOptions) => (
  <Box>
    <span>{item.label}</span>
  </Box>
);

type SchoolOption = { label: string; value: string; id: string };
const formatSchoolOptions = ({
  schools,
  noSchoolLabel,
  idFilter,
}: {
  schools: School[];
  noSchoolLabel?: string;
  idFilter?: string[];
}) => {
  let schoolOptions: SchoolOption[] = _.uniqBy(schools, "id")
    .map((school) => {
      return {
        label: school.name,
        value: school.id,
        id: school.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  if (idFilter) {
    schoolOptions = schoolOptions.filter((school) =>
      idFilter.includes(school.id)
    );
  } else if (noSchoolLabel) {
    schoolOptions.unshift({
      label: noSchoolLabel,
      value: "noschool",
      id: "noschool",
    });
  }

  return schoolOptions;
};
