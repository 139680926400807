import { Box, Flex, FormLabel, Switch, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import {
  DEFAULT_NUMBER_TYPE_FORMAT,
  FormType,
  getNumberConstraintFormValue,
  NUMBER_TYPE_FORMAT_DECIMAL,
  NUMBER_TYPE_FORMAT_WHOLE,
  NumberConstraintsSwitchType,
} from "src/components/Form/QuestionForm/formik";
import { NumberInput } from "src/components/Inputs/NumberInput";
import { AvelaSelectField } from "src/components/Inputs/SelectField";
import { DEFAULT_NUMBER_CONSTRAINT } from "../constants";

enum QuestionConstraintsEnum {
  format = "format",
  precision = "precision",
  minValue = "minValue",
  maxValue = "maxValue",
}

type NumberConstraintsProps = {
  questionId: uuid;
};

type FormatOptions = {
  label: string;
  value: string;
  description: string;
};

const options: FormatOptions[] = [
  {
    label: "Whole",
    value: NUMBER_TYPE_FORMAT_WHOLE,
    description: "The number entered cannot have any decimal points.",
  },
  {
    label: "Decimal",
    value: NUMBER_TYPE_FORMAT_DECIMAL,
    description:
      "The number entered can have a number of decimal places as specified below",
  },
];

export const NumberFormat: React.FC<NumberConstraintsProps> = ({
  questionId,
}) => {
  const form = useFormikContext<FormType>();
  const numberConstraints = getNumberConstraintFormValue(
    questionId,
    form.values
  );

  const getFormatOptions = React.useCallback(
    (value: string) => options.find((option) => option.value === value),
    []
  );

  const [selectedFormatOption, setSelectedFormatOption] = React.useState(
    getFormatOptions(numberConstraints.format)
  );

  const handleInputChange = React.useCallback(
    (value: string | number, field: QuestionConstraintsEnum) => {
      if (field === "format") {
        const formatVal = value as typeof numberConstraints.format;
        setSelectedFormatOption(getFormatOptions(formatVal as string));
        form.setValues((values) => {
          return {
            ...values,
            numberConstraints: {
              ...values.numberConstraints,
              [questionId]: {
                ...numberConstraints,
                format: formatVal,
                precision:
                  formatVal === NUMBER_TYPE_FORMAT_WHOLE
                    ? 0
                    : Math.max(numberConstraints.precision, 1),
              },
            },
          };
        }, true);
      } else {
        form.setValues((values) => {
          return {
            ...values,
            numberConstraints: {
              ...values.numberConstraints,
              [questionId]: {
                ...numberConstraints,
                [field]: value,
              },
            },
          };
        }, true);
      }
    },
    [form, questionId, getFormatOptions, numberConstraints]
  );

  const precisionError = form.errors.numberConstraints?.[questionId]?.precision;

  return (
    <Flex direction="column" gap="4">
      <Flex direction="column" gap="2">
        <AvelaSelectField
          label="Number type"
          name="format"
          defaultValue={numberConstraints.format}
          options={options}
          onChange={(value) =>
            handleInputChange(value, QuestionConstraintsEnum.format)
          }
        />
        <Text fontSize="sm" textColor="gray.600">
          {selectedFormatOption?.description}
        </Text>
      </Flex>
      {selectedFormatOption?.label === "Decimal" && (
        <Flex direction="column" gap="2">
          <NumberInput
            name="precision"
            label="Decimal places"
            placeholder="Decimal places"
            minValue={1}
            maxValue={6}
            value={numberConstraints.precision}
            onChangeEvent={(valueAsString: string) =>
              handleInputChange(
                valueAsString,
                QuestionConstraintsEnum.precision
              )
            }
            error={precisionError}
          />
          <Text fontSize="sm" textColor="gray.600">
            The number of decimal places this number can have. Must be at least
            1 and can be up to 6.
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export const NumberConstraints: React.FC<NumberConstraintsProps> = ({
  questionId,
}) => {
  const form = useFormikContext<FormType>();
  const numberConstraints = getNumberConstraintFormValue(
    questionId,
    form.values
  );

  const handleInputChange = React.useCallback(
    (value: string, field: QuestionConstraintsEnum) => {
      // setTouched has to be called before setValues, otherwise it will behave weirdly where validation is executed with stale values.
      form.setTouched({
        ...form.touched,
        numberConstraints: {
          ...form.touched.numberConstraints,
          [questionId]: {
            ...form.touched.numberConstraints?.[questionId],
            [field]: true,
          },
        },
      });
      form.setValues((values) => {
        return {
          ...values,
          numberConstraints: {
            ...values.numberConstraints,
            [questionId]: {
              ...numberConstraints,
              [field]: value,
            },
          },
        };
      }, true);
    },
    [form, questionId, numberConstraints]
  );

  if (numberConstraints.switch === "disabled") {
    return null;
  }

  const minValueError = form.errors.numberConstraints?.[questionId]?.minValue;
  const maxValueError = form.errors.numberConstraints?.[questionId]?.maxValue;

  return (
    <Flex direction="column" gap="2">
      <Flex direction="row" gap="4">
        <NumberInput
          name="minValue"
          label="Minimum (optional)"
          value={numberConstraints.minValue ?? undefined}
          showStepper={false}
          onChangeEvent={(valueAsNumber) =>
            handleInputChange(valueAsNumber, QuestionConstraintsEnum.minValue)
          }
          error={minValueError}
        />
        <NumberInput
          name="maxValue"
          label="Maximum (optional)"
          value={numberConstraints.maxValue ?? undefined}
          showStepper={false}
          onChangeEvent={(valueAsNumber) =>
            handleInputChange(valueAsNumber, QuestionConstraintsEnum.maxValue)
          }
          error={maxValueError}
        />
      </Flex>
      <Text fontSize="sm" textColor="gray.600">
        The parent will only be able to enter a number between the specified
        minimum and maximum.
      </Text>
    </Flex>
  );
};

export const NumberConstraintsSwitch: React.FC<NumberConstraintsProps> = ({
  questionId,
}) => {
  const form = useFormikContext<FormType>();
  const numberConstraints = getNumberConstraintFormValue(
    questionId,
    form.values
  );
  const [value, setValue] = React.useState<NumberConstraintsSwitchType>(
    numberConstraints.switch
  );

  const onChangeHandler = React.useCallback(() => {
    const nextValue = value === "disabled" ? "checked" : "disabled";

    if (nextValue === "disabled") {
      form.setValues((values) => {
        return {
          ...values,
          numberConstraints: {
            ...values.numberConstraints,
            [questionId]: DEFAULT_NUMBER_CONSTRAINT,
          },
        };
      }, false);
    }

    form.setValues((values) => {
      const reply: FormType = {
        ...values,
        numberConstraints: {
          ...values.numberConstraints,
          [questionId]: {
            switch: nextValue,
            format: numberConstraints.format ?? DEFAULT_NUMBER_TYPE_FORMAT,
            precision: numberConstraints.precision ?? 0,
            minValue: numberConstraints.minValue,
            maxValue: numberConstraints.maxValue,
          },
        },
      };

      return reply;
    }, false);

    setValue(nextValue);
  }, [numberConstraints, form, questionId, value]);

  return (
    <FormLabel
      htmlFor={`numberConstraintsSwitch${questionId}`}
      display="flex"
      flexDirection="row"
      gap="3"
    >
      <Switch
        id={`numberConstraintsSwitch${questionId}`}
        isChecked={value !== "disabled"}
        onChange={onChangeHandler}
      />
      <Box>Constraints</Box>
    </FormLabel>
  );
};
