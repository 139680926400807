import { Flex, FormLabel } from "@chakra-ui/react";
import { PermissionLevelSwitch } from "src/components/Form/QuestionForm/Edit/PermissionLevel";
import { QuestionLinkSwitch } from "src/components/Form/QuestionForm/Edit/QuestionLink";
import { RequirementSwitch } from "src/components/Form/QuestionForm/Edit/Requirement";
import { VerificationSwitch } from "src/components/Form/QuestionForm/Edit/Verification";
import * as AF from "src/types/formTemplate";
import { QuestionConstraintsSwitch } from "../Edit/QuestionContraints";

type Props = { questionId: uuid; questionType: AF.Question<AF.WithId>["type"] };
export const ConfigureQuestion: React.FC<Props> = ({
  questionId,
  questionType,
}) => {
  return (
    <Flex direction="column" gap="3">
      <FormLabel as="div" margin="0">
        Configure question
      </FormLabel>
      <VerificationSwitch questionType={questionType} questionId={questionId} />
      <QuestionLinkSwitch questionId={questionId} />
      <PermissionLevelSwitch
        questionId={questionId}
        questionType={questionType}
      />
      <RequirementSwitch questionType={questionType} questionId={questionId} />
      <QuestionConstraintsSwitch
        questionType={questionType}
        questionId={questionId}
      />
    </Flex>
  );
};
