import { formatInTimeZone } from "date-fns-tz";
import { useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { useOrganizationContacts } from "src/hooks/useOrganization";
import { FormTemplateOutletContext } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/Edit";

// Dummy data for automatic message preview
const PARTIALS = {
  parent: { name: "Elsa Ebert" },
  student: { name: "Johnny Ebert" },
  enrollmentPeriod: { name: "23-24 School Year" },
  form: {
    name: "23-24 Application",
    closeDate: new Date("2023-01-20T08:59:00-0000"),
    offerDate: new Date("2023-02-20T08:59:00-0000")
  },
  grade: "3rd grade",
  schools: [
    { name: "Tillman Elementery", rank: 1, "first": true },
    { name: "Glendale Elementary", rank: 2 },
    { name: "Lindbergh Elementary", rank: 3, "last": true }
  ],
  offer: {
    schoolName: "Tillman Elementary"
  }
};

export const usePartials = () => {
  const { organization } = useOutletContext<FormTemplateOutletContext>();
  const { path, timezone_name } = organization;
  const contacts = useOrganizationContacts();

  return useMemo(() => {
    const format = "MM-dd-yyyy HH:mm:ss zzz"; // From user-communication lambda
    return {
      ...PARTIALS,
      form: {
        name: PARTIALS.form.name,
        closeDate: formatInTimeZone(
          PARTIALS.form.closeDate,
          timezone_name,
          format
        ),
        offerDate: formatInTimeZone(
          PARTIALS.form.offerDate,
          timezone_name,
          format
        )
      },
      organization: organization,
      applyUrl: `https://apply.avela.org/${path}`,
      supportEmail:
        contacts.find((contact) => contact.type === "email")?.value ??
        "support@avela.org"
    };
  }, [organization, path, timezone_name, contacts]);
};
