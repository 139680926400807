import { Box, Flex, FormLabel, Switch, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import {
  DateConstraintsSwitchType,
  DateTypeFormats,
  DEFAULT_DATE_TYPE_FORMAT,
  FormType,
  getDateConstraintFormValue,
} from "src/components/Form/QuestionForm/formik";
import { FormInput } from "src/components/Inputs/FormInput";
import { DEFAULT_DATE_CONSTRAINT } from "../constants";
import { AvelaSelectField } from "src/components/Inputs/SelectField";

enum QuestionConstraintsEnum {
  startDate = "startDate",
  endDate = "endDate",
  format = "format",
}

type DateConstraintsProps = {
  questionId: uuid;
};

export const DateFormat: React.FC<DateConstraintsProps> = ({ questionId }) => {
  const form = useFormikContext<FormType>();
  const dateConstraints = getDateConstraintFormValue(questionId, form.values);

  const handleInputChange = React.useCallback(
    (value: string, field: QuestionConstraintsEnum) => {
      // setTouched has to be called before setValues, otherwise it will behave weirdly where validation is executed with stale values.
      form.setTouched({
        ...form.touched,
        dateConstraints: {
          ...form.touched.dateConstraints,
          [questionId]: {
            ...form.touched.dateConstraints?.[questionId],
            [field]: true,
          },
        },
      });
      form.setValues((values) => {
        return {
          ...values,
          dateConstraints: {
            ...values.dateConstraints,
            [questionId]: {
              ...dateConstraints,
              [field]: value,
            },
          },
        };
      }, true);
    },
    [form, questionId, dateConstraints]
  );

  return (
    <Flex direction="column" gap="2">
      <AvelaSelectField
        label="Date format"
        name="format"
        defaultValue={dateConstraints.format}
        options={DateTypeFormats.map((value) => ({
          label: value,
          value: value,
        }))}
        onChange={(value) =>
          handleInputChange(value, QuestionConstraintsEnum.format)
        }
      />
      <Text fontSize="sm" textColor="gray.600">
        This date will be displayed using the selected format. Example:
        03-31-2009
      </Text>
    </Flex>
  );
};

export const DateConstraints: React.FC<DateConstraintsProps> = ({
  questionId,
}) => {
  const form = useFormikContext<FormType>();
  const dateConstraints = getDateConstraintFormValue(questionId, form.values);

  const handleInputChange = React.useCallback(
    (value: string, field: QuestionConstraintsEnum) => {
      // setTouched has to be called before setValues, otherwise it will behave weirdly where validation is executed with stale values.
      form.setTouched({
        ...form.touched,
        dateConstraints: {
          ...form.touched.dateConstraints,
          [questionId]: {
            ...form.touched.dateConstraints?.[questionId],
            [field]: true,
          },
        },
      });
      form.setValues((values) => {
        return {
          ...values,
          dateConstraints: {
            ...values.dateConstraints,
            [questionId]: {
              ...dateConstraints,
              [field]: value,
            },
          },
        };
      }, true);
    },
    [form, questionId, dateConstraints]
  );

  if (dateConstraints.switch === "disabled") {
    return null;
  }

  const startDateError = form.errors.dateConstraints?.[questionId]?.startDate;
  const endDateError = form.errors.dateConstraints?.[questionId]?.endDate;

  return (
    <Flex direction="column" gap="2">
      <Flex direction="row" gap="4">
        <FormInput
          label="Start date (optional)"
          value={dateConstraints.startDate ?? ""}
          onChange={(event) =>
            handleInputChange(
              event.target.value,
              QuestionConstraintsEnum.startDate
            )
          }
          error={startDateError}
          type="date"
        />
        <FormInput
          label="End date (optional)"
          value={dateConstraints.endDate ?? ""}
          onChange={(event) =>
            handleInputChange(
              event.target.value,
              QuestionConstraintsEnum.endDate
            )
          }
          error={endDateError}
          type="date"
        />
      </Flex>
      <Text fontSize="sm" textColor="gray.600">
        The parent will only be able to choose a date between the two dates
        above.
      </Text>
    </Flex>
  );
};

export const DateConstraintsSwitch: React.FC<DateConstraintsProps> = ({
  questionId,
}) => {
  const form = useFormikContext<FormType>();
  const dateConstraints = getDateConstraintFormValue(questionId, form.values);
  const [value, setValue] = React.useState<DateConstraintsSwitchType>(
    dateConstraints.switch
  );

  const onChangeHandler = React.useCallback(() => {
    const nextValue = value === "disabled" ? "checked" : "disabled";

    if (nextValue === "disabled") {
      form.setValues((values) => {
        return {
          ...values,
          dateConstraints: {
            ...values.dateConstraints,
            [questionId]: DEFAULT_DATE_CONSTRAINT,
          },
        };
      }, false);
    }

    form.setValues((values) => {
      const reply: FormType = {
        ...values,
        dateConstraints: {
          ...values.dateConstraints,
          [questionId]: {
            switch: nextValue,
            format: dateConstraints.format ?? DEFAULT_DATE_TYPE_FORMAT,
            startDate: dateConstraints.startDate ?? "",
            endDate: dateConstraints.endDate ?? "",
          },
        },
      };

      return reply;
    }, false);

    setValue(nextValue);
  }, [dateConstraints, form, questionId, value]);

  return (
    <FormLabel
      htmlFor={`dateConstraintsSwitch${questionId}`}
      display="flex"
      flexDirection="row"
      gap="3"
    >
      <Switch
        id={`dateConstraintsSwitch${questionId}`}
        isChecked={value !== "disabled"}
        onChange={onChangeHandler}
      />
      <Box>Constraints</Box>
    </FormLabel>
  );
};
