import {
  DateTypeConstraints,
  NumberTypeConstraints,
} from "src/components/Form/QuestionForm/formik";
import { FORM_STATUS } from "src/constants";
import * as AFF from "./formTemplateFilters";

export type BaseId = WithId | WithoutId;
export type WithId = { id: string };
export type WithoutId = {};

export type FormTemplate<ID extends BaseId> = ID & {
  enrollmentPeriodId: string;
  name: string;
  key: string;
  description?: string;
  lotteryOffersEnabled: boolean;
  sections: Sections<ID>;
};

export type Sections<ID extends BaseId> =
  | [
      PreRankingSection<ID>,
      SchoolRankingSection<ID>,
      ...GeneralSection<ID>[],
      DisclaimerSection<ID> | undefined
    ]
  | [
      PreRankingSection<ID> | undefined,
      SchoolRankingSection<ID> | undefined,
      ...GeneralSection<ID>[],
      DisclaimerSection<ID> | undefined
    ]
  | [...GeneralSection<ID>[], DisclaimerSection<ID> | undefined]
  | GeneralSection<ID>[];

/**
 * This collapses the AF.Sections union type down to a single array type that
 * supports generic functions, like type-narrowing find() and filter().  (Note
 * that Sections<ID> is assignable to GenericSections<ID>.)
 */
export type GenericSections<ID extends BaseId> = Sections<ID>[number][];

/**
 * Sections
 */
export type Section<ID extends BaseId> =
  | PreRankingSection<ID>
  | SchoolRankingSection<ID>
  | GeneralSection<ID>
  | DisclaimerSection<ID>;

export const PreRankingSectionType = "PreRankingSection";
export const SchoolRankingSectionType = "SchoolRankingSection";
export const GeneralSectionType = "GeneralSection";
export const DisclaimerSectionType = "DisclaimerSection";
export type SectionType =
  | typeof PreRankingSectionType
  | typeof SchoolRankingSectionType
  | typeof GeneralSectionType
  | typeof DisclaimerSectionType;

export type FamilyEditableStatusType = keyof typeof FORM_STATUS;

export type FormSection<ID extends BaseId, Type extends SectionType> = ID & {
  type: Type;
  title: string;
  description?: string;
  key: string | null;
  permissionLevel: string | null;
  filters?: AFF.FormTemplateFilters;
  familyEditableStatuses?: FamilyEditableStatusType[] | null;
};

export type PreRankingSection<ID extends BaseId> = FormSection<
  ID,
  "PreRankingSection"
> & {
  questions: Question<ID>[];
};

export type SchoolRankingSection<ID extends BaseId> = FormSection<
  ID,
  "SchoolRankingSection"
> & {
  schoolRankingFormSectionId: string;
  exploreUrl?: string | null;
  minSchools?: number;
  maxSchools?: number;
  rankingEnabled?: boolean;
};

export type GeneralSection<ID extends BaseId> = FormSection<
  ID,
  "GeneralSection"
> & {
  questions: Question<ID>[];
};

export type DisclaimerSection<ID extends BaseId> = FormSection<
  ID,
  "DisclaimerSection"
> & {
  disclaimerFormSectionId: string;
  disclaimer: string;
};

/**
 * Questions
 */
export const SingleSelectType = "SingleSelect";
export const MultiSelectType = "MultiSelect";
export const FreeTextType = "FreeText";
export const FileUploadType = "FileUpload";
export const GradesType = "Grades";
export const EmailType = "Email";
export const PhoneNumberType = "PhoneNumber";
export const AddressType = "Address";
export const CustomQuestionType = "CustomQuestion";
export const DateType = "Date";
export const NumberType = "Number";

// The order of the question type here matter and being used by src/components/Form/QuestionForm/Edit/QuestionType.tsx
export const QuestionTypeList = [
  FreeTextType,
  SingleSelectType,
  MultiSelectType,
  FileUploadType,
  GradesType,
  EmailType,
  PhoneNumberType,
  AddressType,
  CustomQuestionType,
  DateType,
  NumberType,
] as const;
export type QuestionType = (typeof QuestionTypeList)[number];

export const GeneralCategoryType = "General";
export const EligibilityCategoryType = "Eligibility";
export type Category =
  | typeof GeneralCategoryType
  | typeof EligibilityCategoryType;

export type Question<ID extends BaseId> =
  | SingleSelect<ID>
  | MultiSelect<ID>
  | FreeText<ID>
  | FileUpload<ID>
  | Grades<ID>
  | Email<ID>
  | PhoneNumber<ID>
  | Address<ID>
  | CustomQuestion<ID>
  | Date<ID>
  | Number<ID>;

/**
 * Base Question
 */
export type BaseQuestion<ID extends BaseId> = ID & {
  key?: string;
  type: QuestionType;
  question: string;
  requirement?: typeof Requirement;
  specificToSchools?: uuid[];
  link_url?: string;
  link_text?: string;
  permissionLevel?: string;
  filters?: AFF.FormTemplateFilters;
  // CQT-TODO, this is inelegant, refactor it
  customQuestionTypeFieldId?: uuid;
  // need this constraint here otherwise: error TS2590: Expression produces a union type that is too complex to represent.
  constraints?: DateTypeConstraints | NumberTypeConstraints | null;
};

export type FormQuestion<ID extends BaseId> = BaseQuestion<ID> & {
  /**
   * @deprecated This trip us in more than one occasion where we're adding eligibility, but forgot to set the category
   * I propose we remove this and just rely on eligibility fields instead
   */
  category: Category;
} & Verification<ID>;

/**
 * Question by type
 */

export type SingleSelect<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof SingleSelectType;
  options: Option<ID>[];
  filters?: AFF.FormTemplateFilters;
};

export type MultiSelect<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof MultiSelectType;
  options: Option<ID>[];
  filters?: AFF.FormTemplateFilters;
};

export type FreeText<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof FreeTextType;
};

export type FileUpload<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof FileUploadType;
};

export type Grades<ID extends BaseId> = BaseQuestion<ID> & {
  type: typeof GradesType;
  category?: Category;
} & (ID extends WithId
    ? {
        options: Option<ID>[];
        filters?: AFF.FormTemplateFilters;
      }
    : {
        gradesAdditionalQuestions?: GradesAdditionalQuestion<ID>[];
        filters?: AFF.FormTemplateFilters;
      });

export type GradesAdditionalQuestion<ID extends BaseId> = {
  gradeConfigId: string;
} & Branching<Question<ID>>;

export const Requirement = "Required";

export type FormVerification<ID extends BaseId> = ID & {
  label: string;
};

export type Verification<ID extends BaseId> = {
  formVerification?: FormVerification<ID>;
};

export type VerificationOptions = {
  skipVerification?: boolean;
};

export type Option<ID extends BaseId> = ID & {
  label: string;
  additionalQuestions?: Question<ID>[];
  translate_options?: boolean;
  value?: string;
} & Eligibility &
  VerificationOptions;

export type Email<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof EmailType;
};

export type PhoneNumber<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof PhoneNumberType;
};

export type Address<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof AddressType;
};

export type Date<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof DateType;
  constraints?: DateTypeConstraints | null;
};

export type Number<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof NumberType;
  constraints?: NumberTypeConstraints | null;
};

export type ClonedQuestion<ID extends BaseId> =
  | SingleSelect<ID>
  | FreeText<ID>
  | Email<ID>
  | PhoneNumber<ID>
  | Date<ID>
  | Number<ID>;

export type CustomQuestion<ID extends BaseId> = FormQuestion<ID> & {
  type: typeof CustomQuestionType;
  customQuestionTypeId: uuid;
  nestedQuestions: ClonedQuestion<ID>[];
};

/**
 * Question filters
 */
export type Eligibility = Eligible | NotEligible | EligibilityNotApplicable;
export type Eligible = {
  eligibilityFilter: "Eligible";
  eligibleSchoolIds: string[];
};
export type NotEligible = {
  eligibilityFilter: "NotEligible";
  notEligibleSchoolIds: string[];
};
export type EligibilityNotApplicable = { eligibilityFilter?: "NotApplicable" };

export type BaseBranching = Branching<unknown> | NoBranching;
export type Branching<ADDITIONAL_QUESTION> = {
  additionalQuestions?: ADDITIONAL_QUESTION[];
};
export type NoBranching = {};
