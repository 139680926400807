import * as AF from "src/types/formTemplate";
import {
  DateConstraints,
  DateConstraintsSwitch,
  DateFormat,
} from "./DateConstraints";
import {
  NumberConstraints,
  NumberConstraintsSwitch,
  NumberFormat,
} from "./NumberConstraints";

export type QuestionConstraintsProps = {
  questionId: uuid;
  questionType: AF.QuestionType;
};

export const QuestionFormat: React.FC<QuestionConstraintsProps> = ({
  questionId,
  questionType,
}) => {
  switch (questionType) {
    case AF.DateType:
      return <DateFormat questionId={questionId} />;
    case AF.NumberType:
      return <NumberFormat questionId={questionId} />;
    default:
      return null;
  }
};

export const QuestionConstraints: React.FC<QuestionConstraintsProps> = ({
  questionId,
  questionType,
}) => {
  switch (questionType) {
    case AF.DateType:
      return <DateConstraints questionId={questionId} />;
    case AF.NumberType:
      return <NumberConstraints questionId={questionId} />;
    default:
      return null;
  }
};

export const QuestionConstraintsSwitch: React.FC<QuestionConstraintsProps> = ({
  questionId,
  questionType,
}) => {
  switch (questionType) {
    case AF.DateType:
      return <DateConstraintsSwitch questionId={questionId} />;
    case AF.NumberType:
      return <NumberConstraintsSwitch questionId={questionId} />;
    default:
      return null;
  }
};
