import { gql } from "@apollo/client";
import { ACTION_ITEM_FRAGMENT } from "src/operations/fragments/actionItem";

export const UPSERT_FORM_ANSWERS_AND_DELETE_SCHOOLS_RANK = gql`
  mutation UpsertFormAnswerAndDeleteSchoolsRank(
    $form_id: uuid!
    $single_or_multi_select_question_ids: [uuid!]! # This includes SingleSelect type questions that are nested in custom questions.
    $answers: [form_answer_insert_input!]!
    $grades_answers: [grades_answer_insert_input!]!
    $address_answers: [form_address_insert_input!]!
    $address_answer_deletes: [form_address_bool_exp!]!
    $custom_question_ids: [uuid!]!
    $custom_question_answers: [custom_question_answer_insert_input!]!
    $answer_bank_ids: [uuid!]!
    $answer_bank_answers: [person_answer_insert_input!]!
    $new_answer_bank_entries: [person_answer_bank_insert_input!]!
    $deleted_answers_custom_question_ids: [uuid!]!
    $delete_offers_where: offer_bool_exp!
    $delete_waitlists_where: waitlist_bool_exp!
    $deleted_school_ranks: form_school_rank_bool_exp!
    $upserted_school_ranks: [form_school_rank_insert_input!]!
  ) {
    # There isn't a great way to upsert answer options so instead we need to explicitly delete and insert.
    # To delete:
    # - custom question answers for custom question fields of SingleSelect type
    # - form answers for all SingleSelect and MultiSelect questions modified
    # - form answer options are automatically taken care of by cascading delete
    delete_custom_question_answer(
      where: {
        form_id: { _eq: $form_id }
        question_id: { _in: $custom_question_ids }
        form_answer: {
          form_id: { _eq: $form_id }
          question_id: { _in: $single_or_multi_select_question_ids }
        }
      }
    ) {
      affected_rows
    }

    delete_form_answer(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          question_id: { _in: $single_or_multi_select_question_ids }
        }
      }
    ) {
      affected_rows
    }

    insert_form_answer(
      objects: $answers
      on_conflict: {
        update_columns: [free_text_answer, number_answer, date_answer]
        constraint: form_answer_form_id_form_question_id_key
      }
    ) {
      returning {
        id
      }
    }

    insert_grades_answer(
      objects: $grades_answers
      on_conflict: {
        update_columns: grade_config_id
        constraint: grades_answer_form_id_question_id_key
      }
    ) {
      returning {
        id
      }
    }

    insert_form_address(
      objects: $address_answers
      on_conflict: {
        constraint: form_address_form_id_question_id_key
        update_columns: [
          street_address
          street_address_line_2
          city
          state
          zip_code
        ]
      }
    ) {
      returning {
        id
      }
    }

    delete_form_address(where: { _or: $address_answer_deletes }) {
      affected_rows
    }

    insert_custom_question_answer(
      objects: $custom_question_answers
      on_conflict: {
        constraint: custom_question_answer_form_id_question_id_form_answer_id_key
        update_columns: []
      }
    ) {
      returning {
        id
      }
    }
    # Update answer bank entries
    delete_person_answer(
      where: { person_answer_bank_id: { _in: $answer_bank_ids } }
    ) {
      affected_rows
    }
    update_person_answer_bank(
      _set: { last_used_at: "now()" }
      where: { id: { _in: $answer_bank_ids } }
    ) {
      returning {
        id
      }
    }
    insert_person_answer(objects: $answer_bank_answers) {
      affected_rows
    }

    # Add new answer bank entries
    insert_person_answer_bank(objects: $new_answer_bank_entries) {
      returning {
        id
      }
    }

    # Delete empty custom question answers
    delete_custom_question_answer_bank_relationship(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          custom_question_id: { _in: $deleted_answers_custom_question_ids }
        }
      }
    ) {
      affected_rows
    }

    update_waitlist(
      _set: { deleted_at: "now()" }
      where: $delete_waitlists_where
    ) {
      affected_rows
    }
    update_offer(_set: { deleted_at: "now()" }, where: $delete_offers_where) {
      affected_rows
    }

    delete_form_school_rank(where: $deleted_school_ranks) {
      affected_rows
    }
    insert_form_school_rank(
      objects: $upserted_school_ranks
      on_conflict: {
        constraint: form_school_rank_form_id_schools_ranking_sect_key
        update_columns: rank
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPSERT_FORM_ANSWERS = gql`
  mutation UpsertFormAnswer(
    $form_id: uuid!
    $single_or_multi_select_question_ids: [uuid!]! # This includes SingleSelect type questions that are nested in custom questions.
    $answers: [form_answer_insert_input!]!
    $grades_answers: [grades_answer_insert_input!]!
    $address_answers: [form_address_insert_input!]!
    $address_answer_deletes: [form_address_bool_exp!]!
    $custom_question_ids: [uuid!]!
    $custom_question_answers: [custom_question_answer_insert_input!]!
    $answer_bank_ids: [uuid!]!
    $answer_bank_answers: [person_answer_insert_input!]!
    $deleted_answers_custom_question_ids: [uuid!]!
    $new_answer_bank_entries: [person_answer_bank_insert_input!]!
  ) {
    # There isn't a great way to upsert answer options so instead we need to explicitly delete and insert.
    # To delete:
    # - custom question answers for custom question fields of SingleSelect type
    # - form answers for all SingleSelect and MultiSelect questions modified
    # - form answer options are automatically taken care of by cascading delete
    delete_custom_question_answer(
      where: {
        form_id: { _eq: $form_id }
        question_id: { _in: $custom_question_ids }
        form_answer: {
          form_id: { _eq: $form_id }
          question_id: { _in: $single_or_multi_select_question_ids }
        }
      }
    ) {
      affected_rows
    }

    delete_form_answer(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          question_id: { _in: $single_or_multi_select_question_ids }
        }
      }
    ) {
      affected_rows
    }

    insert_form_answer(
      objects: $answers
      on_conflict: {
        update_columns: [free_text_answer, number_answer, date_answer]
        constraint: form_answer_form_id_form_question_id_key
      }
    ) {
      returning {
        id
      }
    }

    insert_grades_answer(
      objects: $grades_answers
      on_conflict: {
        update_columns: grade_config_id
        constraint: grades_answer_form_id_question_id_key
      }
    ) {
      returning {
        id
      }
    }

    insert_form_address(
      objects: $address_answers
      on_conflict: {
        constraint: form_address_form_id_question_id_key
        update_columns: [
          street_address
          street_address_line_2
          city
          state
          zip_code
        ]
      }
    ) {
      returning {
        id
      }
    }

    delete_form_address(where: { _or: $address_answer_deletes }) {
      affected_rows
    }

    insert_custom_question_answer(
      objects: $custom_question_answers
      on_conflict: {
        constraint: custom_question_answer_form_id_question_id_form_answer_id_key
        update_columns: []
      }
    ) {
      returning {
        id
      }
    }

    # Update answer bank entries
    delete_person_answer(
      where: { person_answer_bank_id: { _in: $answer_bank_ids } }
    ) {
      affected_rows
    }
    update_person_answer_bank(
      _set: { last_used_at: "now()" }
      where: { id: { _in: $answer_bank_ids } }
    ) {
      returning {
        id
      }
    }
    insert_person_answer(objects: $answer_bank_answers) {
      affected_rows
    }

    # Add new answer bank entries
    insert_person_answer_bank(objects: $new_answer_bank_entries) {
      returning {
        id
      }
    }

    # Delete empty custom question answers
    delete_custom_question_answer_bank_relationship(
      where: {
        _and: {
          form_id: { _eq: $form_id }
          custom_question_id: { _in: $deleted_answers_custom_question_ids }
        }
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_FORM_STATUS = gql`
  mutation UpdateFormStatus(
    $form_id: uuid!
    $skipOfferWaitlist: Boolean!
    $set: form_set_input!
  ) {
    update_form_by_pk(pk_columns: { id: $form_id }, _set: $set) {
      id
      status
    }
    update_waitlist(
      where: { deleted_at: { _is_null: true }, form_id: { _eq: $form_id } }
      _set: { status: Removed }
    ) @skip(if: $skipOfferWaitlist) {
      affected_rows
    }
    update_offer(
      where: { form_id: { _eq: $form_id } }
      _set: { status: Revoked }
    ) @skip(if: $skipOfferWaitlist) {
      affected_rows
    }
  }
`;

export const UPDATE_BULK_FORM_STATUS = gql`
  mutation UpdateBulkFormStatus(
    $form_ids: [uuid!]
    $skipOfferWaitlist: Boolean!
    $set: form_set_input
  ) {
    update_form(where: { id: { _in: $form_ids } }, _set: $set) {
      affected_rows
    }
    update_waitlist(
      where: { deleted_at: { _is_null: true }, form_id: { _in: $form_ids } }
      _set: { status: Removed }
    ) @skip(if: $skipOfferWaitlist) {
      affected_rows
    }
    update_offer(
      where: { form_id: { _in: $form_ids } }
      _set: { status: Revoked }
    ) @skip(if: $skipOfferWaitlist) {
      affected_rows
    }
  }
`;

export const UPSERT_VERIFICATION_STATUS = gql`
  mutation UpsertVerificationStatus(
    $form_id: uuid!
    $form_verification_id: uuid!
    $verification_status: verification_status_enum!
  ) {
    delete_form_verification_result(
      where: {
        _and: [
          { form_id: { _eq: $form_id } }
          { form_verification_id: { _eq: $form_verification_id } }
        ]
      }
    ) {
      affected_rows
    }

    insert_form_verification_result_one(
      object: {
        form_id: $form_id
        form_verification_id: $form_verification_id
        verification_status: $verification_status
      }
    ) {
      id
      form_id
      form_verification_id
      verification_status
    }
  }
`;

export const UPSERT_VERIFICATION_STATUSES = gql`
  mutation UpsertVerificationStatuses(
    $form_ids: [uuid!]!
    $form_verification_id: uuid!
    $verification_results: [form_verification_result_insert_input!]!
  ) {
    delete_form_verification_result(
      where: {
        form_id: { _in: $form_ids }
        form_verification_id: { _eq: $form_verification_id }
      }
    ) {
      affected_rows
    }

    insert_form_verification_result(objects: $verification_results) {
      affected_rows
    }
  }
`;

export const INSERT_ACTION_ITEM = gql`
  ${ACTION_ITEM_FRAGMENT}

  mutation InsertActionItem($form_id: uuid!, $summary: String!) {
    insert_action_item(
      objects: { form_id: $form_id, status: Pending, summary: $summary }
    ) {
      returning {
        ...ActionItemFragment
      }
    }
  }
`;

export const UPDATE_ACTION_ITEM = gql`
  ${ACTION_ITEM_FRAGMENT}

  mutation UpdateActionItem(
    $id: uuid!
    $status: action_item_status_enum!
    $summary: String!
  ) {
    update_action_item_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status, summary: $summary }
    ) {
      ...ActionItemFragment
    }
  }
`;

export const BULK_CREATE_ACTION_ITEMS = gql`
  mutation BulkCreateActionItems(
    $form_ids: [uuid!] = ""
    $data: [action_item_insert_input!] = {}
  ) {
    update_action_item_many(
      updates: {
        where: { form_id: { _in: $form_ids } }
        _set: { status: Canceled }
      }
    ) {
      affected_rows
    }

    insert_action_item(objects: $data) {
      returning {
        form_id
        created_at
        id
        status
        summary
        updated_at
      }
    }
  }
`;

export const BULK_CANCEL_ACTION_ITEMS = gql`
  mutation BulkCancelActionItems($form_ids: [uuid!] = "") {
    update_action_item_many(
      updates: {
        where: { form_id: { _in: $form_ids } }
        _set: { status: Canceled }
      }
    ) {
      affected_rows
    }
  }
`;

export const MAKE_BULK_FORM_SCHOOL_OFFER_MAX_INPUT_SIZE = 500;
export const MAKE_BULK_FORM_SCHOOL_OFFER = gql`
  mutation MakeBulkFormSchoolOffer(
    $form_ids: [uuid!]
    $delete_offers_where: offer_bool_exp!
    $insert_offers: [offer_insert_input!]!
    $delete_waitlists_where: waitlist_bool_exp!
    $form_school_rank_ids: [uuid!]!
  ) {
    update_form(
      where: { id: { _in: $form_ids } }
      _set: { status: Admissions }
    ) {
      affected_rows
    }
    update_offer(_set: { deleted_at: "now()" }, where: $delete_offers_where) {
      affected_rows
    }
    insert_offer(objects: $insert_offers) {
      affected_rows
    }
    update_waitlist(
      _set: { deleted_at: "now()" }
      where: $delete_waitlists_where
    ) {
      affected_rows
    }
    update_form_school_rank(
      where: {
        _and: [
          { _or: { id: { _in: $form_school_rank_ids } } }
          { status: { _is_null: false } }
        ]
      }
      _set: { status: null }
    ) {
      affected_rows
    }
  }
`;

export const REVOKE_BULK_FORM_SCHOOL_OFFER_MAX_INPUT_SIZE = 500;
export const REVOKE_BULK_FORM_SCHOOL_OFFER = gql`
  mutation RevokeBulkFormSchoolOffer($where: offer_bool_exp!) {
    update_offer(_set: { status: Revoked }, where: $where) {
      affected_rows
    }
  }
`;

export const IMPORT_LOTTERY_ORDER = gql`
  mutation ImportLotteryOrder($updates: [form_school_rank_updates!]!) {
    update_form_school_rank_many(updates: $updates) {
      returning {
        id
        form_id
        school_id
        lottery_order
      }
    }
  }
`;

export const ACCEPT_BULK_FORM_SCHOOL_OFFER_MAX_INPUT_SIZE = 500;
export const ACCEPT_BULK_FORM_SCHOOL_OFFER = gql`
  mutation AcceptBulkFormSchoolOffer($acceptedCondition: offer_bool_exp!) {
    update_offer(_set: { status: Accepted }, where: $acceptedCondition) {
      affected_rows
    }
  }
`;

export const DECLINE_BULK_FORM_SCHOOL_OFFER_MAX_INPUT_SIZE = 500;
export const DECLINE_BULK_FORM_SCHOOL_OFFER = gql`
  mutation DeclineBulkFormSchoolOffer($declinedCondition: offer_bool_exp!) {
    update_offer(_set: { status: Declined }, where: $declinedCondition) {
      affected_rows
    }
  }
`;

export const EXCLUDE_BULK_FORM = gql`
  mutation ExcludeBulkForm(
    $updateWhere: form_school_rank_bool_exp!
    $delete_offers_where: offer_bool_exp!
    $delete_waitlists_where: waitlist_bool_exp!
  ) {
    update_form_school_rank(
      _set: { status: NotConsidered }
      where: $updateWhere
    ) {
      affected_rows
      returning {
        id
      }
    }
    update_offer(_set: { deleted_at: "now()" }, where: $delete_offers_where) {
      affected_rows
    }
    update_waitlist(
      _set: { deleted_at: "now()" }
      where: $delete_waitlists_where
    ) {
      affected_rows
    }
  }
`;

export const CLEAR_SUB_STATUS_BULK_FORM = gql`
  mutation ClearSubstatusBulkForm(
    $updateWhere: form_school_rank_bool_exp!
    $delete_offers_where: offer_bool_exp!
    $delete_waitlists_where: waitlist_bool_exp!
  ) {
    update_form_school_rank(_set: { status: null }, where: $updateWhere) {
      affected_rows
      returning {
        id
      }
    }
    update_waitlist(
      _set: { deleted_at: "now()" }
      where: $delete_waitlists_where
    ) {
      affected_rows
    }
    update_offer(_set: { deleted_at: "now()" }, where: $delete_offers_where) {
      affected_rows
    }
  }
`;

export const UPDATE_FORM_VISIBILITY_BY_ID = gql`
  mutation UpdateFormVisibilityById($form_id: uuid!, $visibility: Boolean!) {
    update_form_by_pk(
      pk_columns: { id: $form_id }
      _set: { is_hidden_from_parent: $visibility }
    ) {
      id
    }
  }
`;

export const UPDATE_BULK_FORM_VISIBILITY = gql`
  mutation UpdateBulkFormVisibility($form_ids: [uuid!], $visibility: Boolean!) {
    update_form(
      where: { id: { _in: $form_ids } }
      _set: { is_hidden_from_parent: $visibility }
    ) {
      affected_rows
    }
  }
`;
