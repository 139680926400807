import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { BaseProps, NumberInputControl } from "formik-chakra-ui";
import * as React from "react";

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  onChangeEvent?(valueAsString: string, valueAsNumber: number): void;
  isDisabled?: boolean;
  minValue?: number;
  maxValue?: number;
  value?: number;
  pattern?: string;
  showStepper?: boolean;
  error?: string;
} & BaseProps;

/**
 * Convenience wrapper around the Select input.
 * Use this in (Formik) forms.
 */
export const NumberInput: React.FC<Props> = ({
  name,
  label,
  placeholder,
  onChangeEvent,
  isDisabled,
  isRequired,
  minValue,
  maxValue,
  value,
  showStepper,
  error,
  ...rest
}) => {
  return (
    <FormControl
      isInvalid={Boolean(error)}
      isRequired={isRequired}
      height="100%"
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <NumberInputControl
        {...rest}
        id={name}
        name={name}
        showStepper={showStepper}
        numberInputProps={{
          min: minValue,
          max: maxValue,
          value,
          onChange: (valueAsString: string, valueAsNumber: number) =>
            onChangeEvent?.(valueAsString, valueAsNumber),
        }}
        placeholder={placeholder}
      ></NumberInputControl>
      {error && <FormErrorMessage {...rest}>{error}</FormErrorMessage>}
    </FormControl>
  );
};
