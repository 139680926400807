import { useField } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";
import { DateTypeConstraints } from "src/components/Form/QuestionForm/formik";
import { BaseInputProps } from "./QuestionDynamicInputs/Question";

export interface DateProps extends BaseInputProps {
  constraints?: DateTypeConstraints;
}

export const DateInput: React.FC<DateProps> = ({
  id,
  isDisabled = false,
  constraints,
}) => {
  const validateDate = (value: string) => {
    if (!value) return; // don't need to validate blank answer
    const minDate = constraints?.startDate ?? "1900-01-01";
    const maxDate = constraints?.endDate ?? "2200-12-31";
    if (value < minDate) {
      return `Date must be on or after ${minDate}`;
    } else if (value > maxDate) {
      return `Date must be on or before ${maxDate}`;
    }
  };

  const [field] = useField({ name: id, validate: validateDate });

  return (
    <InputControl
      inputProps={{
        type: "date",
        isDisabled: isDisabled,
        min: constraints?.startDate ?? "1900-01-01",
        max: constraints?.endDate ?? "2200-12-31",
        value: field.value,
      }}
      id={id}
      name={field.name}
    />
  );
};
