import {
  Button,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { GET_FORM_DATA_IMPORT_ROW_ERRORS } from "src/components/FormImport/graphql/queries";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";

interface FormImportErrorsDialogProps {
  formImportId: uuid;
  isOpen: boolean;
  onClose: () => void;
}

export function FormImportErrorsDialog(props: FormImportErrorsDialogProps) {
  const { formImportId, isOpen, onClose } = props;

  const { remoteData } = useRemoteDataQuery<
    GQL.GetFormImportRowErrors,
    GQL.GetFormImportRowErrorsVariables
  >(GET_FORM_DATA_IMPORT_ROW_ERRORS, {
    skip: !isOpen,
    variables: {
      form_import_id: formImportId,
    },
  });

  const rows: GQL.GetFormImportRowErrors_form_import_row[] =
    remoteData.hasData() ? remoteData.data.form_import_row : [];

  let body;
  if (remoteData.isLoading()) {
    body = <Spinner label="Loading" />;
  } else if (remoteData.hasData()) {
    body = (
      <>
        <p>
          Import failed on some rows. Review the imported file, and retry the
          upload for the following rows:
        </p>

        <List>
          {rows.map((row) => {
            return (
              <ListItem key={row.row_number} wordBreak="break-word">
                {row.row_number} - {buildErrorMessage(row)}
              </ListItem>
            );
          })}
        </List>
      </>
    );
  } else {
    body = (
      <Text>
        Could not load form import errors. Please try again later or report the
        problem to our support team.
      </Text>
    );
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Import errors</ModalHeader>

        <ModalBody as={VStack} alignItems="start" gap={4}>
          {body}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Done</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function buildErrorMessage(
  row: GQL.GetFormImportRowErrors_form_import_row
): string {
  return row.errors
    .map(
      (errorCode: keyof typeof messagesByErrorCode) =>
        messagesByErrorCode[errorCode] || messagesByErrorCode.UnexpectedError
    )
    .join("; ");
}

const messagesByErrorCode = {
  MissingStudentId: "Missing student ID",
  InvalidStudentId: "Invalid student ID",
  MissingSchoolId: "Missing school ID",
  InvalidSchoolId: "Invalid school ID",
  MissingSchoolRank: "Missing school rank number",
  InvalidSchoolRankNaN: "Invalid school rank, has to be a number",
  InvalidSchoolRankLTEZero: "Invalid school rank, has to be greater than 0",
  MissingStatus: "Missing status",
  InvalidStatus: "Invalid status",
  MissingHide: "Missing hidden from parent",
  InvalidHide: "Invalid hidden from parent",
  MissingCoreColumn: "Missing core column",
  MissingQuestionColumn: "Missing question column",
  MissingRequiredAnswer: "Missing required answer",
  InvalidSingleSelectAnswer: "Invalid answer",
  InvalidMultiSelectAnswer: "Invalid answer",
  FileUploadNotSupported: "Unable to create responses with files",
  FormMixSchoolNoSchool: "Missing school ID",
  FormMoreThanOneNoSchool: "Missing school ID",
  InvalidGradeAnswer: "Invalid grade",
  InvalidEmailAnswer: "Invalid email",
  InvalidPhoneAnswer: "Invalid phone number",
  InvalidDateAnswer: "Invalid date format",
  InvalidNumberAnswer: "Invalid number format",
  UnexpectedError: "Unexpected error",
};
