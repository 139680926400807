import { ApolloError } from "@apollo/client";
import { Alert } from "@chakra-ui/react";
import { Formik, FormikProps } from "formik";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApolloError as ApolloErrorView } from "src/components/Feedback/ApolloError";
import { GenericError } from "src/components/Feedback/GenericError";
import { OrganizationError as OrganizationErrorView } from "src/components/Feedback/OrganizationError";
import { School } from "src/components/Inputs/MultiSelectSchoolRank";
import { Student } from "src/components/Layout/Avatar";
import { FormLayout } from "src/components/Layout/FormLayout";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { useGlossary } from "src/hooks/useGlossary";
import {
  isOrganizationError,
  useOrganization,
} from "src/hooks/useOrganization";
import { usePreviousFormSchools } from "src/hooks/usePreviousFormSchools";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useRankedSchools, { RankedSchool } from "src/hooks/useSchoolRank";
import { useWeglotToast } from "src/plugins/weglot";
import * as FormTemplate from "src/services/formTemplate";
import {
  isFreeTextAnswer,
  isGradesAnswer,
  isMultiSelectAnswer,
  isSingleSelectAnswer,
} from "src/services/formTemplate/answer";
import { getCompleteApplicableQuestions } from "src/services/formTemplate/question";
import {
  findGeneralSections,
  findSchoolRankingSection,
} from "src/services/formTemplate/section";
import * as AFF from "src/services/formTemplateFilters";
import { validateFormTemplate } from "src/services/formTemplateValidations";
import { formatNumberStringToNumber } from "src/services/format";
import { LATE_EDIT_STATUSES } from "src/services/lateEdit";
import * as Url from "src/services/url";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { failure, success } from "src/types/remoteData";
import { LateEditRankSchools } from "./LateEditSchoolsRank";
import { FormQuestion } from "./components/Inputs/FormQuestion";
import { LateEditFormButtons } from "./components/Layout/LateEditFormButtons";
import { StudentHeader } from "./components/Layout/StudentHeader";
import {
  INSERT_FORM_ANSWER_OPTIONS,
  LATE_EDIT_SCHOOLS_RANK,
  UPSERT_FORM_ANSWER_DATE,
  UPSERT_FORM_ANSWER_FREE_TEXT,
  UPSERT_FORM_ANSWER_NUMBER,
  UPSERT_GRADES_ANSWER,
} from "./graphql/mutations";
import {
  GET_FORM_ANSWERS_BY_ID,
  GET_FORM_BY_ID,
  GET_SCHOOLS_RANK,
} from "./graphql/queries";

const TOAST_SUCCESS_ID = "late-edit-success";
const TOAST_ERROR_ID = "late-edit-error";
const TOAST_INFO_ID = "late-edit-info-success";

enum SelectTab {
  RANK_SCHOOLS = "rank-schools",
  SCHOOL_QUESTIONS = "school-questions",
  GENERAL_QUESTIONS = "general-questions",
}

function handleError(error: Error | ApolloError): React.ReactElement {
  if (error instanceof ApolloError) {
    return <ApolloErrorView error={error} />;
  }

  if (isOrganizationError(error)) {
    return <OrganizationErrorView error={error} />;
  }

  return <Alert status="error">{error.message}</Alert>;
}

type Data = {
  form: GQL.FormFragment;
  generalSections: AF.GeneralSection<AF.WithId>[];
  sections: AF.Sections<AF.WithId>;
  enrollmentPeriodName: string;
  student: AFF.Types.Applicant & Student;
  previousFormSchoolIds: uuid[];
  answers: GQL.GetFormAnswersById;
  familyEditableQuestions: readonly AF.Question<AF.WithId>[];
  schoolRankingSection?: AF.SchoolRankingSection<AF.WithId>;
};

export type SchoolWithFakeRank = {
  id: uuid;
  name: string;
  street_address: string | null;
  rank: number;
};

export const LateEdit = () => {
  const { glossary } = useGlossary();
  const navigate = useNavigate();
  const toast = useWeglotToast();
  const organization = useOrganization();
  const { id: formId = "" } = useParams();

  const [updatedSchoolRanks, setUpdatedSchoolRanks] = React.useState<
    GQL.GetSchoolsRank_form_school_rank[]
  >([]);
  const [removedSchoolRanks, setRemovedSchoolRanks] = React.useState<
    GQL.GetSchoolsRank_form_school_rank[]
  >([]);
  const [newSchools, setNewSchools] = React.useState<SchoolWithFakeRank[]>([]);
  const [selectedTab, setSelectedTab] = React.useState<SelectTab>(
    SelectTab.RANK_SCHOOLS
  );
  const flags = useFlags(["family-editable-statuses"]);

  const isFamilyEditableFlagEnabled = useMemo(() => {
    return flags["family-editable-statuses"].enabled;
  }, [flags]);

  const RankedSchools = useRankedSchools([]);

  const { remoteData: schoolRanksData } = useRemoteDataQuery<
    GQL.GetSchoolsRank,
    GQL.GetSchoolsRankVariables
  >(GET_SCHOOLS_RANK, {
    variables: {
      form_id: formId,
    },
    fetchPolicy: "no-cache",
  });

  const { remoteData: formData } = useRemoteDataQuery<
    GQL.GetFormById,
    GQL.GetFormByIdVariables
  >(GET_FORM_BY_ID, {
    variables: { form_id: formId },
    fetchPolicy: "no-cache",
  });

  const { remoteData: answersRemoteData } = useRemoteDataQuery<
    GQL.GetFormAnswersById,
    GQL.GetFormAnswersByIdVariables
  >(GET_FORM_ANSWERS_BY_ID, {
    fetchPolicy: "no-cache",
    variables: { form_id: formId },
  });

  const previousFormSchoolsRemoteData = usePreviousFormSchools(formId);

  const remoteData = React.useMemo(() => {
    return RD.toTuple4(
      formData,
      schoolRanksData,
      previousFormSchoolsRemoteData,
      answersRemoteData
    )
      .mapError<ApolloError | Error>((error) => error)
      .andThen(([data, schoolData, previousFormSchoolIds, answersData]) => {
        const form = data.form[0];
        if (!form?.form_template) {
          return failure<ApolloError | Error, Data>(
            new Error("Form not found")
          );
        }
        try {
          const formTemplate = FormTemplate.fromGQL(form.form_template);

          const enrollmentPeriodName =
            form.form_template.enrollment_period.name;

          const generalSections = findGeneralSections(formTemplate.sections);

          const schoolRankingSection = findSchoolRankingSection(
            formTemplate.sections
          );

          if (
            !schoolRankingSection ||
            (schoolRankingSection?.familyEditableStatuses &&
              !schoolRankingSection?.familyEditableStatuses?.includes(
                form.status
              ) &&
              isFamilyEditableFlagEnabled)
          ) {
            setSelectedTab(SelectTab.GENERAL_QUESTIONS);
          }

          setUpdatedSchoolRanks(schoolData.form_school_rank);

          if (schoolRankingSection) {
            RankedSchools.setRanks(
              schoolData.form_school_rank.map((item) => ({
                form_id: formId,
                schools_ranking_section_id: schoolRankingSection.id,
                school_id: item.school.id,
              }))
            );
          }

          const familyEditableSections = formTemplate.sections.filter(
            (section) => {
              if (!section?.familyEditableStatuses) {
                return false;
              }

              return section.familyEditableStatuses.includes(form.status);
            }
          );
          const familyEditableQuestions = familyEditableSections.flatMap(
            (section) =>
              section && "questions" in section ? section.questions : []
          );

          return success<ApolloError, Data>({
            familyEditableQuestions,
            previousFormSchoolIds,
            form,
            enrollmentPeriodName,
            schoolRankingSection,
            generalSections,
            sections: formTemplate.sections,
            answers: answersData,
            student: {
              first_name: form.person.first_name ?? "",
              last_name: form.person.last_name ?? "",
              avatar: form.person.avatar,
              birth_date: form.person.birth_date,
            },
          });
        } catch (error: unknown) {
          console.error(error);
          return failure<ApolloError | Error, Data>(error as Error);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, formData, schoolRanksData]);

  const specificToNewSchoolsQuestions = useMemo(() => {
    if (!remoteData.hasData() || "questions" in remoteData.data.generalSections)
      return [];

    return FormTemplate.Question.getSpecificToSchoolsQuestions(
      remoteData.data.generalSections.flatMap((s) => s.questions),
      newSchools.map((s) => s.id)
    );
  }, [remoteData, newSchools]);

  const specificToDeletedSchoolsQuestions = useMemo(() => {
    if (!remoteData.hasData() || "questions" in remoteData.data.generalSections)
      return [];

    return FormTemplate.Question.getSpecificToSchoolsQuestions(
      remoteData.data.generalSections.flatMap((s) => s.questions),
      removedSchoolRanks.map((s) => s.school.id)
    );
  }, [remoteData, removedSchoolRanks]);

  const familyEditableQuestions = useMemo(() => {
    if (!remoteData.hasData()) return [];

    return remoteData.data.familyEditableQuestions.filter(
      (question) =>
        !specificToNewSchoolsQuestions.some((s) => s.id === question.id) ||
        !specificToDeletedSchoolsQuestions.some((s) => s.id === question.id)
    );
  }, [
    remoteData,
    specificToNewSchoolsQuestions,
    specificToDeletedSchoolsQuestions,
  ]);

  const hasFamilyEditableQuestions = useMemo(() => {
    if (!remoteData.hasData()) return false;

    return remoteData.data.familyEditableQuestions.length > 0;
  }, [remoteData]);

  const [lateEditRankSchools] = useRemoteDataMutation<
    GQL.LateEditSchoolRanks,
    GQL.LateEditSchoolRanksVariables
  >(LATE_EDIT_SCHOOLS_RANK);

  const [upsertFreeText] = useRemoteDataMutation<
    GQL.UpsertFormAnswerFreeText,
    GQL.UpsertFormAnswerFreeTextVariables
  >(UPSERT_FORM_ANSWER_FREE_TEXT, { fetchPolicy: "no-cache" });

  const [upsertNumber] = useRemoteDataMutation<
    GQL.UpsertFormAnswerNumber,
    GQL.UpsertFormAnswerNumberVariables
  >(UPSERT_FORM_ANSWER_NUMBER, { fetchPolicy: "no-cache" });

  const [upsertDate] = useRemoteDataMutation<
    GQL.UpsertFormAnswerDate,
    GQL.UpsertFormAnswerDateVariables
  >(UPSERT_FORM_ANSWER_DATE, { fetchPolicy: "no-cache" });

  const [insertAnswerOptions] = useRemoteDataMutation<
    GQL.InsertFormAnswerOptions,
    GQL.InsertFormAnswerOptionsVariables
  >(INSERT_FORM_ANSWER_OPTIONS, { fetchPolicy: "no-cache" });

  const [upsertGrades] = useRemoteDataMutation<
    GQL.UpsertGradesAnswer,
    GQL.UpsertGradesAnswerVariables
  >(UPSERT_GRADES_ANSWER, { fetchPolicy: "no-cache" });

  const saveAnswers = useCallback(
    async (
      answers: FormTemplate.Answer.FormikValues,
      narrowDownQuestions: readonly AF.Question<AF.WithId>[],
      formId: string
    ) => {
      for (const questionId of Object.keys(answers)) {
        const question = narrowDownQuestions?.find(
          (question) => question.id === questionId
        );

        if (question?.type === GQL.question_type_enum.SingleSelect) {
          if (!isSingleSelectAnswer(answers[questionId])) {
            console.error(
              `Expecting a "string" for question ${question.id}, but got ${answers[questionId]} instead`
            );
            continue;
          }

          await insertAnswerOptions({
            variables: {
              form_id: formId,
              question_id: question.id,
              options: answers[questionId]
                ? [
                    {
                      form_question_option_id: answers[questionId]?.toString(),
                    },
                  ]
                : [],
            },
          });
        } else if (question?.type === GQL.question_type_enum.MultiSelect) {
          if (!isMultiSelectAnswer(answers[questionId])) {
            console.error(
              `Expecting a "string[]" for question ${question.id}, but got ${answers[questionId]} instead`
            );
            continue;
          }

          const answersOptions = answers[questionId] as string[];

          await insertAnswerOptions({
            variables: {
              form_id: formId,
              question_id: question.id,
              options: answersOptions.length
                ? answersOptions.map((value) => ({
                    form_question_option_id: value,
                  }))
                : [],
            },
          });
        } else if (question?.type === GQL.question_type_enum.FreeText) {
          if (!isFreeTextAnswer(answers[questionId])) {
            console.error(
              `Expecting a "string" for question ${question.id}, but got ${answers[questionId]} instead`
            );
            continue;
          }
          await upsertFreeText({
            variables: {
              form_id: formId,
              question_id: question.id,
              free_text_answer: answers[questionId]?.toString(),
            },
          });
        } else if (question?.type === GQL.question_type_enum.Number) {
          const number = formatNumberStringToNumber(answers[questionId]);

          await upsertNumber({
            variables: {
              form_id: formId,
              question_id: question.id,
              number_answer: number,
            },
          });
        } else if (question?.type === GQL.question_type_enum.Date) {
          const stringDate = answers[questionId]?.toString();

          if (!isFreeTextAnswer(stringDate)) {
            console.error(
              `Expecting a "string" for question ${question.id}, but got ${stringDate} instead`
            );
            return false;
          }

          await upsertDate({
            variables: {
              form_id: formId,
              question_id: question.id,
              date_answer:
                stringDate && stringDate.length > 0 ? stringDate : null,
            },
          });
          return true;
        } else if (question?.type === GQL.question_type_enum.Grades) {
          if (!isGradesAnswer(answers[questionId])) {
            console.error(
              `Expecting a "string[]" for question ${question.id}, but got ${answers[questionId]} instead`
            );
            continue;
          }
          await upsertGrades({
            variables: {
              form_id: formId,
              question_id: question.id,
              grade_config_id: answers[questionId]?.toString(),
            },
          });
        }
      }
    },
    [
      insertAnswerOptions,
      upsertDate,
      upsertFreeText,
      upsertGrades,
      upsertNumber,
    ]
  );

  const handleDeletedSchoolRank = (
    schoolRank: GQL.GetSchoolsRank_form_school_rank
  ) => {
    if (schoolRank.offers[0]?.status === GQL.offer_status_enum.Accepted) return;
    setRemovedSchoolRanks([...removedSchoolRanks, schoolRank]);
    const newSchoolRanks = updatedSchoolRanks.filter(
      (s) => s.id !== schoolRank.id
    );

    newSchoolRanks.forEach((s, index) => {
      s.rank = index;
    });

    const newSchoolsUpdatedRanks = newSchools.map((s, index) => {
      s.rank = newSchoolRanks.length + index;
      return s;
    });

    setUpdatedSchoolRanks(newSchoolRanks);
    setNewSchools(newSchoolsUpdatedRanks);

    toast({
      id: TOAST_INFO_ID,
      status: "info",
      title: glossary`School removed from list.`,
      isClosable: true,
    });
  };

  const handleLateSelectSchool = (school: School) => {
    setNewSchools([
      ...newSchools,
      { ...school, rank: updatedSchoolRanks.length + newSchools.length },
    ]);
  };

  const handleDeleteNewSchool = (school: School) => {
    const updatedNewSchools = newSchools.filter((s) => s.id !== school.id);
    updatedNewSchools.forEach((s, index) => {
      s.rank = updatedSchoolRanks.length + index;
    });
    setNewSchools(updatedNewSchools);

    if (!toast.isActive(TOAST_INFO_ID)) {
      toast({
        id: TOAST_INFO_ID,
        status: "info",
        title: glossary`School removed from list.`,
        isClosable: true,
      });
    }
  };

  const handleUpdateGeneralQuestions = useCallback(
    async (
      answers: FormTemplate.Answer.FormikValues,
      generalQuestions: readonly AF.Question<AF.WithId>[]
    ) => {
      if (!remoteData.hasData()) return;

      try {
        if (answers && generalQuestions) {
          await saveAnswers(answers, generalQuestions, remoteData.data.form.id);
        }
        navigate(organization.map(Url.Parent.index).withDefault("#"));
      } catch (err) {
        console.error(err);
      }
    },
    [navigate, organization, remoteData, saveAnswers]
  );

  const handleUpdateSchoolRanks = useCallback(
    async (
      answers?: FormTemplate.Answer.FormikValues,
      specificToNewSchoolsQuestions?: readonly AF.Question<AF.WithId>[]
    ) => {
      if (!remoteData.hasData()) return;

      try {
        if (newSchools.length && answers && specificToNewSchoolsQuestions) {
          await saveAnswers(
            answers,
            specificToNewSchoolsQuestions,
            remoteData.data.form.id
          );
        }

        const schoolRanksData = updatedSchoolRanks.map((schoolRank) => ({
          form_id: remoteData.data.form.id,
          schools_ranking_section_id: remoteData.data.schoolRankingSection?.id,
          school_id: schoolRank.school.id,
          rank: schoolRank.rank,
        }));

        const newSchoolsData = newSchools.map((school) => ({
          form_id: remoteData.data.form.id,
          schools_ranking_section_id:
            remoteData.data.schoolRankingSection?.id ?? "",
          school_id: school.id,
          rank: school.rank,
        }));

        const upsertedSchoolRanks = RankedSchools.getUpsertedRanks(
          schoolRanksData.concat(newSchoolsData) as RankedSchool[]
        );
        const deletedSchoolRanks =
          RankedSchools.getDeletedRanks(upsertedSchoolRanks);
        const deletedOffers =
          RankedSchools.getDeletedOffers(upsertedSchoolRanks);
        const deletedWaitlists =
          RankedSchools.getDeletedWaitlists(upsertedSchoolRanks);

        await lateEditRankSchools({
          variables: {
            deleted_school_ranks: deletedSchoolRanks,
            upserted_school_ranks: upsertedSchoolRanks,
            delete_offers_where: deletedOffers,
            delete_waitlists_where: deletedWaitlists,
          },
        });

        if (!toast.isActive(TOAST_SUCCESS_ID)) {
          toast({
            id: TOAST_ERROR_ID,
            status: "success",
            title: glossary`Schools updated`,
            isClosable: true,
          });
        }

        if (hasFamilyEditableQuestions && isFamilyEditableFlagEnabled) {
          setSelectedTab(SelectTab.GENERAL_QUESTIONS);
        } else {
          navigate(organization.map(Url.Parent.index).withDefault("#"));
        }
      } catch (err) {
        if (!toast.isActive(TOAST_ERROR_ID)) {
          toast({
            id: TOAST_ERROR_ID,
            status: "error",
            title: glossary`Error while updating schools`,
            description:
              "Please try again later or report the problem to our support team.",
            isClosable: true,
          });
        }
      }
    },
    [
      remoteData,
      newSchools,
      updatedSchoolRanks,
      RankedSchools,
      lateEditRankSchools,
      toast,
      navigate,
      organization,
      saveAnswers,
      glossary,
      hasFamilyEditableQuestions,
      isFamilyEditableFlagEnabled,
    ]
  );

  const verifyNextStep = useCallback(
    (generalQuestions: readonly AF.Question<AF.WithId>[]) => {
      if (!newSchools.length) {
        return handleUpdateSchoolRanks();
      }

      const validQuestions =
        FormTemplate.Question.getSpecificToSchoolsQuestions(
          generalQuestions,
          newSchools.map((s) => s.id)
        );

      if (!validQuestions.length) {
        return handleUpdateSchoolRanks();
      }

      setSelectedTab(SelectTab.SCHOOL_QUESTIONS);
    },
    [newSchools, handleUpdateSchoolRanks]
  );

  const getInitialValues = (
    generalQuestions: AF.Question<AF.WithId>[],
    answers: GQL.GetFormAnswersById
  ) =>
    FormTemplate.Answer.getFormikInitialValues(
      generalQuestions,
      answers.form_answer,
      answers.grades_answer,
      answers.form_address,
      answers.custom_question_answer
    );

  const leftNavigationOverride = useMemo(() => {
    switch (selectedTab) {
      case SelectTab.RANK_SCHOOLS:
        return {
          label: "Cancel",
          action: () =>
            navigate(organization.map(Url.Parent.index).withDefault("#")),
        };
      case SelectTab.SCHOOL_QUESTIONS:
        return {
          label: "Back",
          action: () => setSelectedTab(SelectTab.RANK_SCHOOLS),
        };
      case SelectTab.GENERAL_QUESTIONS:
        return {
          label: "Cancel",
          action: () =>
            navigate(organization.map(Url.Parent.index).withDefault("#")),
        };
    }
  }, [selectedTab, navigate, organization]);

  const handleRankSchoolsNavigation = useCallback(
    (generalQuestions: readonly AF.Question<AF.WithId>[]) => ({
      label: "Next",
      action: () => verifyNextStep(generalQuestions),
    }),
    [verifyNextStep]
  );

  const handleSchoolQuestionsNavigation = useCallback(
    (
      formikProps: FormikProps<FormTemplate.Answer.FormikValues>,
      schoolQuestions: readonly AF.Question<AF.WithId>[]
    ) => ({
      label: glossary`Update schools`,
      action: () => {
        if (formikProps.isValid) {
          handleUpdateSchoolRanks(formikProps.values, schoolQuestions);
        }
      },
      hide: !formikProps.isValid,
    }),
    [glossary, handleUpdateSchoolRanks]
  );

  const handleGeneralQuestionsNavigation = useCallback(
    (
      formikProps: FormikProps<FormTemplate.Answer.FormikValues>,
      generalQuestions: readonly AF.Question<AF.WithId>[]
    ) => ({
      label: "Update",
      action: () => {
        if (formikProps.isValid) {
          handleUpdateGeneralQuestions(formikProps.values, generalQuestions);
        }
      },
      hide: !formikProps.isValid,
    }),
    [handleUpdateGeneralQuestions]
  );

  const rightNavigationOverride = useCallback(
    (
      generalQuestions: readonly AF.Question<AF.WithId>[],
      formikProps: FormikProps<FormTemplate.Answer.FormikValues>,
      schoolQuestions: readonly AF.Question<AF.WithId>[],
      familyEditableQuestions: readonly AF.Question<AF.WithId>[]
    ) => {
      switch (selectedTab) {
        case SelectTab.RANK_SCHOOLS:
          return handleRankSchoolsNavigation(generalQuestions);
        case SelectTab.SCHOOL_QUESTIONS:
          return handleSchoolQuestionsNavigation(formikProps, schoolQuestions);
        case SelectTab.GENERAL_QUESTIONS:
          return handleGeneralQuestionsNavigation(
            formikProps,
            familyEditableQuestions
          );
      }
    },
    [
      selectedTab,
      handleRankSchoolsNavigation,
      handleSchoolQuestionsNavigation,
      handleGeneralQuestionsNavigation,
    ]
  );

  return (
    <ParentRemoteDataLayout error={handleError} remoteData={remoteData}>
      {({
        form,
        schoolRankingSection,
        generalSections,
        sections,
        enrollmentPeriodName,
        student,
        previousFormSchoolIds,
        answers,
      }) => {
        if (!LATE_EDIT_STATUSES.includes(form.status))
          return <GenericError message="This form it's not locked." />;

        const generalQuestions = generalSections.flatMap(
          (section) => section.questions
        );

        return (
          <Formik
            initialValues={getInitialValues(familyEditableQuestions, answers)}
            onSubmit={() => {}}
            validate={
              selectedTab === SelectTab.SCHOOL_QUESTIONS
                ? validateFormTemplate(specificToNewSchoolsQuestions, {
                    rankedSchoolIds: newSchools.map((s) => s.id),
                    previousFormSchoolIds,
                  })
                : selectedTab === SelectTab.GENERAL_QUESTIONS
                ? validateFormTemplate(familyEditableQuestions, {
                    rankedSchoolIds: updatedSchoolRanks.map((s) => s.school.id),
                    previousFormSchoolIds,
                  })
                : undefined
            }
            isInitialValid={false}
          >
            {(formikProps) => {
              const schoolQuestions = getCompleteApplicableQuestions(
                specificToNewSchoolsQuestions,
                formikProps.values,
                {
                  rankedSchoolIds: newSchools.map((s) => s.id),
                  previousFormSchoolIds,
                }
              );
              const localFamilyEditableQuestions =
                getCompleteApplicableQuestions(
                  familyEditableQuestions,
                  formikProps.values,
                  {
                    rankedSchoolIds: updatedSchoolRanks.map((s) => s.school.id),
                    previousFormSchoolIds,
                  }
                );

              return (
                <FormLayout
                  heading={glossary`Edit schools`}
                  buttons={
                    <LateEditFormButtons
                      overridePreviousButton={leftNavigationOverride}
                      overrideNextButton={rightNavigationOverride(
                        generalQuestions,
                        formikProps,
                        schoolQuestions,
                        familyEditableQuestions
                      )}
                    />
                  }
                  backLink={{
                    url: organization.map(Url.Parent.index).withDefault("#"),
                    label: "Back to forms",
                  }}
                >
                  <StudentHeader
                    student={student}
                    enrollmentPeriodName={enrollmentPeriodName}
                  />
                  {selectedTab === SelectTab.RANK_SCHOOLS &&
                    schoolRankingSection && (
                      <LateEditRankSchools
                        formTemplateId={form.form_template.id}
                        formId={formId}
                        enrollmentPeriodId={
                          form.form_template.enrollment_period_id
                        }
                        section={schoolRankingSection}
                        allSections={sections}
                        newSchools={newSchools}
                        schoolRanks={updatedSchoolRanks}
                        onLateDeleteSchoolRank={handleDeletedSchoolRank}
                        onLateSelectSchool={handleLateSelectSchool}
                        onLateDeleteNewSchool={handleDeleteNewSchool}
                      />
                    )}
                  {selectedTab === SelectTab.SCHOOL_QUESTIONS &&
                    schoolQuestions.map((question) => (
                      <FormQuestion
                        formId={form.id}
                        applicant={student}
                        key={question.id}
                        question={question}
                        marginBottom={4}
                      />
                    ))}
                  {selectedTab === SelectTab.GENERAL_QUESTIONS &&
                    localFamilyEditableQuestions.map((question) => (
                      <FormQuestion
                        formId={form.id}
                        applicant={student}
                        key={question.id}
                        question={question}
                        marginBottom={4}
                      />
                    ))}
                </FormLayout>
              );
            }}
          </Formik>
        );
      }}
    </ParentRemoteDataLayout>
  );
};
