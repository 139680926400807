import { ApolloError } from "@apollo/client";
import { Button, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { identity } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EmptyState } from "src/components/EmptyState";
import { FormikAddressBook } from "src/components/Inputs/Address/Book";
import { AuthLayout } from "src/components/Layout/AuthLayout";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { useOrganization } from "src/hooks/useOrganization";
import { usePreferredLanguage } from "src/hooks/usePreferredLanguage";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useUser from "src/hooks/useUser";
import { UserProfileError, useUserProfile } from "src/hooks/useUserProfile";
import { ReactComponent as LocationOnMapSvg } from "src/images/location-on-map.svg";
import { useWeglotToast } from "src/plugins/weglot";
import {
  AddressSchema,
  AddressValidationSchema,
  BaseAddressSchema,
} from "src/schemas/Address";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { failure, success } from "src/types/remoteData";
import { z } from "zod";
import { UPSERT_PROFILE_ADDRESS } from "../graphql/mutations";

const ParentAddressFormSchema = z.object({
  address: BaseAddressSchema,
});
type ParentAddressForm = z.infer<typeof ParentAddressFormSchema>;

const ParentAddressFormValidationSchema = z.object({
  address: AddressValidationSchema,
});

const emptyAddressForm: ParentAddressForm = {
  address: {
    street_address: "",
    street_address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
  },
};

export const Address: React.FC = () => {
  const user = useUser();
  const userProfile = useUserProfile()
    .map<GQL.ProfileFragment | null>(identity)
    .recover<ApolloError>((error: UserProfileError) => {
      switch (error.kind) {
        case "ServerError":
          return failure(error.error);

        case "ProfileNotFoundError":
        case "UnauthenticatedError":
          return success(null);
      }
    });
  const toast = useWeglotToast();
  const genericErrorToast = () => {
    toast({
      title: "Error updating profile",
      description:
        "Please try again later or report the problem to our support team.",
      status: "error",
      isClosable: true,
    });
  };
  const [upsertProfileName, { remoteData }] = useRemoteDataMutation<
    GQL.UpsertProfileAddress,
    GQL.UpsertProfileAddressVariables
  >(UPSERT_PROFILE_ADDRESS);

  const submitHandler = async (values: ParentAddressForm) => {
    if (user.status !== Status.OK || !organization.hasData()) {
      genericErrorToast();
      return;
    }

    const { address } = values;
    const parsedAddress = BaseAddressSchema.parse(address);

    try {
      await upsertProfileName({
        variables: {
          user_id: user.data.id,
          organization_id: organization.data.id,
          ...parsedAddress,
        },
      });
      navigate(Url.Parent.index(organization.data));
    } catch (err: unknown) {
      console.error(err);
      genericErrorToast();
    }
  };

  const organization = useOrganization();
  const navigate = useNavigate();
  const preferredLanguageSetting = usePreferredLanguage();

  return (
    <AuthLayout languageSelector={{ preferredLanguageSetting }}>
      <ParentRemoteDataLayout remoteData={userProfile}>
        {(data) => {
          const parsed = AddressSchema.safeParse(data);
          const initialValues = parsed.success
            ? { address: parsed.data }
            : emptyAddressForm;
          return (
            <Formik<ParentAddressForm>
              initialValues={initialValues}
              onSubmit={submitHandler}
              validate={validateWithZod(ParentAddressFormValidationSchema)}
              validateOnMount={true}
            >
              <Flex
                as={Form}
                direction="column"
                gap={4}
                width="100%"
                alignItems="stretch"
              >
                <EmptyState heading="Almost done!" Svg={LocationOnMapSvg} />
                <FormikAddressBook fieldName="address" addressIsRequired />
                <Button type="submit" isLoading={remoteData.isLoading()}>
                  Submit
                </Button>
              </Flex>
            </Formik>
          );
        }}
      </ParentRemoteDataLayout>
    </AuthLayout>
  );
};
