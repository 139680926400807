import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { toBaseQuestion, toFormVerification } from "../question";
import { toGradesQuestion } from "./questionTypes/grades";
import {
  toMultiSelectWithBranching,
  toMultiSelectWithoutBranching,
  toSingleSelectWithBranching,
  toSingleSelectWithoutBranching,
} from "./questionTypes/select";
import {
  toAddress,
  toDate,
  toEmail,
  toFileUpload,
  toFreeText,
  toNumber,
  toPhoneNumber,
} from "./questionTypes/simple";
import { formatQueryResponseAsCustomQuestionProps } from "../customQuestion";

export function toQuestionWithBranching(
  value: GQL.QuestionFragment | null | undefined
): AF.Question<AF.WithId> | null {
  if (!value) {
    return null;
  }

  switch (value.type) {
    case GQL.question_type_enum.Date:
      return toDate(value);
    case GQL.question_type_enum.Number:
      return toNumber(value);
    case GQL.question_type_enum.FileUpload:
      return toFileUpload(value);
    case GQL.question_type_enum.FreeText:
      return toFreeText(value);
    case GQL.question_type_enum.MultiSelect:
      return toMultiSelectWithBranching(value);
    case GQL.question_type_enum.SingleSelect:
      return toSingleSelectWithBranching(value);
    case GQL.question_type_enum.Email:
      return toEmail(value);
    case GQL.question_type_enum.PhoneNumber:
      return toPhoneNumber(value);
    case GQL.question_type_enum.Address:
      return toAddress(value);
    case GQL.question_type_enum.Grades:
      return toGradesQuestion(value);
    case GQL.question_type_enum.CustomQuestion:
      return formatQueryResponseAsCustomQuestionProps(value);
    default:
      const _exhaustiveCheck: never = value.type;
      return _exhaustiveCheck;
  }
}

/**
 * Used for PreRanking Section questions types that contain "additional_questions":
 * - Select
 * - Multi-select
 * - Grades
 */
export function toQuestionWithoutBranching(
  value: GQL.FormQuestionWithoutBranchingFragment | null | undefined
): AF.Question<AF.WithId> | null {
  if (!value) {
    return null;
  }

  switch (value.type) {
    case GQL.question_type_enum.Date:
      return toDate(value);
    case GQL.question_type_enum.Number:
      return toNumber(value);
    case GQL.question_type_enum.FileUpload:
      return toFileUpload(value);
    case GQL.question_type_enum.FreeText:
      return toFreeText(value);
    case GQL.question_type_enum.MultiSelect:
      return toMultiSelectWithoutBranching(value);
    case GQL.question_type_enum.SingleSelect:
      return toSingleSelectWithoutBranching(value);
    case GQL.question_type_enum.Email:
      return toEmail(value);
    case GQL.question_type_enum.PhoneNumber:
      return toPhoneNumber(value);
    case GQL.question_type_enum.Address:
      return toAddress(value);
    case GQL.question_type_enum.Grades:
      throw new Error(
        "Grades question is not supported as additional questions"
      );
    case GQL.question_type_enum.CustomQuestion:
      throw new Error(
        "Custom question is not supported as additional questions"
      );
    default:
      const _exhaustiveCheck: never = value.type;
      return _exhaustiveCheck;
  }
}

export function toBaseGeneralQuestion(
  value: GQL.FormQuestionWithoutBranchingFragment
): Omit<AF.FormQuestion<AF.WithId>, "type"> {
  return {
    ...toBaseQuestion(value),
    category: AF.GeneralCategoryType,
    formVerification: toFormVerification(value),
  };
}
