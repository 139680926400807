import { useCallback, useEffect } from "react";
import * as AF from "src/types/formTemplate";
import * as Answer from "src/services/formTemplate/answer";
import { useUpdateFormAddress } from "src/components/Form/useUpdateFormAddress";
import { AddressAnswer } from "src/components/Form/QuestionForm/formik";
import { rankedSchoolsVar } from "../store";
import {
  calculateSchoolsToRemoveByEligibilityQuestions,
  calculateSchoolsToRemoveByGrade,
} from "src/services/formTemplate/preRankingSection";
import useRankedSchools from "src/hooks/useSchoolRank";
import * as RD from "src/types/remoteData";
import { HooksFn } from "./useEligibilityAnswersChange";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { RankedSchoolsRemovalConfirmationDialogBody } from "../components/Dialogs/RankedSchoolsRemovalConfirmationDialogBody";
import { useDeleteRankedSchools } from "./useDeleteRankedSchools";

export const useFrontEndEligibilityAnswersChange: HooksFn = ({
  formId,
  schoolRankingSection,
  schoolRanksRemoteData,
  refetchSchoolRanks,
  rankingEnabled,
}) => {
  const {
    confirm: confirmAddressChange,
    confirmationDialog: addressChangeConfirmationDialog,
    calculateSchoolsToRemove,
  } = useUpdateFormAddress({ formId, rankingEnabled });

  const { setRanks } = useRankedSchools([]);

  const { deleteRankedSchools } = useDeleteRankedSchools({
    formId,
    schoolRankingSection,
    schoolRanksRemoteData,
    refetchSchoolRanks,
  });

  const confirmAddressQuestionUpdate = useCallback(
    async (
      dateOfBirth: string | null,
      answers: Answer.FormikValues,
      question: AF.Question<AF.WithId>,
      address: Answer.UndoableAnswer<AddressAnswer | undefined>,
      completeQuestions: readonly AF.Question<AF.WithId>[]
    ): Promise<boolean> => {
      if (question.type !== AF.AddressType) {
        throw new Error(
          `Invalid question type: ${question.type}, expecting AddressType`
        );
      }

      const rankedSchools = rankedSchoolsVar();
      const rankedSchoolsToRemove = await calculateSchoolsToRemove(
        rankedSchools,
        completeQuestions,
        question,
        address.after
      );
      const isConfirmed = await confirmAddressChange(rankedSchoolsToRemove);
      if (isConfirmed) {
        // do this in the background so it doesn't block the UI
        deleteRankedSchools(
          rankedSchools,
          rankedSchoolsToRemove.map(({ school: { id } }) => id)
        );
      }

      return isConfirmed;
    },
    [calculateSchoolsToRemove, confirmAddressChange, deleteRankedSchools]
  );

  const { glossary } = useGlossary();
  const { confirm, confirmationDialog } = useConfirmationDialog({
    header: glossary`Edit family/student information`,
    body: null,
    cancelButton: { label: "No, cancel" },
    confirmButton: { label: "Yes, continue" },
    translate: true,
  });
  const confirmGradesQuestionUpdate = useCallback(
    async (
      dateOfBirth: string | null,
      answers: Answer.FormikValues,
      question: AF.Question<AF.WithId>,
      newGradeConfigId: Answer.UndoableAnswer<uuid | undefined>,
      completeQuestions: readonly AF.Question<AF.WithId>[]
    ): Promise<boolean> => {
      const rankedSchools = rankedSchoolsVar();
      const newAnswers = {
        ...answers,
        [question.id]: newGradeConfigId.after,
      };
      const schoolsToRemove = calculateSchoolsToRemoveByGrade(
        rankedSchools,
        completeQuestions,
        newAnswers
      );
      if (schoolsToRemove.length === 0) return true;

      const isConfirmed = await confirm({
        body: (
          <RankedSchoolsRemovalConfirmationDialogBody
            rankingEnabled={rankingEnabled}
            schoolsToRemove={schoolsToRemove}
            body={glossary`By changing this selection, your eligibility will change and these schools will be removed from your list:`}
            confirmation="Are you sure?"
          />
        ),
      });
      if (isConfirmed) {
        // do this in the background so it doesn't block the UI
        deleteRankedSchools(
          rankedSchools,
          schoolsToRemove.map(({ school: { id } }) => id)
        );
      }

      return isConfirmed;
    },
    [confirm, deleteRankedSchools, glossary, rankingEnabled]
  );

  const confirmEligibilityQuestionUpdate = useCallback(
    async (
      dateOfBirth: string | null,
      answers: Answer.FormikValues,
      question: AF.Question<AF.WithId>,
      answer: Answer.UndoableAnswer<AF.Option<AF.WithId> | undefined>,
      completeQuestions: readonly AF.Question<AF.WithId>[]
    ) => {
      if (question.category !== "Eligibility") return true;

      const rankedSchools = rankedSchoolsVar();

      const newAnswers = {
        ...answers,
        [question.id]: answer?.after?.id,
      };

      const schoolsToRemove = calculateSchoolsToRemoveByEligibilityQuestions(
        rankedSchools,
        completeQuestions,
        newAnswers
      );

      if (schoolsToRemove.length === 0) return true;

      const isConfirmed = await confirm({
        body: (
          <RankedSchoolsRemovalConfirmationDialogBody
            rankingEnabled={rankingEnabled}
            schoolsToRemove={schoolsToRemove}
            body={glossary`By changing this selection, your eligibility will change and these schools will be removed from your list:`}
            confirmation="Are you sure?"
          />
        ),
      });
      if (isConfirmed) {
        // do this in the background so it doesn't block the UI
        deleteRankedSchools(
          rankedSchools,
          schoolsToRemove.map(({ school: { id } }) => id)
        );
      }

      return isConfirmed;
    },
    [confirm, deleteRankedSchools, glossary, rankingEnabled]
  );

  useEffect(() => {
    // This is a workaround for weird bug where the
    // rankedSchoolsRemoteData inside the confirmEligibilityQuestionUpdate function
    // doesn't get updated after a refetch.
    // So instead of directly accessing the rankedSchools in the remote data, we're using reactive variable.
    if (schoolRanksRemoteData.kind === RD.RemoteDataKind.Success) {
      rankedSchoolsVar(schoolRanksRemoteData.data.form_school_rank);
    }

    setRanks(
      rankedSchoolsVar().map((rankedSchool) => ({
        form_id: rankedSchool.form.id,
        schools_ranking_section_id: schoolRankingSection?.id ?? "",
        school_id: rankedSchool.school.id,
      }))
    );
  }, [schoolRanksRemoteData, schoolRankingSection, setRanks]);

  return {
    confirmAddress: confirmAddressQuestionUpdate,
    confirmGrades: confirmGradesQuestionUpdate,
    confirmEligibilityQuestion: confirmEligibilityQuestionUpdate,
    dialog: (
      <>
        {confirmationDialog}
        {addressChangeConfirmationDialog}
      </>
    ),
  };
};
