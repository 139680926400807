import _ from "lodash";
import {
  DEFAULT_ADDITIONAL_QUESTIONS,
  DEFAULT_DATE_CONSTRAINT,
  DEFAULT_ELIGIBILITY,
  DEFAULT_NUMBER_CONSTRAINT,
  DEFAULT_OPTION,
  DEFAULT_PERMISSION_LEVEL,
  DEFAULT_QUESTION_LINK,
  DEFAULT_QUESTION_TYPE,
  DEFAULT_REQUIREMENT,
  DEFAULT_VERIFICATION,
} from "src/components/Form/QuestionForm/constants";
import {
  AdditionalQuestions,
  AdditionalQuestionsByOptionId,
  AdditionalQuestionsIds,
  CustomQuestionTypeIdsByQuestionId,
  DateConstraints,
  DateConstraintsByQuestionId,
  DateTypeConstraints,
  DateTypeConstraintsSchema,
  Eligibility,
  EligibilityByOptionId,
  FormTemplateFiltersById,
  FormType,
  NumberConstraints,
  NumberConstraintsByQuestionId,
  NumberTypeConstraints,
  NumberTypeConstraintsSchema,
  Option,
  OptionIdsByQuestionId,
  OptionsByOptionId,
  PermissionLevel,
  PermissionLevelsByQuestionId,
  QuestionForm,
  QuestionKeysByQuestionId,
  QuestionLink,
  QuestionLinksByQuestionId,
  QuestionTitlesByQuestionId,
  QuestionTypesByQuestionId,
  Requirement,
  RequirementsByQuestionId,
  ValidationsById,
  Verification,
  VerificationByQuestionId,
} from "src/components/Form/QuestionForm/formik/types";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as Question from "src/services/formTemplate/question";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import {
  BirthdateEligibilityFilter,
  EligibleFilterType,
  FormTemplateFilter,
  LocationBoundariesFilter,
} from "src/types/formTemplateFilters";

export function toFormValues(question: Draft.Question): FormType {
  return {
    ...toQuestionForm(question),
    questionKeys: toQuestionKeysByQuestionId(question),
    questionTitles: toQuestionTitlesByQuestionId(question),
    additionalQuestions: toAdditionalQuestionsByOptionId(question),
    eligibilities: toEligibilityByOptionId(question),
    optionIds: toOptionIdsByQuestionId(question),
    options: toQuestionOptionsByOptionId(question),
    questionTypes: toQuestionTypesByQuestionId(question),
    customQuestionTypeIds: toCustomQuestionTypeIdsByQuestionId(question),
    verifications: toVerificationByQuestionId(question),
    requirements: toRequirementsByQuestionId(question),
    permissionLevels: toPermissionlevelsByQuestionId(question),
    questionLinks: toQuestionLinksByQuestionId(question),
    validations: toValidationsById(question),
    formTemplateFilters: toQuestionFiltersById(question),
    dateConstraints: toDateConstraintsByQuestionId(question),
    numberConstraints: toNumberConstraintsByQuestionId(question),
  };
}

export function toQuestionForm(question: Draft.Question): QuestionForm {
  return {
    id: question.id,
  };
}

function toQuestionFiltersById(
  parentQuestion: Draft.Question
): FormTemplateFiltersById {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];

  return Object.fromEntries(
    questions.map((question) => {
      return [question.id, question.filters ?? []];
    })
  );
}

function toValidationsById(parentQuestion: Draft.Question): ValidationsById {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  return Object.fromEntries(
    questions.map((question) => {
      return [question.id, { options: "" }];
    })
  );
}

function getAdditionalQuestions(
  parentQuestion: Draft.Question
): Draft.Question[] {
  if (!Question.hasOptions(parentQuestion)) {
    return [];
  }

  return parentQuestion.options.flatMap(
    (option) => option.additionalQuestions ?? []
  );
}

function toQuestionKeysByQuestionId(
  parentQuestion: Draft.Question
): QuestionKeysByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  return Object.fromEntries(questions.map((q) => [q.id, q.key]));
}

function toQuestionTitlesByQuestionId(
  parentQuestion: Draft.Question
): QuestionTitlesByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  return Object.fromEntries(questions.map((q) => [q.id, q.question]));
}

function toQuestionTypesByQuestionId(
  parentQuestion: Draft.Question
): QuestionTypesByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  const questionTypesByQuestionId: QuestionTypesByQuestionId =
    Object.fromEntries(questions.map((q) => [q.id, q.type]));

  return questionTypesByQuestionId;
}

function toCustomQuestionTypeIdsByQuestionId(
  parentQuestion: Draft.Question
): CustomQuestionTypeIdsByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];

  const customQuestionTypesByQuestionId: CustomQuestionTypeIdsByQuestionId =
    Object.fromEntries(
      questions.map((question) => {
        if (Question.hasCustomQuestionType(question)) {
          return [question.id, question.customQuestionTypeId];
        }
        return [question.id, undefined];
      })
    );
  return customQuestionTypesByQuestionId;
}

export function toVerificationByQuestionId(
  parentQuestion: Draft.Question
): VerificationByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  const verificationByQuestionId: VerificationByQuestionId = Object.fromEntries(
    questions.map((question) => {
      switch (question.type) {
        case AF.GradesType:
        case undefined:
          return [question.id, { type: "disabled" }];

        default:
          if (question.newFormVerification) {
            return [
              question.id,
              {
                type: "new",
                id: "NEW",
                label: question.newFormVerification.label,
              },
            ];
          }

          return [
            question.id,
            question.formVerification
              ? {
                  type: "existing",
                  id: question.formVerification?.id,
                }
              : { type: "disabled" },
          ];
      }
    })
  );

  return verificationByQuestionId;
}

function toRequirementsByQuestionId(
  parentQuestion: Draft.Question
): RequirementsByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  return Object.fromEntries(questions.map((q) => [q.id, q.requirement]));
}

function toDateConstraintsByQuestionId(
  parentQuestion: Draft.Question
): DateConstraintsByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  return Object.fromEntries(
    questions.map((q) => {
      if (q.type === AF.DateType) {
        const constraints = q.constraints as DateTypeConstraints;
        return [
          q.id,
          {
            switch:
              isNotNull(constraints.startDate) || isNotNull(constraints.endDate)
                ? "checked"
                : "disabled",
            ...constraints,
          },
        ];
      }
      return [q.id, { switch: "disabled" }];
    })
  );
}

function toNumberConstraintsByQuestionId(
  parentQuestion: Draft.Question
): NumberConstraintsByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  return Object.fromEntries(
    questions.map((q) => {
      if (q.type === AF.NumberType) {
        const constraints = q.constraints as NumberTypeConstraints;
        return [
          q.id,
          {
            switch:
              isNotNull(constraints.minValue) || isNotNull(constraints.maxValue)
                ? "checked"
                : "disabled",
            ...constraints,
          },
        ];
      }
      return [q.id, { switch: "disabled" }];
    })
  );
}

export function toPermissionlevelsByQuestionId(
  parentQuestion: Draft.Question | Draft.NewQuestion
): PermissionLevelsByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  return Object.fromEntries(
    questions.map((question) => {
      if (question.permissionLevel) {
        return [
          question.id,
          {
            type: "checked",
            level: question.permissionLevel,
          },
        ];
      }

      return [
        question.id,
        {
          type: "disabled",
          level: undefined,
        },
      ];
    })
  );
}

function toOptionIdsByQuestionId(
  parentQuestion: Draft.Question
): OptionIdsByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  return Object.fromEntries(
    questions.flatMap((question) => {
      if (!Question.hasOptions(question)) {
        return [];
      }

      return [[question.id, question.options.map((option) => option.id)]];
    })
  );
}

function toQuestionOptionsByOptionId(
  parentQuestion: Draft.Question
): OptionsByOptionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];

  return Object.fromEntries(
    questions.flatMap((question) => {
      if (!Question.hasOptions(question)) {
        return [];
      }

      return question.options.map(
        (option: Draft.Option) =>
          [
            option.id,
            {
              label: option.label,
              isNew: option.isNew ?? false,
              value: option.value,
            },
          ] as const
      );
    })
  );
}

export function toQuestionLinksByQuestionId(
  parentQuestion: Draft.Question | Draft.NewQuestion
): QuestionLinksByQuestionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];
  return Object.fromEntries(
    questions.map((question) => {
      if (question.link_text && question.link_url) {
        return [
          question.id,
          {
            type: "checked",
            text: question.link_text,
            url: question.link_url,
          },
        ];
      }

      return [
        question.id,
        {
          type: "disabled",
          text: "",
          url: "",
        },
      ];
    })
  );
}

export function toEmptyFormValues(id?: string): FormType {
  return {
    ...toEmptyQuestionFormValues(id),
    questionKeys: {},
    additionalQuestions: {},
    eligibilities: {},
    optionIds: id ? { [id]: [] } : {},
    options: {},
    questionTypes: id ? { [id]: DEFAULT_QUESTION_TYPE } : {},
    customQuestionTypeIds: {},
    verifications: id ? { [id]: DEFAULT_VERIFICATION } : {},
    questionTitles: id ? { [id]: "" } : {},
    requirements: id ? { [id]: DEFAULT_REQUIREMENT } : {},
    permissionLevels: id ? { [id]: DEFAULT_PERMISSION_LEVEL } : {},
    questionLinks: id ? { [id]: DEFAULT_QUESTION_LINK } : {},
    validations: id ? { [id]: { options: "" } } : {},
    formTemplateFilters: id ? { [id]: [] } : {},
    dateConstraints: id ? { [id]: DEFAULT_DATE_CONSTRAINT } : {},
    numberConstraints: id ? { [id]: DEFAULT_NUMBER_CONSTRAINT } : {},
  };
}

export function toEmptyQuestionFormValues(id?: string): QuestionForm {
  return {
    id: id ?? "",
  };
}

export function toAdditionalQuestionsByOptionId(
  question: Draft.Question | Draft.NewQuestion
): AdditionalQuestionsByOptionId {
  if (!Question.hasOptions(question)) {
    return {};
  }

  return question.options.reduce(
    (record: AdditionalQuestionsByOptionId, option: AF.Option<AF.WithId>) => {
      const additionalQuestionIds: AdditionalQuestionsIds =
        option.additionalQuestions?.reduce(
          (
            additionalQuestionsList: AdditionalQuestionsIds,
            question: AF.Question<AF.WithId>
          ) => [...additionalQuestionsList, question.id],
          [] as AdditionalQuestionsIds
        ) ?? [];

      const hasAdditionalQuestions: boolean = additionalQuestionIds.length > 0;

      return {
        [option.id]: {
          type: hasAdditionalQuestions
            ? ("enabled" as const)
            : ("disabled" as const),
          questionIds: additionalQuestionIds,
        },
        ...record,
      };
    },
    {} as AdditionalQuestionsByOptionId
  );
}

export function toEligibilityByOptionId(
  parentQuestion: Draft.Question
): EligibilityByOptionId {
  const questions = [parentQuestion, ...getAdditionalQuestions(parentQuestion)];

  return Object.fromEntries(
    questions.flatMap((question) => {
      if (!Question.hasOptions(question)) {
        return [];
      }

      const bla: [uuid, Eligibility][] = question.options.flatMap(
        (option): [uuid, Eligibility][] => {
          switch (option.eligibilityFilter) {
            case "Eligible":
              return [
                [
                  option.id,
                  {
                    type: "includes",
                    schoolIds: option.eligibleSchoolIds,
                  },
                ],
              ];

            case "NotEligible":
              return [
                [
                  option.id,
                  {
                    type: "excludes",
                    schoolIds: option.notEligibleSchoolIds,
                  },
                ],
              ];

            case "NotApplicable":
            case undefined:
              return [];

            default:
              const _exhaustiveCheck: never = option;
              return _exhaustiveCheck;
          }
        }
      );

      return bla;
    })
  );
}

export function createQuestion(
  questionId: uuid,
  formValues: FormType,
  verificationOptions: AF.FormVerification<AF.WithId>[]
): Draft.NewQuestion {
  const question = {
    id: questionId,
    isDraft: true,
    isNew: true,
    key: formValues.questionKeys[questionId],
    question: formValues.questionTitles[questionId] ?? "",
  } as const;

  const questionType = getQuestionTypeFormValue(questionId, formValues);

  switch (questionType) {
    case AF.DateType:
      return {
        ...question,
        type: questionType,
        ...updateCategory(formValues),
        ...updateFormVerification(questionId, formValues, verificationOptions),
        ...updateLink(questionId, formValues),
        ...updatePermissionLevel(questionId, formValues),
        ...updateRequirement(questionId, formValues),
        ...updateDateConstraints(questionId, formValues, questionType),
      };
    case AF.NumberType:
      return {
        ...question,
        type: questionType,
        ...updateCategory(formValues),
        ...updateFormVerification(questionId, formValues, verificationOptions),
        ...updateLink(questionId, formValues),
        ...updatePermissionLevel(questionId, formValues),
        ...updateRequirement(questionId, formValues),
        ...updateNumberConstraints(questionId, formValues, questionType),
      };
    case AF.MultiSelectType:
    case AF.SingleSelectType:
      return {
        ...question,
        type: questionType,
        options: toNewDraftOptions(
          question.id,
          formValues,
          verificationOptions
        ),
        ...updateCategory(formValues),
        ...updateFormVerification(questionId, formValues, verificationOptions),
        ...updateLink(questionId, formValues),
        ...updatePermissionLevel(questionId, formValues),
        ...updateRequirement(questionId, formValues),
      };

    case AF.FreeTextType:
    case AF.FileUploadType:
    case AF.EmailType:
    case AF.PhoneNumberType:
      return {
        ...question,
        type: questionType,
        category: AF.GeneralCategoryType,
        ...updateFormVerification(questionId, formValues, verificationOptions),
        ...updateLink(questionId, formValues),
        ...updatePermissionLevel(questionId, formValues),
        ...updateRequirement(questionId, formValues),
      };

    case AF.GradesType:
      return {
        ...question,
        type: questionType,
        category: AF.GeneralCategoryType,
        ...updateLink(questionId, formValues),
        options: [],
        ...updateQuestionFilters(questionId, formValues),
      };

    case AF.AddressType:
      return {
        ...question,
        type: questionType,
        category: AF.GeneralCategoryType,
        ...updateFormVerification(questionId, formValues, verificationOptions),
        ...updateLink(questionId, formValues),
        ...updatePermissionLevel(questionId, formValues),
        ...updateRequirement(questionId, formValues),
        ...updateQuestionFilters(questionId, formValues),
      };
    case AF.CustomQuestionType:
      return {
        ...question,
        type: questionType,
        category: AF.GeneralCategoryType,
        ...updateLink(questionId, formValues),
        ...updatePermissionLevel(questionId, formValues),
        ...updateRequirement(questionId, formValues),
        nestedQuestions: [], // CQT-TODO, this is set on question creation
        customQuestionTypeId: getCustomQuestionTypeIdFormValue(
          questionId,
          formValues
        ),
      };
    default:
      const _exhaustiveCheck: never = questionType;
      return _exhaustiveCheck;
  }
}

export function updateQuestion(
  question: Draft.Question,
  formValues: FormType,
  verificationOptions: AF.FormVerification<AF.WithId>[]
): Draft.Question {
  const questionType = question.isNew
    ? getQuestionTypeFormValue(question.id, formValues)
    : question.type;

  const commonFields = {
    ...question,
    key: formValues.questionKeys[question.id],
    question: formValues.questionTitles[question.id] ?? question.question,
  };

  switch (questionType) {
    // TODO: clear and update
    case AF.DateType:
      return {
        ...commonFields,
        type: questionType,
        category: AF.GeneralCategoryType,
        ...updateFormVerification(question.id, formValues, verificationOptions),
        ...updateLink(question.id, formValues),
        ...updatePermissionLevel(question.id, formValues),
        ...updateRequirement(question.id, formValues),
        ...updateDateConstraints(question.id, formValues, questionType),
      };
    case AF.NumberType:
      return {
        ...commonFields,
        type: questionType,
        category: AF.GeneralCategoryType,
        ...updateFormVerification(question.id, formValues, verificationOptions),
        ...updateLink(question.id, formValues),
        ...updatePermissionLevel(question.id, formValues),
        ...updateRequirement(question.id, formValues),
        ...updateNumberConstraints(question.id, formValues, questionType),
      };
    case AF.SingleSelectType:
    case AF.MultiSelectType:
      return {
        ...commonFields,
        type: questionType,
        options: updateOptions(question, formValues, verificationOptions),
        ...updateCategory(formValues),
        ...updateFormVerification(question.id, formValues, verificationOptions),
        ...updateLink(question.id, formValues),
        ...updatePermissionLevel(question.id, formValues),
        ...updateRequirement(question.id, formValues),
      };

    case AF.FreeTextType:
    case AF.FileUploadType:
    case AF.EmailType:
    case AF.PhoneNumberType:
      return {
        ...commonFields,
        type: questionType,
        category: AF.GeneralCategoryType,
        ...updateFormVerification(question.id, formValues, verificationOptions),
        ...updateLink(question.id, formValues),
        ...updatePermissionLevel(question.id, formValues),
        ...updateRequirement(question.id, formValues),
      };

    case AF.GradesType:
      return {
        ...commonFields,
        type: questionType,
        category: AF.GeneralCategoryType,
        options: [],
        ...updateLink(question.id, formValues),
        ...updateQuestionFilters(question.id, formValues),
      };

    case AF.AddressType:
      return {
        ...commonFields,
        type: questionType,
        category: AF.GeneralCategoryType,
        ...updateFormVerification(question.id, formValues, verificationOptions),
        ...updateLink(question.id, formValues),
        ...updatePermissionLevel(question.id, formValues),
        ...updateRequirement(question.id, formValues),
        ...updateQuestionFilters(question.id, formValues),
      };
    case AF.CustomQuestionType:
      return {
        ...commonFields,
        type: questionType,
        category: AF.GeneralCategoryType,
        ...updateLink(question.id, formValues),
        ...updatePermissionLevel(question.id, formValues),
        ...updateRequirement(question.id, formValues),
        customQuestionTypeId: getCustomQuestionTypeIdFormValue(
          question.id,
          formValues
        ),
        nestedQuestions: [], // CQT-TODO
      };
    default:
      const _exhaustiveCheck: never = questionType;
      return _exhaustiveCheck;
  }
}

function toNewDraftOptions(
  questionId: uuid,
  formValues: FormType,
  verificationOptions: AF.FormVerification<AF.WithId>[]
): Draft.Option[] {
  const optionIds = getOptionIdsFormValue(questionId, formValues);
  const options = optionIds.map((optionId) => {
    const option = getOptionFormValue(optionId, formValues);
    const updatedOption = toDraftOption(
      formValues,
      optionId,
      option,
      verificationOptions
    );
    return {
      ...updatedOption,
    };
  });

  return options;
}

function toDraftOption(
  formValues: FormType,
  optionId: uuid,
  newOption: Option,
  verificationOptions: AF.FormVerification<AF.WithId>[]
): Draft.Option {
  const updatedOption: Draft.Option = {
    isDraft: true,
    isNew: newOption.isNew,
    id: optionId,
    label: newOption.label,
    value: newOption.value,
    ...newQuestionOptionEligibility(formValues, optionId),
  };

  return {
    ...updatedOption,
    ...toAdditionalQuestions(
      optionId,
      formValues,
      updatedOption,
      verificationOptions
    ),
  };
}

function newQuestionOptionEligibility(
  formValues: FormType,
  optionId: uuid
): AF.Eligible | AF.NotEligible | undefined {
  const eligibility = formValues.eligibilities?.[optionId];
  if (eligibility === undefined) return undefined;
  switch (eligibility.type) {
    case "disabled":
      return undefined;

    case "excludes":
      return {
        eligibilityFilter: "NotEligible",
        notEligibleSchoolIds: eligibility.schoolIds,
      };

    case "includes":
      return {
        eligibilityFilter: "Eligible",
        eligibleSchoolIds: eligibility.schoolIds,
      };

    default:
      const _exhaustiveCheck: never = eligibility;
      return _exhaustiveCheck;
  }
}

function updateCategory(formValues: FormType): { category: AF.Category } {
  if (_.isEmpty(formValues.eligibilities)) {
    return { category: "General" };
  }

  return { category: "Eligibility" };
}

function updateOptions(
  question: Draft.Question,
  formValues: FormType,
  verificationOptions: AF.FormVerification<AF.WithId>[]
): Draft.Option[] {
  if (!Question.hasOptions(question)) {
    return [];
  }

  const optionIds = getOptionIdsFormValue(question.id, formValues);
  if (optionIds === undefined) {
    return [];
  }

  return optionIds.map((optionId) => {
    const option = getOptionFormValue(optionId, formValues);
    const existingOption = question.options.find((o) => o.id === optionId);

    return {
      id: optionId,
      isNew: option.isNew,
      isDraft: true,
      label: option.label,
      value: option.value,
      ...(existingOption?.translate_options
        ? { translate_options: existingOption?.translate_options }
        : {}),
      ...toAdditionalQuestions(
        optionId,
        formValues,
        existingOption,
        verificationOptions
      ),
      ...updateEligibility(formValues, optionId),
    };
  });
}

function toAdditionalQuestions(
  optionId: uuid,
  formValues: FormType,
  existingOption: Draft.Option | undefined,
  verificationOptions: AF.FormVerification<AF.WithId>[]
): Pick<Draft.Option, "additionalQuestions"> {
  const additionalQuestions = formValues.additionalQuestions?.[optionId];
  if (
    additionalQuestions === undefined ||
    additionalQuestions.type === "disabled"
  )
    return {};
  return {
    additionalQuestions: additionalQuestions.questionIds.map(
      (questionId: uuid) => {
        const existingQuestion = existingOption?.additionalQuestions?.find(
          (q) => q.id === questionId
        );

        return existingQuestion
          ? updateQuestion(
              Draft.fromOriginalQuestion(existingQuestion),
              formValues,
              verificationOptions
            )
          : createQuestion(questionId, formValues, verificationOptions);
      }
    ),
  };
}

function updateQuestionFilters(
  questionId: uuid,
  formValues: FormType
): Pick<Draft.Question, "filters"> {
  return {
    filters: formValues.formTemplateFilters[questionId],
  };
}

function updateRequirement(
  questionId: uuid,
  formValues: FormType
): Pick<Draft.Question, "requirement"> {
  return { requirement: formValues.requirements[questionId] };
}

function updateFormVerification(
  questionId: uuid,
  formValues: FormType,
  verificationOptions: AF.FormVerification<AF.WithId>[]
):
  | Pick<AF.FormQuestion<AF.WithId>, "formVerification">
  | Pick<Draft.Question, "newFormVerification"> {
  const verificationForm = getVerificationFormValue(questionId, formValues);
  switch (verificationForm?.type) {
    case "disabled":
      return {
        formVerification: undefined,
        newFormVerification: undefined,
      };

    case "existing":
      const verificationId = verificationForm.id;
      const verification = verificationOptions?.find(
        (option) => option.id === verificationId
      );
      return {
        formVerification: verification,
        newFormVerification: undefined,
      };

    case "new":
      return {
        newFormVerification: { label: verificationForm.label },
        formVerification: undefined,
      };

    default:
      const _exhaustiveCheck: never = verificationForm;
      return _exhaustiveCheck;
  }
}

function updateEligibility(
  formValues: FormType,
  optionId: uuid
): AF.Eligibility {
  const eligibility = formValues.eligibilities?.[optionId];
  switch (eligibility?.type) {
    case "includes":
      return {
        eligibilityFilter: "Eligible",
        eligibleSchoolIds: eligibility.schoolIds,
      };

    case "excludes":
      return {
        eligibilityFilter: "NotEligible",
        notEligibleSchoolIds: eligibility.schoolIds,
      };

    case "disabled":
    case undefined:
      return {};

    default:
      const _exhaustiveCheck: never = eligibility;
      return _exhaustiveCheck;
  }
}

function updateLink(
  questionId: uuid,
  formValues: FormType
):
  | Pick<AF.FormQuestion<AF.WithId>, "link_text" | "link_url">
  | Pick<Draft.Question, "link_text" | "link_url"> {
  const questionLink = getQuestionLinkFormValue(questionId, formValues);

  if (questionLink.type === "checked") {
    return {
      link_text: questionLink.text,
      link_url: questionLink.url,
    };
  }

  return {
    link_text: undefined,
    link_url: undefined,
  };
}

function updateDateConstraints(
  questionId: uuid,
  formValues: FormType,
  questionType: AF.QuestionType
) {
  const dateConstraints = getDateConstraintFormValue(questionId, formValues);

  if (questionType === AF.DateType) {
    if (dateConstraints.switch === "checked") {
      return {
        constraints: DateTypeConstraintsSchema.parse(dateConstraints),
      };
    }
    return {
      constraints: {
        format: dateConstraints.format,
        startDate: null,
        endDate: null,
      },
    };
  }

  return {
    constraints: null,
  };
}

function updateNumberConstraints(
  questionId: uuid,
  formValues: FormType,
  questionType: AF.QuestionType
) {
  const numberConstraints = getNumberConstraintFormValue(
    questionId,
    formValues
  );

  if (questionType === AF.NumberType) {
    if (numberConstraints.switch === "checked") {
      return {
        constraints: NumberTypeConstraintsSchema.parse(numberConstraints),
      };
    }
    return {
      constraints: {
        format: numberConstraints.format,
        precision: numberConstraints.precision,
        minValue: null,
        maxValue: null,
      } satisfies NumberTypeConstraints,
    };
  }
  return {
    constraints: null,
  };
}

function updatePermissionLevel(
  questionId: uuid,
  formValues: FormType
): Pick<Draft.Question, "permissionLevel"> {
  const permissionLevel = getPermissionLevelFormValue(questionId, formValues);
  switch (permissionLevel.type) {
    case "disabled":
      return { permissionLevel: undefined };

    case "checked":
      return { permissionLevel: permissionLevel.level };

    default:
      const _exhaustiveCheck: never = permissionLevel;
      return _exhaustiveCheck;
  }
}

export function isInvalid({
  touched,
  error,
}: {
  touched: boolean | undefined;
  error: string | undefined;
}): boolean {
  return touched === true && typeof error === "string";
}

export function getAllQuestionIds(values: FormType): readonly uuid[] {
  return [
    values.id,
    ...Object.values(values.additionalQuestions).flatMap((value) => {
      if (value.type === "disabled") {
        return [];
      }

      return value.questionIds;
    }),
  ];
}

export function getAdditionalQuestionFormValue(
  optionId: uuid,
  formValues: Pick<FormType, "additionalQuestions">
): AdditionalQuestions {
  return (
    formValues.additionalQuestions[optionId] ?? DEFAULT_ADDITIONAL_QUESTIONS
  );
}

export function getQuestionTypeFormValue(
  questionId: uuid,
  formValues: Pick<FormType, "questionTypes">
): AF.QuestionType {
  return formValues.questionTypes[questionId] ?? DEFAULT_QUESTION_TYPE;
}

export function getCustomQuestionTypeIdFormValue(
  questionId: uuid,
  formValues: Pick<FormType, "customQuestionTypeIds">
): uuid {
  return formValues.customQuestionTypeIds[questionId] ?? "";
}

export function getVerificationFormValue(
  questionId: uuid,
  formValues: Pick<FormType, "verifications">
): Verification {
  return formValues.verifications[questionId] ?? DEFAULT_VERIFICATION;
}

export function getEligibilityFormValue(
  optionId: uuid,
  formValues: FormType
): Eligibility {
  return formValues.eligibilities[optionId] ?? DEFAULT_ELIGIBILITY;
}

export function getRequirementFormValue(
  questionId: uuid,
  formValues: FormType
): Requirement {
  return formValues.requirements[questionId] ?? DEFAULT_REQUIREMENT;
}

export function getDateConstraintFormValue(
  questionId: uuid,
  formValues: FormType
): DateConstraints {
  return formValues.dateConstraints[questionId] ?? DEFAULT_DATE_CONSTRAINT;
}

export function getNumberConstraintFormValue(
  questionId: uuid,
  formValues: FormType
): NumberConstraints {
  return formValues.numberConstraints[questionId] ?? DEFAULT_NUMBER_CONSTRAINT;
}

export function getPermissionLevelFormValue(
  questionId: uuid,
  formValues: FormType
): PermissionLevel {
  return formValues.permissionLevels[questionId] ?? DEFAULT_PERMISSION_LEVEL;
}

export function getQuestionLinkFormValue(
  questionId: uuid,
  formValues: FormType
): QuestionLink {
  return formValues.questionLinks[questionId] ?? DEFAULT_QUESTION_LINK;
}

export function getAllOptionsFormValue(
  questionId: uuid,
  formValues: FormType
): Option[] {
  const optionIds = getOptionIdsFormValue(questionId, formValues);
  return optionIds.map((optionId) => {
    return getOptionFormValue(optionId, formValues);
  });
}

export function getOptionFormValue(
  optionId: uuid,
  formValues: FormType
): Option {
  return formValues.options[optionId] ?? DEFAULT_OPTION;
}

export function toOptionDraft(
  optionId: uuid,
  formValues: FormType,
  draft: Draft.Option | undefined
) {
  const optionFormValue = getOptionFormValue(optionId, formValues);
  const draftOption: Draft.Option = _.merge(
    { id: optionId },
    draft,
    optionFormValue
  );
  return draftOption;
}

export function getOptionIdsFormValue(
  questionId: uuid,
  formValues: FormType
): uuid[] {
  return formValues.optionIds[questionId] ?? [];
}

export function getBirthdateFilterFormValue(
  questionId: uuid,
  formValues: FormType
): BirthdateEligibilityFilter[] {
  const birthdateFilter = formValues.formTemplateFilters[questionId]?.filter(
    (formFilter): formFilter is BirthdateEligibilityFilter => {
      return (
        formFilter.type === EligibleFilterType.BirthdateAfterFilter ||
        formFilter.type === EligibleFilterType.BirthdateBeforeFilter ||
        formFilter.type === EligibleFilterType.BirthdateBetweenFilter
      );
    }
  );
  return birthdateFilter ?? [];
}

export function removeQuestionFormValues(
  values: FormType,
  ...questionIds: uuid[]
): FormType {
  return {
    ...values,
    questionTitles: _.omit(values.questionTitles, questionIds),
    questionTypes: _.omit(values.questionTypes, questionIds),
    verifications: _.omit(values.verifications, questionIds),
    requirements: _.omit(values.requirements, questionIds),
    permissionLevels: _.omit(values.permissionLevels, questionIds),
    questionLinks: _.omit(values.questionLinks, questionIds),
  };
}

export function isLocationBoundariesFilter(
  filter: FormTemplateFilter
): filter is LocationBoundariesFilter {
  return filter.type === EligibleFilterType.LocationBoundariesFilter;
}

export function getLocationBoundariesFilterFormValue(
  questionId: uuid,
  formValues: FormType
): LocationBoundariesFilter[] {
  return (
    formValues.formTemplateFilters[questionId]?.filter(
      isLocationBoundariesFilter
    ) ?? []
  );
}
