import { ApolloError } from "@apollo/client";
import React, { useContext, useMemo } from "react";
import { NotFound } from "src/components/Feedback/NotFound";
import { Step as StepType } from "src/components/Layout/FormStepLayout";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import {
  getMissingRequiredQuestions,
  isSectionApplicableForEdit,
} from "src/services/formTemplate/section";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import { setCurrentQuestionId } from "./state/actions";
import { EditFormContext } from "./state/provider";
import { StepDisclaimerSection } from "./StepDisclaimerSection";
import { StepGeneralSection } from "./StepGeneralSection";
import { StepPreRanking } from "./StepPreRanking";
import { StepRankSchools } from "./StepRankSchools";

type Props = {
  answers: GQL.GetFormAnswersById;
  currentStep: number;
  refetchSchoolRanks: () => Promise<unknown>;
  schoolRanksRemoteData: RemoteData<ApolloError, GQL.GetSchoolsRank>;
  previousFormSchoolIds: uuid[];
  form: GQL.FormFragment;
  sections: AF.Sections<AF.WithId>;
  formId: uuid;
  formTemplateId: string;
  applicant: AFF.Types.Applicant;
  verificationResults: GQL.FormFragment_form_verification_results[];
  headers?: React.ReactNode;
  hasBeenSubmittedBefore: boolean | null;
};
export const Step: React.FC<Props> = ({
  answers,
  refetchSchoolRanks,
  schoolRanksRemoteData,
  previousFormSchoolIds,
  form,
  sections,
  formTemplateId,
  verificationResults,
  hasBeenSubmittedBefore,
  ...props
}) => {
  const { dispatch } = useContext(EditFormContext);

  const rankedSchoolIds = useMemo(() => {
    if (!schoolRanksRemoteData.hasData()) {
      return [];
    }

    return schoolRanksRemoteData.data.form_school_rank.map(
      (rankedSchool) => rankedSchool.school.id
    );
  }, [schoolRanksRemoteData]);
  const flags = useFlags(["family-editable-statuses"]);

  const applicableSections = useMemo<AF.Sections<AF.WithId>>(() => {
    if (!schoolRanksRemoteData.hasData()) {
      /*
       * Since the remote data is resolved in the parent component, this should
       * never be reached.
       */
      return sections;
    }

    return sections.filter((section) => {
      return isSectionApplicableForEdit(
        form.status,
        section,
        answers.form_answer,
        answers.grades_answer,
        answers.form_address,
        answers.custom_question_answer,
        { rankedSchoolIds, previousFormSchoolIds },
        flags["family-editable-statuses"]?.enabled ?? false
      );
    }) as AF.Sections<AF.WithId>;
  }, [
    form.status,
    answers,
    schoolRanksRemoteData,
    sections,
    rankedSchoolIds,
    previousFormSchoolIds,
    flags,
  ]);

  const section = applicableSections[props.currentStep - 1];
  if (!section) return <NotFound />;

  const steps: StepType[] = applicableSections.flatMap((s) => {
    if (s === undefined) {
      return [];
    }

    return [
      {
        id: s.id,
        title: s.title,
        initialMissingRequiredQuestions: getMissingRequiredQuestions(
          s,
          answers.form_answer,
          answers.grades_answer,
          answers.form_address,
          answers.custom_question_answer,
          answers.form_disclaimer,
          { rankedSchoolIds, previousFormSchoolIds }
        ),
      },
    ];
  });

  const onChangeFormQuestion = (questionId: string) => {
    dispatch(setCurrentQuestionId(questionId));
  };

  switch (section.type) {
    case AF.PreRankingSectionType:
      return (
        <StepPreRanking
          key={section.id}
          answers={answers}
          refetchSchoolRanks={refetchSchoolRanks}
          schoolRanksRemoteData={schoolRanksRemoteData}
          section={section}
          allSections={applicableSections}
          steps={steps}
          verificationResults={verificationResults}
          hasBeenSubmittedBefore={hasBeenSubmittedBefore}
          previousFormSchoolIds={previousFormSchoolIds}
          formTemplateId={formTemplateId}
          onChangeFormQuestion={onChangeFormQuestion}
          {...props}
        />
      );
    case AF.SchoolRankingSectionType:
      return (
        <StepRankSchools
          key={section.id}
          formTemplateId={formTemplateId}
          enrollmentPeriodId={form.form_template.enrollment_period_id}
          section={section}
          allSections={applicableSections}
          steps={steps}
          hasBeenSubmittedBefore={hasBeenSubmittedBefore}
          refetchSchoolRanks={refetchSchoolRanks}
          schoolRanksRemoteData={schoolRanksRemoteData}
          {...props}
        />
      );
    case AF.GeneralSectionType:
      return (
        <StepGeneralSection
          key={section.id} // using `key` is important here to prevent react from reusing this component for different sections
          answers={answers}
          schoolRanksRemoteData={schoolRanksRemoteData}
          previousFormSchoolIds={previousFormSchoolIds}
          section={section}
          steps={steps}
          verificationResults={verificationResults}
          hasBeenSubmittedBefore={hasBeenSubmittedBefore}
          onChangeFormQuestion={onChangeFormQuestion}
          {...props}
        />
      );
    case AF.DisclaimerSectionType:
      return (
        <StepDisclaimerSection
          key={section.id}
          section={section}
          steps={steps}
          hasBeenSubmittedBefore={hasBeenSubmittedBefore}
          {...props}
        />
      );
  }
};
