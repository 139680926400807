import {
  As,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { AnswerBankRecordField } from "../list/schemas/AnswerBankField";
import { DataLabel } from "src/components/Inputs/DataLabel";
import { CustomQuestionTypeFieldTypes } from "src/schemas/CustomQuestionType";
import { PhoneNumberType } from "src/types/formTemplate";
import { formatPhoneNumber } from "react-phone-number-input";

type UpdateOutdatedAnswerDialogProps = {
  bankAnswerFields: AnswerBankRecordField[];
  formAnswerFields: AnswerBankRecordField[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const UpdateOutdatedAnswerDialog: FunctionComponent<
  UpdateOutdatedAnswerDialogProps
> = (props) => {
  const { bankAnswerFields, formAnswerFields, isOpen, onClose, onSubmit } =
    props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update information?</ModalHeader>
        <ModalBody as={Flex} direction="column" gap={4}>
          <Text>
            This answer was edited on a different form, you can update it now
            with the latest or edit it again anytime:
          </Text>
          <AnswerList
            outdatedAnswers={formAnswerFields}
            updatedAnswers={bankAnswerFields}
          />
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="ghost" colorScheme="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type AnswerListProps = {
  outdatedAnswers: AnswerBankRecordField[];
  updatedAnswers: AnswerBankRecordField[];
};
const AnswerList: FunctionComponent<AnswerListProps> = (props) => {
  const { outdatedAnswers, updatedAnswers } = props;
  if (outdatedAnswers.length !== updatedAnswers.length) {
    throw new Error(
      "Outdated answer and updated answer do not have the same number of fields"
    );
  }
  return (
    <Flex direction="column" gap={1}>
      {outdatedAnswers.map((outdatedAnswer) => {
        const updatedAnswer = updatedAnswers.find(
          (answer) =>
            answer.clonedQuestionId === outdatedAnswer.clonedQuestionId
        );
        if (!updatedAnswer) {
          throw new Error(
            `Updated answer not found for question id ${outdatedAnswer.clonedQuestionId}`
          );
        }
        return (
          <AnswerBankFieldText
            key={outdatedAnswer.clonedQuestionId}
            outdatedAnswerField={outdatedAnswer}
            updatedAnswerField={updatedAnswer}
          />
        );
      })}
    </Flex>
  );
};

enum AnswerTypes {
  Outdated = "outdated",
  Updated = "updated",
  NoChange = "no-change",
}

/*
The below is very similar to AnswerBankRadio.RadioOptionText
*/
type AnswerBankFieldTextProps = {
  outdatedAnswerField: AnswerBankRecordField;
  updatedAnswerField: AnswerBankRecordField;
};
const AnswerBankFieldText: FunctionComponent<AnswerBankFieldTextProps> = (
  props
) => {
  const { outdatedAnswerField, updatedAnswerField } = props;
  const { questionLabel, questionType } = outdatedAnswerField;

  if (updatedAnswerField.questionLabel !== questionLabel) {
    throw new Error(
      "Outdated answer and updated answer do not belong to the same field - different question labels"
    );
  }

  const labelProps = { color: "blackAlpha.700" };
  let outdatedAnswer, updatedAnswer;
  switch (questionType) {
    case "FreeText":
    case "Email":
    case "PhoneNumber":
    case "Date":
    case "Number":
      // TODO: handle date and number type
      if (updatedAnswerField.questionType !== questionType) {
        throw new Error(
          "Outdated answer and updated answer do not belong to the same field - different question types"
        );
      }
      outdatedAnswer = outdatedAnswerField.answer;
      updatedAnswer = updatedAnswerField.answer;
      break;
    case "SingleSelect":
      if (updatedAnswerField.questionType !== questionType) {
        throw new Error(
          "Outdated answer and updated answer do not belong to the same field - different question types"
        );
      }
      outdatedAnswer = outdatedAnswerField.clonedQuestionSelectOptionLabel;
      updatedAnswer = updatedAnswerField.clonedQuestionSelectOptionLabel;
      break;
    default:
      const _exhaustiveCheck: never = questionType;
      return _exhaustiveCheck;
  }

  return (
    <Flex direction="column">
      <DataLabel {...labelProps}>{questionLabel}</DataLabel>
      {outdatedAnswer !== updatedAnswer ? (
        <Stack direction="row">
          {processAnswer(outdatedAnswer, questionType, AnswerTypes.Outdated)}
          {processAnswer(updatedAnswer, questionType, AnswerTypes.Updated)}
        </Stack>
      ) : (
        processAnswer(outdatedAnswer, questionType, AnswerTypes.NoChange)
      )}
    </Flex>
  );
};

const answerStyles = {
  [AnswerTypes.Outdated]: {
    as: "s" as As,
    color: "blackAlpha.400",
    fontWeight: "bold",
  },
  [AnswerTypes.Updated]: {
    fontWeight: "bold",
  },
  [AnswerTypes.NoChange]: {},
};
function processAnswer(
  answer: string | undefined,
  questionType: CustomQuestionTypeFieldTypes,
  answerType: AnswerTypes
) {
  const style = answerStyles[answerType];

  // Empty answer case
  if (!answer || answer.length === 0) {
    switch (answerType) {
      case AnswerTypes.Outdated:
        return (
          <Text {...style} fontStyle="italic">
            No answer
          </Text>
        );
      case AnswerTypes.Updated:
        return <></>;
      case AnswerTypes.NoChange:
        return <Text variant="placeholder">No answer</Text>;
    }
  }

  // Non-empty answer case
  return (
    <Text {...style}>
      {questionType === PhoneNumberType ? formatPhoneNumber(answer) : answer}
    </Text>
  );
}
