import { ApolloError } from "@apollo/client";
import { Form, Formik } from "formik";
import React from "react";
import {
  FormStepLayout,
  StepProps,
} from "src/components/Layout/FormStepLayout";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useFormSteps } from "src/hooks/useFormSteps";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useRankedSchools from "src/hooks/useSchoolRank";
import { getContext, saveContext } from "src/scenes/parent/state/localStorage";
import { useEligibilityService } from "src/services/eligibility/useEligibilityService";
import { Answer, Question } from "src/services/formTemplate";
import { getAllNotEligibleSchools } from "src/services/formTemplate/preRankingSection";
import {
  getAllEligibilityQuestions,
  getGradesAnswer,
} from "src/services/formTemplate/preRankingSection/determineEligibility";
import * as AFF from "src/services/formTemplateFilters";
import { validateFormTemplate } from "src/services/formTemplateValidations";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RemoteData from "src/types/remoteData";
import { QuestionList } from "./QuestionList";
import { ExploreSavedSchoolsDialog } from "./components/Dialogs/ExploreSavedSchools";
import { FormButtons } from "./components/Layout/FormButtons";
import { INSERT_FORM_SCHOOLS_RANK } from "./graphql/mutations";
import { useAutosaveReducer } from "./hooks/useAutosaveReducer";
import { useEligibilityAnswersChange } from "./hooks/useEligibilityAnswersChange";
import { rankedSchoolsVar } from "./store";

function getMaxSchools(
  allSections: AF.Sections<AF.WithId>
): number | undefined {
  const section = allSections.find(
    (item) => item?.type === AF.SchoolRankingSectionType
  );
  return section
    ? (section as AF.SchoolRankingSection<AF.WithId>).maxSchools
    : undefined;
}

type Props = {
  formTemplateId: string;
  answers: GQL.GetFormAnswersById;
  formId: uuid;
  applicant: AFF.Types.Applicant;
  refetchSchoolRanks: () => Promise<unknown>;
  previousFormSchoolIds: uuid[];
  schoolRanksRemoteData: RemoteData.RemoteData<ApolloError, GQL.GetSchoolsRank>;
  section: AF.PreRankingSection<AF.WithId>;
  allSections: AF.Sections<AF.WithId>;
  verificationResults: GQL.FormFragment_form_verification_results[];
  hasBeenSubmittedBefore: boolean | null;
  onChangeFormQuestion?: (questionId: string) => void;
} & StepProps;

export const StepPreRanking: React.FC<Props> = (props) => {
  const {
    formTemplateId,
    answers,
    formId,
    applicant,
    refetchSchoolRanks,
    previousFormSchoolIds,
    schoolRanksRemoteData,
    section,
    allSections,
    verificationResults,
    hasBeenSubmittedBefore,
    onChangeFormQuestion,
    ...stepProps
  } = props;
  const { glossary } = useGlossary();
  const { confirm, confirmationDialog } = useConfirmationDialog({
    header: glossary`Edit family/student information`,
    body: null,
    cancelButton: { label: "No, cancel" },
    confirmButton: { label: "Yes, continue" },
    translate: true,
  });

  const schoolRankingSection = allSections.find(
    (section): section is AF.SchoolRankingSection<AF.WithId> =>
      section?.type === "SchoolRankingSection"
  ) as AF.SchoolRankingSection<AF.WithId>;

  const rankingEnabled = schoolRankingSection?.rankingEnabled ?? false;

  const [
    insertSchoolRank,
    {
      remoteData: insertSchoolRanksRemoteData,
      reset: resetInsertSchoolRanksRemoteData,
    },
  ] = useRemoteDataMutation<
    GQL.InsertFormSchoolsRank,
    GQL.InsertFormSchoolsRankVariables
  >(INSERT_FORM_SCHOOLS_RANK);

  const { getDeletedRanks, getDeletedOffers, getDeletedWaitlists } =
    useRankedSchools([]);

  const flags = useFlags(["eligibility-service"]);

  const toast = useAvelaToast();
  React.useEffect(() => {
    if (
      insertSchoolRanksRemoteData.kind === RemoteData.RemoteDataKind.Success
    ) {
      resetInsertSchoolRanksRemoteData();
    }
  }, [insertSchoolRanksRemoteData, resetInsertSchoolRanksRemoteData, toast]);

  const { autosaveStatus, onAutosave } = useAutosaveReducer();

  const eligibilityService = useEligibilityService({ formTemplateId });

  const handleExploreSavedSchools = React.useCallback(
    async (answers: Answer.FormikValues): Promise<boolean> => {
      const ctx = getContext();
      if (!ctx) return true;
      if (!ctx.exploreSavedSchools.length) return true;
      if (
        schoolRanksRemoteData.hasData() &&
        schoolRanksRemoteData.data.form_school_rank.length > 0
      ) {
        // already have school selection, don't overwrite the existing selection with saved schools from explore
        return true;
      }

      setIsLoading(true);
      const rankedSchools = rankedSchoolsVar();

      const selectedGradeConfigID =
        getGradesAnswer(section.questions, answers) ?? "";

      let ineligibleSchoolIDs: readonly string[] = [];
      try {
        const eligibilityQuestions = getAllEligibilityQuestions(
          section.questions
        );
        if (flags["eligibility-service"].enabled) {
          ineligibleSchoolIDs =
            await eligibilityService.getIneligibleSchoolIdsByAnswers({
              answers,
              dateOfBirth: applicant.birth_date ?? "", // applicant's birth date is required
            });
        } else {
          ineligibleSchoolIDs = getAllNotEligibleSchools(
            eligibilityQuestions,
            answers
          );
        }
      } catch (err) {
        setIsLoading(false);
        console.error(err);
        toast.error({ title: "Unable to check eligibility" });
        return false;
      }

      const allEligibleSchools = ctx.exploreSavedSchools
        .filter((item) => !ineligibleSchoolIDs.includes(item.id))
        .filter(
          (item) =>
            item.gradeConfigIDs.includes(selectedGradeConfigID) ||
            selectedGradeConfigID === ""
        );

      const ineligibleSchools = ctx.exploreSavedSchools.filter(
        (item) => !allEligibleSchools.map((item) => item.id).includes(item.id)
      );

      const maxSchools = getMaxSchools(allSections) ?? 0;
      const remainingSchoolCount = maxSchools - rankedSchools.length;

      const eligibleSchools = maxSchools
        ? allEligibleSchools.slice(0, remainingSchoolCount)
        : allEligibleSchools;
      const removedSchools = maxSchools
        ? allEligibleSchools.slice(maxSchools, undefined)
        : [];

      const cancelLabel =
        !eligibleSchools.length && ineligibleSchools.length ? "Cancel" : "No";
      const confirmLabel =
        !eligibleSchools.length && ineligibleSchools.length
          ? "Continue"
          : glossary`Yes, add schools`;
      const isConfirmed = await confirm({
        header: glossary`School choices`,
        body: (
          <ExploreSavedSchoolsDialog
            eligibleSchools={eligibleSchools}
            ineligibleSchools={ineligibleSchools}
            removedSchools={removedSchools}
            numUnresolvedSchools={ctx.unresolvedReferenceIDs.length}
          ></ExploreSavedSchoolsDialog>
        ),
        cancelButton: { label: cancelLabel },
        confirmButton: { label: confirmLabel },
      });

      if (isConfirmed) {
        try {
          const upsertedSchoolRanks = eligibleSchools.map((school, index) => {
            return {
              form_id: formId,
              schools_ranking_section_id: schoolRankingSection?.id,
              school_id: school.id,
              rank: index,
            };
          });
          const deletedSchoolRanks = getDeletedRanks(upsertedSchoolRanks);
          const deletedOffers = getDeletedOffers(upsertedSchoolRanks);
          const deletedWaitlists = getDeletedWaitlists(upsertedSchoolRanks);
          await insertSchoolRank({
            variables: {
              deleted_school_ranks: deletedSchoolRanks,
              delete_offers_where: deletedOffers,
              delete_waitlists_where: deletedWaitlists,
              upserted_school_ranks: upsertedSchoolRanks,
            },
          });
          saveContext(null);
        } catch (e) {
          console.error(e);
          toast.error({
            title: "Something went wrong!",
            description: "Check your network and try again",
          });
        }
      }

      return isConfirmed;
    },
    [
      schoolRanksRemoteData,
      section.questions,
      allSections,
      glossary,
      confirm,
      flags,
      eligibilityService,
      applicant.birth_date,
      toast,
      getDeletedRanks,
      getDeletedOffers,
      getDeletedWaitlists,
      insertSchoolRank,
      formId,
      schoolRankingSection?.id,
    ]
  );
  const { onNext } = useFormSteps(stepProps);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleSubmit = async (answers: Answer.FormikValues) => {
    if (autosaveStatus === "Saving") {
      return;
    }

    await handleExploreSavedSchools(answers);
    onNext();
  };

  const initialValues = Answer.getFormikInitialValues(
    section.questions,
    answers.form_answer,
    answers.grades_answer,
    answers.form_address,
    answers.custom_question_answer
  );

  const eligibilityChange = useEligibilityAnswersChange({
    formId,
    formTemplateId,
    refetchSchoolRanks,
    schoolRankingSection,
    schoolRanksRemoteData,
    rankingEnabled,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateFormTemplate(section.questions, {
        rankedSchoolIds: [],
        previousFormSchoolIds,
      })}
      validateOnBlur={true}
      validateOnMount={false} // need to disable this since it's causing weird validation behavior since we introduce refetchAnswer. See: https://app.asana.com/0/0/1203247877978830/1203832069318649/f
      // enableReinitialize
    >
      {(formikProps) => {
        const completeQuestions = Question.getCompleteQuestions(
          section.questions
        );
        const applicableQuestions = Question.groupByVerifications(
          Question.getCompleteApplicableQuestions(
            section.questions,
            formikProps.values,
            { rankedSchoolIds: [], previousFormSchoolIds }
          )
        );

        return (
          <FormStepLayout
            noValidate
            as={Form}
            title={section.title}
            description={section.description}
            buttons={(buttonsProps) => (
              <FormButtons
                saveStatus={autosaveStatus}
                {...buttonsProps}
                overridePreviousButton={{
                  hide: true,
                }}
                overrideNextButton={{
                  disabled: !formikProps.isValid,
                  isLoading,
                  action: () => {
                    // Do nothing here, and instead, next button will trigger form submission
                  },
                }}
                hasBeenSubmittedBefore={hasBeenSubmittedBefore}
              />
            )}
            {...stepProps}
            content={
              <>
                <QuestionList
                  formId={formId}
                  applicant={applicant}
                  completeQuestions={completeQuestions}
                  applicableQuestions={applicableQuestions}
                  formikProps={formikProps}
                  verificationResults={verificationResults}
                  confirmGradesChange={eligibilityChange.confirmGrades}
                  confirmEligibilityChange={
                    eligibilityChange.confirmEligibilityQuestion
                  }
                  confirmAddressChange={eligibilityChange.confirmAddress}
                  onAutosave={onAutosave}
                  onChangeFormQuestion={onChangeFormQuestion}
                />
                {confirmationDialog}
                {eligibilityChange.dialog}
              </>
            }
          />
        );
      }}
    </Formik>
  );
};
