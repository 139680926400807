import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useField } from "formik";
import { isEmpty } from "lodash";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { Card } from "src/components/Layout/Card";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useUser from "src/hooks/useUser";
import {
  AddressBookAnswer,
  CURRENT_ADDRESS,
  EXISTING_ADDRESS,
  ExistingAddress,
  NO_ADDRESS,
} from "src/schemas/Address";
import { Status } from "src/types/authData";
import { GetUserAddresses, GetUserAddressesVariables } from "src/types/graphql";
import {
  getRecentUserAddresses,
  isAddressBlank,
  onAddressChange,
} from "./helpers";
import { AddressBookLabel } from "./modules/AddressBookLabel";
import { AddressRadioOption } from "./modules/AddressRadioOption";
import { ValidateNewAddressForm } from "./modules/ValidateNewAddressForm";
import { GET_USER_ADDRESSES } from "./queries";

export type FormikAddressBookProps = {
  fieldName: string;
  addressIsRequired?: boolean;
  showStandardLabel?: boolean;
  disableAddressBook?: boolean;
};

/**
 * A address selection and creation component with rich interactions:
 *  1. Creates new user_address
 *  2. Auto-selects the radio option for the initial address
 *  3. Loads the 3 recently used user_addresses
 *
 * Uses a mix of static and dynamic radio ids:
 * - static: the "currently selected address"
 * - dynamic: user_address options from database
 */
export const FormikAddressBook: FunctionComponent<FormikAddressBookProps> = (
  props
) => {
  const {
    fieldName,
    addressIsRequired,
    showStandardLabel = true,
    disableAddressBook = false,
  } = props;

  const [{ value: addressValue }, { error, touched, initialValue }, helpers] =
    useField<AddressBookAnswer | undefined>(fieldName);
  const [showAddressValidationForm, setShowAddressValidationForm] =
    useState(false);
  const user = useUser();

  const { remoteData } = useRemoteDataQuery<
    GetUserAddresses,
    GetUserAddressesVariables
  >(GET_USER_ADDRESSES, {
    variables: { user_id: user.status === Status.OK ? user.data.id : "" },
    skip: disableAddressBook,
  });

  const recentUserAddresses = useMemo(() => {
    return getRecentUserAddresses(initialValue, remoteData);
  }, [initialValue, remoteData]);

  const userId = user.status !== Status.OK ? undefined : user.data.id;

  const handleOnAddressChange = useCallback(
    (selectedAddressKey: string) => {
      onAddressChange({
        selectedAddressKey,
        recentUserAddresses,
        userId,
        helpers,
      });
    },
    [helpers, recentUserAddresses, userId]
  );

  const handleAfterUpsertNewAddress = useCallback(
    async (address: ExistingAddress) => {
      helpers.setTouched(true, false);
      helpers.setValue(address);
      setShowAddressValidationForm(false);
    },
    [helpers]
  );

  const radioValue = useMemo(() => {
    if (!addressValue) {
      return NO_ADDRESS;
    }
    const { kind } = addressValue;
    if (kind === EXISTING_ADDRESS) {
      return addressValue.user_address_id;
    }

    if (kind === CURRENT_ADDRESS || kind === NO_ADDRESS) {
      return kind;
    }

    // handle initial values
    const initialAddressIsBlank = isAddressBlank(initialValue);
    return initialAddressIsBlank ? NO_ADDRESS : CURRENT_ADDRESS;
  }, [initialValue, addressValue]);

  if (!userId) {
    return null;
  }

  const hasExistingAddresses = recentUserAddresses.length > 0;

  if (showAddressValidationForm) {
    return (
      <Flex direction="column" gap={2}>
        {showStandardLabel && (
          <AddressBookLabel id={fieldName} label="Address" />
        )}
        <ValidateNewAddressForm
          userId={userId}
          onAfterSubmit={handleAfterUpsertNewAddress}
          addressIsRequired={addressIsRequired}
        />
        {hasExistingAddresses && (
          <Button
            size="sm"
            variant="outline"
            colorScheme="gray"
            width="100%"
            onClick={() => setShowAddressValidationForm(false)}
          >
            Choose existing address
          </Button>
        )}
      </Flex>
    );
  }

  const showValidationErrors = !isEmpty(error) && touched;
  return (
    <FormControl isInvalid={showValidationErrors}>
      <Flex direction="column" gap={2}>
        {showStandardLabel && (
          <AddressBookLabel id={fieldName} label="Address" />
        )}
        <RadioGroup onChange={handleOnAddressChange} value={radioValue}>
          <Flex direction="column" gap={2}>
            {recentUserAddresses.map((recentAddress) => {
              return (
                <AddressRadioOption
                  isInvalid={touched ? undefined : false}
                  key={
                    recentAddress.kind === EXISTING_ADDRESS
                      ? recentAddress.user_address_id
                      : recentAddress.kind
                  }
                  {...recentAddress}
                />
              );
            })}
            {!addressIsRequired && (
              <Card showBorder padding={4}>
                <Radio
                  value={NO_ADDRESS}
                  isInvalid={false} // disable red ring
                >
                  Do not enter an address
                </Radio>
              </Card>
            )}
          </Flex>
        </RadioGroup>
        <Button
          size="sm"
          variant="outline"
          colorScheme="gray"
          width="100%"
          onClick={() => setShowAddressValidationForm(true)}
        >
          Add new address
        </Button>
      </Flex>
      {showValidationErrors && (
        <FormErrorMessage>An address is required.</FormErrorMessage>
      )}
    </FormControl>
  );
};
