import { ApolloError, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import * as GQL from "../types/graphql";
import * as RD from "src/types/remoteData";
import * as RemoteData from "../types/remoteData";
import { useRemoteDataQuery } from "./useRemoteDataQuery";
import * as OrgConfig from "@avela/organization-config-sdk";
import { useOrgConfig } from "./useOrgConfig";

export function useContactMethods(): RD.RemoteData<
  unknown,
  OrgConfig.Contacts.ContactMethod[]
> {
  return useOrgConfig("Contacts").map((config) => {
    // If it's disabled config, return empty array
    if (config.disabled) {
      return [];
    }

    // Otherwise, return just the contacts array
    return config.contacts;
  });
}

export const ORGANIZATION_FRAGMENT = gql`
  fragment Organization on organization {
    id
    name
    path
    timezone_name
  }
`;

export const GET_ORGANIZATION_ID = gql`
  ${ORGANIZATION_FRAGMENT}
  query GetOrganizationId($path: String!) {
    organization(where: { path: { _eq: $path } }, limit: 1) {
      ...Organization
    }
  }
`;

export type UseOrganizationType = RemoteData.RemoteData<
  OrganizationError,
  GQL.Organization
>;

export type OrganizationError = ServerError | NotFoundError;
export class ServerError {
  readonly kind = "ServerError";
  constructor(public error: ApolloError) {}
}
export class NotFoundError {
  readonly kind = "NotFoundError";
}

export function isOrganizationError(
  error: unknown
): error is OrganizationError {
  return error instanceof ServerError || error instanceof NotFoundError;
}

export function useOrganization(): UseOrganizationType {
  const { organization } = useParams();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetOrganizationId,
    GQL.GetOrganizationIdVariables
  >(GET_ORGANIZATION_ID, {
    variables: { path: organization ?? "" },
    skip: organization === undefined,
  });

  return remoteData
    .mapError<OrganizationError>((error) => new ServerError(error))
    .andThen((data) => {
      const organizationData = data.organization[0];
      if (organizationData === undefined)
        return RemoteData.failure(new NotFoundError());

      return RemoteData.success(organizationData);
    });
}

export function useTimezoneName(): string {
  const organization = useOrganization();
  const timezoneName: string = organization
    .map((o) => o.timezone_name)
    .withDefault("+00:00");
  return timezoneName;
}

// Load the contacts.json from Cloudfront
export const useOrganizationContacts = () => {
  const contactMethodsRemoteData = useContactMethods();

  // Return empty array while loading or if there's an error
  if (!contactMethodsRemoteData.hasData()) {
    return [];
  }

  // Get the contact methods from the RemoteData
  const contactMethods = contactMethodsRemoteData.data;
  return contactMethods;
};

export function hasOrganizations(
  organization: UseOrganizationType,
  ...orgPaths: string[]
) {
  if (organization.hasData()) {
    return orgPaths.some((path) => path === organization.data.path);
  }

  return false;
}
