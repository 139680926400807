/**
 * Returns the name of the language associated with the provided unicode / BCP47
 * language tag, or the provided fallback value if unrecognized (which defaults
 * to the language tag itself).
 */
export function getLanguageName<FALLBACK = never>(
  languageCode: string,
  fallback: string | FALLBACK = languageCode
): string | FALLBACK {
  try {
    if (languageCode === "null") {
      return "No preferred language";
    }
    const LanguageNames = new Intl.DisplayNames(["en"], {
      type: "language",
      fallback: "none", // Return `undefined` if not found.
    });
    // This throws a RangeError if the code is in an invalid format.
    return LanguageNames.of(languageCode) ?? fallback;
  } catch (error) {
    return fallback;
  }
}
