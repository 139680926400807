import { FormikErrors } from "formik";
import _ from "lodash";
import {
  CustomQuestionAnswer,
  CustomQuestionAnswerSchema,
} from "src/components/Form/QuestionForm/formik";
import { AddressValidationSchema, BaseAddress } from "src/schemas/Address";
import * as AF from "../types/formTemplate";
import { Answer, Question } from "./formTemplate";
import { answerIsEmpty } from "./formTemplate/answer";
import { SelectedSchoolIds } from "./formTemplate/generalSection/question";
import {
  isValidEmail,
  isValidUsPhoneNumber,
  validateWithZod,
} from "./formValidations";
import {
  EMAIL_TYPE_VALIDATION_ERROR,
  PHONE_NUMBER_TYPE_VALIDATION_ERROR,
  REQUIRED_FIELD_VALIDATION_ERROR,
} from "./url/constants";

const isAnswerValidEmail = (
  answers: Answer.FormikValues,
  questionId: string
): boolean => {
  const answer = answers[questionId];
  if (!answer) return true;

  if (typeof answer === "string") return isValidEmail(answer);
  return true;
};

const isAnswerValidUSPhoneNumber = (
  answers: Answer.FormikValues,
  questionId: string
): boolean => {
  const answer = answers[questionId];
  if (!answer) return true;
  if (typeof answer === "string") return isValidUsPhoneNumber(answer);
  return true;
};

export const validateRequiredQuestions = (
  questions: readonly AF.Question<AF.WithId>[],
  selectedSchoolIds: SelectedSchoolIds,
  answers: Answer.FormikValues
) => {
  const completeQuestions = Question.getCompleteApplicableQuestions(
    questions,
    answers,
    selectedSchoolIds
  );

  const requiredQuestions = Question.findRequiredQuestionIds(completeQuestions);

  const missingAnswerRequiredQuestions = [];

  for (const questionId of Object.keys(answers)) {
    if (
      requiredQuestions.includes(questionId) &&
      !Question.hasAnswer(answers, questionId)
    ) {
      missingAnswerRequiredQuestions.push(questionId);
      continue;
    }
  }

  return missingAnswerRequiredQuestions;
};

export const validateFormTemplate =
  (
    questions: readonly AF.Question<AF.WithId>[],
    selectedSchoolIds: SelectedSchoolIds
  ) =>
  async (
    answers: Answer.FormikValues
  ): Promise<FormikErrors<Answer.FormikValues>> => {
    const completeQuestions = Question.getCompleteApplicableQuestions(
      questions,
      answers,
      selectedSchoolIds
    );
    const emailQuestions = Question.findEmailQuestionIds(completeQuestions);

    const phoneNumberQuestions =
      Question.findPhoneNumberQuestionIds(completeQuestions);

    const requiredQuestions =
      Question.findRequiredQuestionIds(completeQuestions);

    const addressQuestions = Question.findAddressQuestionIds(completeQuestions);

    const customQuestions = Question.findCustomQuestionIds(completeQuestions);

    const errors: FormikErrors<Answer.FormikValues> = {};
    for (const questionId of Object.keys(answers)) {
      if (
        requiredQuestions.includes(questionId) &&
        !Question.hasAnswer(answers, questionId)
      ) {
        errors[questionId] = REQUIRED_FIELD_VALIDATION_ERROR;
        continue;
      }

      if (
        emailQuestions.includes(questionId) &&
        !isAnswerValidEmail(answers, questionId)
      ) {
        errors[questionId] = EMAIL_TYPE_VALIDATION_ERROR;
        continue;
      }

      if (
        phoneNumberQuestions.includes(questionId) &&
        !isAnswerValidUSPhoneNumber(answers, questionId)
      ) {
        errors[questionId] = PHONE_NUMBER_TYPE_VALIDATION_ERROR;
        continue;
      }

      if (addressQuestions.includes(questionId)) {
        const isRequired = requiredQuestions.includes(questionId);
        const addressErrors = await validateAddressAnswer(
          answers,
          questionId,
          isRequired
        );

        if (addressErrors !== undefined) {
          errors[questionId] = addressErrors as any;
        }

        continue;
      }

      if (customQuestions.includes(questionId)) {
        const customQuestion = completeQuestions.find(
          (question) => question.id === questionId
        ) as AF.CustomQuestion<AF.WithId>;
        const isRequired = requiredQuestions.includes(questionId);
        const customQuestionErrors = await validateCustomQuestionAnswer(
          customQuestion.nestedQuestions,
          answers,
          questionId,
          isRequired
        );

        if (customQuestionErrors !== undefined) {
          errors[questionId] = customQuestionErrors as any;
        }

        continue;
      }
    }
    return errors;
  };

async function validateAddressAnswer(
  answers: Answer.FormikValues,
  questionId: uuid,
  isRequired: boolean
): Promise<FormikErrors<BaseAddress> | undefined> {
  const answer = answers[questionId];

  if (answer === undefined || !isRequired) {
    return undefined;
  }

  const validate = validateWithZod(AddressValidationSchema);

  if (!Answer.isAddressAnswer(answer)) {
    return await validate({
      street_address: "",
      street_address_line_2: "",
      city: "",
      state: "",
      zip_code: "",
    });
  }

  return await validate(answer);
}

async function validateCustomQuestionAnswer(
  fieldQuestions: AF.ClonedQuestion<AF.WithId>[],
  answers: Answer.FormikValues,
  questionId: uuid,
  isRequired: boolean
): Promise<FormikErrors<CustomQuestionAnswer> | undefined> {
  const customQuestionAnswer = answers[questionId] as CustomQuestionAnswer;
  if (
    customQuestionAnswer === undefined ||
    (!isRequired && answerIsEmpty(customQuestionAnswer.answersByQuestionId))
  ) {
    return undefined;
  }

  const validate = validateWithZod(CustomQuestionAnswerSchema);
  const errors: FormikErrors<CustomQuestionAnswer> =
    (await validate(customQuestionAnswer)) ?? {};

  const fieldAnswers = customQuestionAnswer.answersByQuestionId;
  const fieldErrors: Record<string, string> = {};

  for (const fieldQuestion of fieldQuestions) {
    if (fieldQuestion.requirement && !fieldAnswers[fieldQuestion.id]) {
      fieldErrors[fieldQuestion.id] = REQUIRED_FIELD_VALIDATION_ERROR;
    }
    switch (fieldQuestion.type) {
      case AF.EmailType:
        if (!isAnswerValidEmail(fieldAnswers, fieldQuestion.id))
          fieldErrors[fieldQuestion.id] = EMAIL_TYPE_VALIDATION_ERROR;
        break;
      case AF.PhoneNumberType:
        if (!isAnswerValidUSPhoneNumber(fieldAnswers, fieldQuestion.id))
          fieldErrors[fieldQuestion.id] = PHONE_NUMBER_TYPE_VALIDATION_ERROR;
        break;
    }
  }

  if (!_.isEmpty(fieldErrors)) {
    errors.answersByQuestionId = fieldErrors;
  }

  return _.isEmpty(errors) ? undefined : errors;
}
