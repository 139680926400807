import {
  AddressBookAnswerSchema,
  BaseAddressSchema,
} from "src/schemas/Address";
import { CustomQuestionTypeFieldTypesSchema } from "src/schemas/CustomQuestionType";
import { FormTemplateFiltersSchema } from "src/schemas/formTemplateFilters";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import { z } from "zod";

/**
 * Eligibility Inclusion
 */
const DisabledEligibilitySchema = z.object({
  type: z.literal("disabled"),
  schoolIds: z.array(z.string()).optional(),
});
export const EligibilityInclusionSchema = z.object({
  type: z.literal("includes"),
  schoolIds: z.array(z.string()).min(1, "Include or exclude at least 1 school"),
});
export const EligibilityExclusionSchema = z.object({
  type: z.literal("excludes"),
  schoolIds: z.array(z.string()).min(1, "Include or exclude at least 1 school"),
});
export type EligibilityInclusion = z.infer<typeof EligibilityInclusionSchema>;
export type EligibilityExclusion = z.infer<typeof EligibilityExclusionSchema>;
export const EligibilitySchema = z.discriminatedUnion("type", [
  DisabledEligibilitySchema,
  EligibilityInclusionSchema,
  EligibilityExclusionSchema,
]);
export const EligibilityByOptionIdSchema = z.record(EligibilitySchema);
export type Eligibility = z.infer<typeof EligibilitySchema>;
export type EligibilityByOptionId = z.infer<typeof EligibilityByOptionIdSchema>;

/**
 * Verification
 */
export const VerificationSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("existing"),
    id: z.string().min(1, "This field is required").default(""),
    label: z.string().optional(),
  }),
  z.object({
    type: z.literal("new"),
    id: z.string().optional(),
    label: z.string().min(1, "This field is required").default(""),
  }),
  z.object({
    type: z.literal("disabled"),
    id: z.string().optional(),
    label: z.string().optional(),
  }),
]);
export type VerificationType = Verification["type"];
export type Verification = z.infer<typeof VerificationSchema>;
export const VerificationByQuestionIdSchema = z.record(VerificationSchema);
export type VerificationByQuestionId = z.infer<
  typeof VerificationByQuestionIdSchema
>;

/**
 * Question Link
 */
export const QuestionLinkSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("checked"),
    url: z
      .string()
      .min(1, "While this question has a link, there must be a valid URL")
      .default(""),
    text: z
      .string()
      .min(
        1,
        "While this question has a link, there must be text for that link"
      )
      .default(""),
  }),
  z.object({
    type: z.literal("disabled"),
    url: z.string().optional(),
    text: z.string().optional(),
  }),
]);
export type QuestionLinkType = QuestionLink["type"];
export type QuestionLink = z.infer<typeof QuestionLinkSchema>;
export const QuestionLinksByQuestionIdSchema = z.record(QuestionLinkSchema);
export type QuestionLinksByQuestionId = z.infer<
  typeof QuestionLinksByQuestionIdSchema
>;

/**
 * Permission level
 */
export const PermissionLevelSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("checked"),
    level: z.string().min(1, "This field is required").default(""),
  }),
  z.object({
    type: z.literal("disabled"),
    level: z.string().optional(),
  }),
]);
export type PermissionLevelType = PermissionLevel["type"];
export type PermissionLevel = z.infer<typeof PermissionLevelSchema>;
export const PermissionLevelsByQuestionIdSchema = z.record(
  PermissionLevelSchema
);
export type PermissionLevelsByQuestionId = z.infer<
  typeof PermissionLevelsByQuestionIdSchema
>;

/**
 * Requirement
 */
export const RequirementSchema = z.enum(["Required"]).optional();
export const RequirementsByQuestionIdSchema = z.record(RequirementSchema);
export type RequirementsByQuestionId = z.infer<
  typeof RequirementsByQuestionIdSchema
>;
export type Requirement = z.infer<typeof RequirementSchema>;

const z_date = z
  .string()
  .regex(/^(?:\d{4}-\d{2}-\d{2})?$/, {
    message: "Invalid date format, expected yyyy-MM-dd",
  })
  .transform((val) => (val === "" ? null : val))
  .refine(
    (val) => {
      if (!val) return true;

      const [year, month, day] = val.split("-").map(Number);
      const date = new Date(`${year}-${month}-${day}`);
      return (
        !isNaN(date.getTime()) &&
        date.getUTCFullYear() === year &&
        date.getUTCMonth() + 1 === month &&
        date.getUTCDate() === day
      );
    },
    { message: "Invalid date" }
  );
export const DEFAULT_DATE_TYPE_FORMAT = "yyyy-MM-dd";
export const DateTypeFormats = [
  "yyyy-MM-dd",
  "MM/dd/yyyy",
  "MM-dd-yyyy",
] as const;
export const DateTypeConstraintsSchema = z.object({
  format: z.enum(DateTypeFormats).default(DEFAULT_DATE_TYPE_FORMAT),
  startDate: z_date.optional().nullable(),
  endDate: z_date.optional().nullable(),
});
export type DateTypeConstraints = z.infer<typeof DateTypeConstraintsSchema>;

export const DateConstraintsSchema = DateTypeConstraintsSchema.extend({
  switch: z.enum(["checked", "disabled"]),
}).superRefine((data, ctx) => {
  const { startDate, endDate } = data;

  // Validate that minValue is less than maxValue, only if both are defined (not null or undefined)
  if (
    isNotNull(startDate) &&
    isNotNull(endDate) &&
    new Date(startDate) >= new Date(endDate)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Start date must be less than end date",
      path: ["startDate"], // The field that causes the issue
    });
  }
});

export const DateConstraintsByQuestionIdSchema = z.record(
  DateConstraintsSchema
);
export type DateConstraintsByQuestionId = z.infer<
  typeof DateConstraintsByQuestionIdSchema
>;
export type DateConstraints = z.infer<typeof DateConstraintsSchema>;
export type DateConstraintsSwitchType = DateConstraints["switch"];

export const NUMBER_TYPE_FORMAT_WHOLE = "#,#";
export const NUMBER_TYPE_FORMAT_DECIMAL = "#,#.#";
export const DEFAULT_NUMBER_TYPE_FORMAT = NUMBER_TYPE_FORMAT_WHOLE;

const z_coerced_number = z.preprocess(
  (val) => (val === "" ? null : val),
  z.coerce.number().nullable()
);

export const NumberTypeConstraintsSchema = z.object({
  precision: z.preprocess((val) => {
    const num = Number(val);
    return isNaN(num) ? "" : num;
  }, z.coerce.number()),
  minValue: z_coerced_number.optional().nullable(),
  maxValue: z_coerced_number.optional().nullable(),
  format: z
    .enum([NUMBER_TYPE_FORMAT_WHOLE, NUMBER_TYPE_FORMAT_DECIMAL])
    .default(DEFAULT_NUMBER_TYPE_FORMAT),
});
export type NumberTypeConstraints = z.infer<typeof NumberTypeConstraintsSchema>;

export const NumberConstraintsSchema = NumberTypeConstraintsSchema.extend({
  switch: z.enum(["checked", "disabled"]),
}).superRefine((data, ctx) => {
  const { format, precision, minValue, maxValue } = data;

  // Validate that minValue is less than maxValue, only if both are defined (not null or undefined)
  if (isNotNull(minValue) && isNotNull(maxValue) && minValue >= maxValue) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Minimum must be less than maximum",
      path: ["minValue"], // The field that causes the issue
    });
  }

  if (
    format === NUMBER_TYPE_FORMAT_DECIMAL &&
    (isNaN(Number(precision)) ||
      precision === undefined ||
      precision === null ||
      precision < 1 ||
      precision > 6)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Decimal places must be between 1 and 6",
      path: ["precision"], // The field that causes the issue
    });
  }
});
export const NumberConstraintsByQuestionIdSchema = z.record(
  NumberConstraintsSchema
);
export type NumberConstraintsByQuestionId = z.infer<
  typeof NumberConstraintsByQuestionIdSchema
>;
export type NumberConstraints = z.infer<typeof NumberConstraintsSchema>;
export type NumberConstraintsSwitchType = NumberConstraints["switch"];

/**
 * Question Option
 */
export const OptionSchema = z.object({
  isNew: z.boolean(),
  label: z.string().min(1, "This field is required"),
  value: z.string().optional(),
});
export type Option = z.infer<typeof OptionSchema>;
export const OptionsByOptionIdSchema = z.record(OptionSchema);
export type OptionsByOptionId = z.infer<typeof OptionsByOptionIdSchema>;

export const OptionIdsByQuestionIdSchema = z.record(z.array(z.string()));
export type OptionIdsByQuestionId = z.infer<typeof OptionIdsByQuestionIdSchema>;

/**
 * Question type
 */
export const QuestionTypeSchema = z.union([
  z.literal(AF.SingleSelectType),
  z.literal(AF.MultiSelectType),
  z.literal(AF.FreeTextType),
  z.literal(AF.FileUploadType),
  z.literal(AF.GradesType),
  z.literal(AF.EmailType),
  z.literal(AF.PhoneNumberType),
  z.literal(AF.AddressType),
  z.literal(AF.CustomQuestionType),
  z.literal(AF.DateType),
  z.literal(AF.NumberType),
]);

export const QuestionTypesByQuestionIdSchema = z.record(QuestionTypeSchema);
export type QuestionTypesByQuestionId = z.infer<
  typeof QuestionTypesByQuestionIdSchema
>;

export const CustomQuestionTypeIdsByQuestionIdSchema = z.record(
  z.string().uuid().optional()
);
export type CustomQuestionTypeIdsByQuestionId = z.infer<
  typeof CustomQuestionTypeIdsByQuestionIdSchema
>;

/**
 * Question
 */
export const QuestionFormSchema = z.object({
  id: z.string().uuid(),
});
export type QuestionForm = z.infer<typeof QuestionFormSchema>;

/**
 * Question Key
 */
export const QuestionKeySchema = z.string().optional();
export const QuestionKeysByQuestionIdSchema = z.record(QuestionKeySchema);
export type QuestionKeysByQuestionId = z.infer<
  typeof QuestionKeysByQuestionIdSchema
>;

/**
 * Question Title
 */
export const QuestionTitleSchema = z.string().min(1, "This field is required");
export const QuestionTitlesByQuestionIdSchema = z.record(QuestionTitleSchema);
export type QuestionTitlesByQuestionId = z.infer<
  typeof QuestionTitlesByQuestionIdSchema
>;

/**
 * Question answers
 */
export const AddressAnswerSchema = z.union([
  BaseAddressSchema,
  AddressBookAnswerSchema,
]);
export type AddressAnswer = z.infer<typeof AddressAnswerSchema>;

export const CustomQuestionAnswerSchema = z.object({
  kind: z.string(),
  answerBankId: z.string().uuid().optional(),
  lastUsedAt: z.string().optional(),
  answersByQuestionId: z.record(z.string().uuid(), z.string()),
  referenceId: z.string().optional(),
});
export type CustomQuestionAnswer = z.infer<typeof CustomQuestionAnswerSchema>;
export type CustomQuestionAnswersByQuestionId =
  CustomQuestionAnswer["answersByQuestionId"];

const CustomQuestionAnswerWithTypeSchema = z.object({
  type: CustomQuestionTypeFieldTypesSchema,
  value: z.string().optional(),
});

export type CustomQuestionAnswerWithType = z.infer<
  typeof CustomQuestionAnswerWithTypeSchema
>;

const CustomQuestionAnswersWithFieldQuestionTypesSchema = z.record(
  CustomQuestionAnswerWithTypeSchema
);
export type CustomQuestionAnswersWithFieldQuestionTypes = z.infer<
  typeof CustomQuestionAnswersWithFieldQuestionTypesSchema
>;

/**
 * Additional Question
 */
export const AdditionalQuestionIdsSchema = z.array(z.string());

export type AdditionalQuestionsIds = z.infer<
  typeof AdditionalQuestionIdsSchema
>;

export const DisabledAdditionalQuestionsSchema = z.object({
  type: z.literal("disabled"),
  questionIds: AdditionalQuestionIdsSchema,
});

export const EnabledAdditionalQuestionsSchema = z.object({
  type: z.literal("enabled"),
  questionIds: AdditionalQuestionIdsSchema.min(
    1,
    "At least 1 additional question is required."
  ),
});

export const AdditionalQuestionsSchema = z.discriminatedUnion("type", [
  DisabledAdditionalQuestionsSchema,
  EnabledAdditionalQuestionsSchema,
]);
export type AdditionalQuestions = z.infer<typeof AdditionalQuestionsSchema>;

export const AdditionalQuestionsByOptionIdSchema = z.record(
  AdditionalQuestionsSchema
);
export type AdditionalQuestionsByOptionId = z.infer<
  typeof AdditionalQuestionsByOptionIdSchema
>;

export const ValidationsByIdSchema = z.record(
  z.object({ options: z.literal("") })
);
export type ValidationsById = z.infer<typeof ValidationsByIdSchema>;

/**
 * Question filters
 */
export type FormTemplateFilters = z.infer<typeof FormTemplateFiltersSchema>;

export const FormTemplateFiltersByIdSchema = z.record(
  FormTemplateFiltersSchema
);
export type FormTemplateFiltersById = z.infer<
  typeof FormTemplateFiltersByIdSchema
>;

/**
 * Form Values
 *
 * We're maintaining the form state in flat structure leveraging uuid for storing different part of the question/option.
 * This flat structure enable us to work with nested question/option easier since each component responsible
 * for different part of the question can access the data in the same manner regardless whether it's a nested or parent question.
 */
export const FormSchema = QuestionFormSchema.merge(
  z.object({
    questionKeys: QuestionKeysByQuestionIdSchema,
    questionTitles: QuestionTitlesByQuestionIdSchema,
    additionalQuestions: AdditionalQuestionsByOptionIdSchema,
    eligibilities: EligibilityByOptionIdSchema,
    optionIds: OptionIdsByQuestionIdSchema,
    options: OptionsByOptionIdSchema,
    questionTypes: QuestionTypesByQuestionIdSchema,
    customQuestionTypeIds: CustomQuestionTypeIdsByQuestionIdSchema,
    verifications: VerificationByQuestionIdSchema,
    requirements: RequirementsByQuestionIdSchema,
    permissionLevels: PermissionLevelsByQuestionIdSchema,
    questionLinks: QuestionLinksByQuestionIdSchema,
    validations: ValidationsByIdSchema, // this is hack to allow non formik field specific validation message such as min number of answers in single/multi select
    formTemplateFilters: FormTemplateFiltersByIdSchema,
    dateConstraints: DateConstraintsByQuestionIdSchema,
    numberConstraints: NumberConstraintsByQuestionIdSchema,
  })
);

export type FormType = z.infer<typeof FormSchema>;
