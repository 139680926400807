import { Grid, GridItem } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { FunctionComponent } from "react";
import { AddressAutocomplete } from "src/components/Inputs/Address/Autocomplete/AddressAutocompleteInput";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { BaseAddress } from "src/schemas/Address";
import { GoogleMapsAutoCompleteAddress } from "src/services/googlePlaces/transforms";

type CompositeAddressFieldProps = {
  fieldsAreRequired?: boolean;
  fieldsAreDisabled?: boolean;
};

export const CompositeAddressField: FunctionComponent<
  CompositeAddressFieldProps
> = (props) => {
  const { fieldsAreRequired, fieldsAreDisabled } = props;

  const { setValues, values } = useFormikContext<BaseAddress>();

  const setAddressFields = async (address: GoogleMapsAutoCompleteAddress) => {
    // autocomplete can return undefined values, which can cause uncontrolled component errors
    const coalescedAddress: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(address)) {
      coalescedAddress[key] = value ?? "";
    }

    setValues({
      ...values,
      ...coalescedAddress,
    });
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" columnGap={4}>
      <GridItem colSpan={2}>
        <AddressAutocomplete onAddressSelected={setAddressFields}>
          <InputWithValidationControl
            isRequired={fieldsAreRequired}
            label="Street address"
            inputProps={{ placeholder: "E.g.: 532 Oakland Ave" }}
            name="street_address"
            id="street_address"
            isDisabled={fieldsAreDisabled}
          />
        </AddressAutocomplete>
      </GridItem>
      <GridItem colSpan={2}>
        <InputWithValidationControl
          isRequired={false}
          inputProps={{ placeholder: "E.g.: 5B" }}
          name="street_address_line_2"
          id="street_address_line_2"
          label="Apartment, suite, or floor # (Optional)"
          isDisabled={fieldsAreDisabled}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <InputWithValidationControl
          isRequired={fieldsAreRequired}
          inputProps={{ placeholder: "E.g.: New Orleans" }}
          name="city"
          id="city"
          label="City"
          isDisabled={fieldsAreDisabled}
        />
      </GridItem>
      <InputWithValidationControl
        isRequired={fieldsAreRequired}
        inputProps={{ placeholder: "E.g.: LA" }}
        name="state"
        id="state"
        label="State"
        isDisabled={fieldsAreDisabled}
      />
      <InputWithValidationControl
        isRequired={fieldsAreRequired}
        inputProps={{ placeholder: "E.g.: 10181" }}
        name="zip_code"
        id="zip_code"
        label="Zip code"
        isDisabled={fieldsAreDisabled}
      />
    </Grid>
  );
};
