import { AllOrgConfigs, DisabledConfig, DisabledConfigSchema } from "../types";
import * as OrgConfig from "@avela/organization-config-sdk";
import { Flex } from "@chakra-ui/react";
import { TranslationOptionsConfigForm } from "./TranslationOptionsConfigForm";
import { ScheduleExportConfigForm } from "./ScheduleExportConfigForm";
import { MatchConfigForm } from "./MatchConfigForm";
import { AccountLookupConfigForm } from "./AccountLookupConfigForm";
import { z } from "zod";
import { LoginConfigForm } from "./LoginConfigForm";

export const ConfigForm = () => {
  return (
    <Flex direction="column" gap={8}>
      <TranslationOptionsConfigForm />
      <ScheduleExportConfigForm />
      <MatchConfigForm />
      <AccountLookupConfigForm />
      <LoginConfigForm />
    </Flex>
  );
};

export const ForkmikSchema = z.object({
  Login: z.union([OrgConfig.Login.ConfigSchema, DisabledConfigSchema]),
  AccountLookup: z.union([
    OrgConfig.AccountLookup.ConfigSchema,
    DisabledConfigSchema,
  ]),
  Match: z.union([OrgConfig.Match.ConfigSchema, DisabledConfigSchema]),
  ScheduleExport: z.union([
    OrgConfig.ScheduleExport.ConfigSchema,
    DisabledConfigSchema,
  ]),
  TranslationOptions: z.union([
    OrgConfig.TranslationOptions.ConfigSchema,
    DisabledConfigSchema,
  ]),
  Contacts: z.union([OrgConfig.Contacts.ConfigSchema, DisabledConfigSchema]),
  Auth0: z.union([OrgConfig.Auth0.ConfigSchema, DisabledConfigSchema]),
});
export type FormikForm = z.infer<typeof ForkmikSchema>;

export function getInitialValues(
  configs: AllOrgConfigs | undefined
): FormikForm {
  if (!configs) {
    // No configs, this is a new org. Disable all configs.
    return {
      Login: createDisabledInitialConfig("Login"),
      AccountLookup: createDisabledInitialConfig("AccountLookup", {
        supportUrl: "",
      }),
      Match: createDisabledInitialConfig("Match"),
      ScheduleExport: createDisabledInitialConfig("ScheduleExport", {
        secretArn: "",
      }),
      TranslationOptions: createDisabledInitialConfig("TranslationOptions"),
      Contacts: createDisabledInitialConfig("Contacts"),
      Auth0: createDisabledInitialConfig("Auth0"),
    };
  }

  return {
    Login: getInitialValue("Login", configs),
    AccountLookup: getInitialValue("AccountLookup", configs, {
      supportUrl: "",
    }),
    Match: getInitialValue("Match", configs),
    ScheduleExport: getInitialValue("ScheduleExport", configs, {
      secretArn: "",
    }),
    TranslationOptions: getInitialValue("TranslationOptions", configs),
    Contacts: getInitialValue("Contacts", configs),
    Auth0: createDisabledInitialConfig("Auth0"),
  };
}

type InitialValue<T extends OrgConfig.Type> =
  | (OrgConfig.Config<T> & { disabled?: boolean })
  | OrgConfig.DisabledConfig;
function getInitialValue<T extends Exclude<OrgConfig.Type, "Branding">>(
  type: T,
  configs: AllOrgConfigs | undefined,
  empty?: OrgConfig.Config<T>
): InitialValue<T> {
  if (!configs?.[type] || configs[type].disabled) {
    // if configs is undefined or disabled, use default config
    return createDisabledInitialConfig(type, empty);
  }

  return configs[type];
}

function createDisabledInitialConfig<T extends OrgConfig.Type>(
  type: T,
  empty?: OrgConfig.Config<T>
): InitialValue<T> {
  return {
    ...DisabledConfig,
    ...empty, // this empty config is important to get correct validation error.
    ...OrgConfig.Constants.DefaultConfig[type],
  } as InitialValue<T>;
}
