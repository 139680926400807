/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Divider, Flex, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import { ParentAnnouncement } from "src/components/Announcement/ParentAnnouncement";
import { ParentFormsLayout } from "src/components/Layout/ParentFormsLayout";
import { Glossary } from "src/components/Text/Glossary";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useUserProfile } from "src/hooks/useUserProfile";
import { BaseAddressSchema } from "src/schemas/Address";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { CREATE_STUDENT } from "../../orgAdmin/students/graphql/mutations";
import { StudentForm } from "../components/Forms/StudentForm";
import { GET_STUDENTS_BY_GUARDIAN } from "./graphql/queries";
import {
  INITIAL_STUDENT_PROFILE,
  StudentProfileForm,
  StudentProfileSchema,
  StudentProfileValidator,
} from "./schemas";

export function New() {
  const { glossary } = useGlossary();
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const organization = useOrganization();
  const userProfile = useUserProfile();
  const [createStudent, { remoteData }] = useRemoteDataMutation<
    GQL.CreateStudent,
    GQL.CreateStudentVariables
  >(CREATE_STUDENT, {
    refetchQueries: [GET_STUDENTS_BY_GUARDIAN],
  });
  const { returnUrl, studentCount } = useQueryParams() as {
    returnUrl: string;
    studentCount: string;
  };

  function showErrorToast() {
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        title: glossary`Error adding student`,
        description:
          "Please try again later or report the problem to our support team.",
        isClosable: true,
        status: "error",
      });
    }
  }

  const toastId = "NewStudent";
  React.useEffect(() => {
    if (remoteData.hasError()) {
      showErrorToast();
    }
  });

  const handleSubmit = async (form: StudentProfileForm) => {
    if (!userProfile.hasData() || !organization.hasData()) {
      showErrorToast();
      return;
    }

    setSubmitting(true);
    try {
      const { address, applicant_attending_schools, ...student } =
        StudentProfileSchema.parse(form);
      const parsedAddress = BaseAddressSchema.parse(address);
      const result = await createStudent({
        variables: {
          student: {
            ...student,
            ...parsedAddress,
            birth_date: student.birth_date ? student.birth_date : null,
            active: true,
            second_relationship: {
              data: [
                {
                  first_person: userProfile.data.id,
                },
              ],
            },
            applicant_attending_schools: {
              data: [],
            },
            organization_id: organization.data.id,
            person_type: GQL.person_type_enum.applicant,
          },
        },
      });

      let url = returnUrl;
      if (returnUrl && result.data?.insert_person_one?.id) {
        /*
         * Since a `returnUrl` was provided, the originating page is likely
         * deviating from a related user flow to create this student. As a
         * convenience, provide the id of the added student in the url search
         * params. The originating page can use this id as context in whatever
         * flow it is resuming.
         */
        const [base, search] = returnUrl.split("?");
        const params = new URLSearchParams(search);
        params.set("studentId", result.data.insert_person_one.id);
        url = `${base}?${params.toString()}`;
      }

      navigate(url || Url.Parent.Student.index(organization.data));
    } catch (err) {
      showErrorToast();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ParentFormsLayout
      paddingX={0}
      heading={glossary`Add a student`}
      backLink={organization
        .map((org) => ({
          url: Url.Parent.Student.index(org),
          label: glossary`Back to students`,
        }))
        .withDefault(undefined)}
    >
      {studentCount === "0" && (
        <ParentAnnouncement
          condition={GQL.announcement_condition_enum.NO_STUDENT}
        />
      )}
      <Formik<StudentProfileForm>
        initialValues={INITIAL_STUDENT_PROFILE}
        onSubmit={handleSubmit}
        validate={validateWithZod(StudentProfileValidator)}
        validateOnMount={true}
      >
        <Flex direction="column" as={Form} gap={6}>
          <StudentForm paddingX={6} />
          <Divider />
          <Flex direction="column" paddingX={6}>
            <Button type="submit" isLoading={submitting} flexGrow="1">
              <Glossary>Add student</Glossary>
            </Button>
          </Flex>
        </Flex>
      </Formik>
    </ParentFormsLayout>
  );
}
